import { all } from 'redux-saga/effects';
import { watchSetLoading } from './genric/Saga';
import { watchLogin } from './login/Saga';
import { watchLog } from './Logger/LoggerSaga';
import {
  watchFilesByProcessIDSuccess,
  watchFilesByProcessIDFail,
  watchApproveImportedFile,
} from './file/Saga';
import {
  watchFormGet,
  watchFormPost,
  watchFormbyIdGet,
  watchFormbyIdPut,
  watchFormbyIdGetDialog,
} from './forms/Saga';

import { watchLookupItems, watchLookupTypes } from './lookups/Saga';
import { watchGlobalFilterFilter } from './GlobalOrderFilter/GlobalOrderFilterSaga';
import { watchTableColumnsFilter } from './TableColumnsFilter/TableColumnsFilterSaga';
import {watchGlobalAdvanceSearch} from './GlobalAdvanceSearch/GlobalAdvanceSearchSaga'
export default function* rootSaga() {
  yield all([
    watchSetLoading(),
    watchLogin(),
    watchFormGet(),
    watchFormPost(),
    watchFormbyIdGet(),
    watchFormbyIdGetDialog(),
    watchFormbyIdPut(),
    watchFilesByProcessIDSuccess(),
    watchFilesByProcessIDFail(),
    watchApproveImportedFile(),
    watchLookupTypes(),
    watchLookupItems(),
    watchLog(),
    watchTableColumnsFilter(),
    watchGlobalFilterFilter(),
    watchGlobalAdvanceSearch(),
  ]);
}
