/* eslint-disable prefer-const */
/* eslint-disable guard-for-in */
import { Fab, Tooltip } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import exportFromJSON from 'export-from-json';
import moment from 'moment';
import {
  XelePagination,
  PermissionsComponent,
  XeleTable,
} from '../../../../Components';
import { GetParams, GlobalNavigate, showSuccess } from '../../../../Helper';
import { ReportBuilderPermissions } from '../../../../Permissions';
// import {
//   RunSystemReportById,
//   ExportSystemReport,
//   ExportSystemReportWithEmailAPI,
// } from '../../../../Services';
import { CellsSpinner } from '../../../../SharedComponents/CellSpinnerComponent/CellsSpinner';
import { XeleDateRangePicker } from '../../../../Components/Controls/DateRangePickerComponent/XeleDateRangePicker';
import {
  ExportSystemReport,
  ExportSystemReportWithEmailAPI,
  RunSystemReportById,
} from '../../../../Services/ReportsBuilderServices';

const parentTranslationPath = 'ReportsBuilderg';
export const ReportRunView = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [TotalCount, setTotalCount] = useState(0);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
  });

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };
  const [reportDto, setReportDto] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const mediaprint =
    '@media print {footer {position: fixed !important;bottom: 0 !important;}strong, b {font-weight: bold !important;}@page {size:8.27in 11.69in !important;margin: 1cm 1cm 1cm 1cm !important;}}@media print {header {position: fixed !important;top: 0 !important;left: 0 !important;right: 0 !important;text-align:center !important;width: 100% !important;}strong, b {font-weight: bold !important;}@page {size:8.27in 11.69in !important;margin: .5cm !important;}}';
  const maxHeight = '170px';
  const [reportSchema, setReportSchema] = useState([]);
  const { t } = useTranslation(parentTranslationPath);
  const unCamelCaseConvert = (name) =>
    name
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, (str) => str.toUpperCase())
      .replace('_', ' ')
      .replace('.lookup Item Name', ' ')
      .replace('.value', ' ')
      .replace(/^((?:[^"\n]*"){5})(_*)(\..*)$/gm, ' ')
      .replace('-', ' ')
      .replace('-', ' ')
      .replace('-', ' ');

  const ExportSystemReportAPI = useCallback(
    async (
      reportId,
      fromDate,
      toDate,
      orderBy,
      orderType,
      exportType,
      pageIndex,
      pageSize
    ) => {
      setIsLoading(true);
      const res = await ExportSystemReport(
        reportId,
        fromDate,
        toDate,
        orderBy,
        orderType,
        exportType,
        pageIndex,
        pageSize
      );
      if (!(res && res.status && res.status !== 200)) {
        showSuccess(
          'The File in Process When Job Done, We Will Send Notification in Menu for You'
        );
        setIsLoading(false);
      } else setIsLoading(false);
    },
    []
  );

  const ExportSystemReportWithEmailFuncation = useCallback(
    async (
      reportId,
      fromDate,
      toDate,
      orderBy,
      orderType,
      pageIndex,
      pageSize
    ) => {
      setIsLoading(true);
      const res = await ExportSystemReportWithEmailAPI({
        reportId,
        fromDate,
        toDate,
        orderBy,
        orderType,
        pageIndex,
        pageSize,
      });
      if (!(res && res.status && res.status !== 200)) {
        showSuccess('The File Will Be Send to Your email When Job Done');
        setIsLoading(false);
      } else setIsLoading(false);
    },
    []
  );

  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: 'selection',
  };
  const [dateFilter, setDateFilter] = useState(dateRangeDefault);
  const SystemReportsResultAPI = useCallback(
    async (
      reportId,
      fromDate,
      toDate,
      orderBy,
      orderType,
      pageIndex,
      pageSize
    ) => {
      setIsLoading(true);
      const res = await RunSystemReportById(
        reportId,
        fromDate,
        toDate,
        orderBy,
        orderType,
        pageIndex,
        pageSize
      );
      const ClearData = JSON.parse(res && res.reportResult);
      const temp = ClearData[0];
      setReportDto(JSON.parse(res && res.reportResult) || []);
      setIsLoading(false);
      setTotalCount(temp?.TotalCount || 0);
      const list = [];

      let i = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const key in temp) {
        // eslint-disable-next-line no-plusplus
        i++;
        if (key !== 'TotalCount') {
          list.push({
            id: i,
            label: unCamelCaseConvert(key),
            component: (item) => (
              <div>
                {item && item[key] !== null
                  ? String(item[key]) || 'N/A'
                  : 'N/A'}
              </div>
            ),
            isDefaultFilterColumn: true,
            isSortable: true,
            input: key,
          });
        }
      }
      setReportSchema(list);
    },
    []
  );

  const onExportClickedxml = (exportType) => {
    ExportSystemReportAPI(
      +GetParams('ReportId'),
      moment(dateFilter.startDate)
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss') || '',
      moment(dateFilter.endDate).format('YYYY-MM-DDTHH:mm:ss') || '',
      (sortBy && sortBy.filterBy) || null,
      (sortBy && sortBy.orderBy) || null,
      exportType,
      1,
      TotalCount
    );
  };

  const onExportEmailFuncation = () => {
    ExportSystemReportWithEmailFuncation(
      +GetParams('ReportId'),
      (dateFilter.startDate !== null &&
        moment(dateFilter.startDate)
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ss')) ||
        null,
      (dateFilter.endDate !== null &&
        moment(dateFilter.endDate).format('YYYY-MM-DDTHH:mm:ss')) ||
        null,
      (sortBy && sortBy.filterBy) || null,
      (sortBy && sortBy.orderBy) || null,
      1,
      TotalCount
    );
  };

  const onExportClicked = () => {
    onExportClickedxml(1);
  };
  const onExportClickedcsv = () => {
    onExportClickedxml(3);
  };
  const [headerFooter] = useState({
    imageHeaderId: 'd40968f9-09c3-4796-84ca-a798f4dba141',
    imageFooterId: 'f076cbc9-397e-43c4-a39d-a46a60b75a7a',
  });
  useEffect(() => {
    SystemReportsResultAPI(
      +GetParams('ReportId'),
      moment(dateFilter.startDate)
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss') || null,
      moment(dateFilter.endDate).format('YYYY-MM-DDTHH:mm:ss') || null,
      (sortBy && sortBy.filterBy) || null,
      (sortBy && sortBy.orderBy) || null,
      filter.pageIndex,
      filter.pageSize
    );
  }, [SystemReportsResultAPI, dateFilter, sortBy, filter]);

  return (
    <div className='ReportRunView-Main'>
      <div className='navbar'>
        <div className='left'>
          <a onClick={() => GlobalNavigate('/home')} className='logo'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              version='1.0'
              width='47'
              height='35'
              viewBox='0 0 1095.000000 969.000000'
              preserveAspectRatio='xMidYMid meet'
            >
              <metadata />
              <g
                transform='translate(0.000000,969.000000) scale(0.100000,-0.100000)'
                fill='#000000'
                stroke='none'
              >
                <path d='M0 9642 l0 -49 73 -36 c166 -82 216 -179 264 -512 36 -253 26 -5471 -11 -5755 -20 -152 -41 -250 -67 -311 -28 -64 -107 -136 -192 -174 l-66 -30 -1 -57 0 -58 856 0 855 0 -26 43 c-50 79 -133 262 -164 359 -38 122 -77 311 -91 447 -12 121 -13 956 -3 3768 l6 2002 86 20 c260 61 527 86 839 78 253 -6 367 -20 582 -73 331 -81 612 -232 811 -435 348 -354 458 -922 261 -1343 -46 -98 -83 -153 -164 -247 -32 -37 -58 -70 -58 -74 0 -9 181 -125 253 -161 132 -67 240 -97 368 -102 133 -5 195 5 286 49 196 94 336 329 401 674 26 139 23 427 -6 571 -68 344 -232 642 -482 877 -299 281 -651 435 -1205 526 -306 51 -322 51 -1915 51 l-1490 0 0 -48z' />
                <path d='M7040 7572 l0 -58 62 -29 c121 -57 197 -160 237 -318 45 -175 45 -159 46 -2927 0 -2463 1 -2629 17 -2688 64 -229 176 -405 343 -538 176 -142 408 -224 632 -224 l62 0 4 3048 c3 2206 7 3072 15 3137 38 296 109 430 272 507 l69 33 0 58 1 57 -880 0 -880 0 0 -58z' />
                <path d='M3950 6274 c-458 -66 -896 -279 -1201 -583 -198 -199 -335 -418 -419 -670 -157 -472 -123 -979 95 -1416 229 -460 566 -722 1735 -1350 817 -439 1260 -773 1414 -1065 82 -154 107 -368 66 -545 -51 -222 -205 -440 -415 -590 -36 -26 -65 -49 -65 -52 0 -13 312 7 424 27 515 89 859 397 959 860 28 129 29 374 3 510 -66 343 -234 636 -502 876 -253 227 -368 301 -1804 1161 -316 190 -600 363 -630 386 -30 22 -95 82 -145 132 -398 405 -474 895 -203 1311 239 366 738 569 1293 525 270 -22 497 -83 732 -196 394 -190 646 -439 802 -793 79 -178 161 -467 212 -744 l24 -128 113 0 112 0 0 548 c0 302 3 833 7 1180 l6 632 -116 0 -116 0 -6 -37 c-13 -76 -45 -169 -77 -223 -26 -42 -45 -61 -88 -83 -52 -28 -60 -29 -145 -24 -76 5 -147 22 -450 112 -720 212 -948 256 -1318 254 -113 -1 -244 -7 -297 -15z' />
                <path d='M9897 2315 c-151 -29 -295 -104 -404 -211 -155 -154 -233 -341 -233 -564 0 -309 163 -573 435 -705 124 -61 204 -79 350 -79 146 0 226 18 350 79 444 216 575 786 274 1187 -97 130 -272 245 -429 283 -96 23 -250 27 -343 10z' />
              </g>
            </svg>
          </a>
          <form action=''>
            <div className='search-box'>
              <input type='text' placeholder='Report  View' disabled />

              <span className='mdi mdi-file-chart px-1 mdi-24px' />
            </div>
          </form>
        </div>
        <PermissionsComponent
          permissionsList={Object.values(ReportBuilderPermissions)}
          permissionsId={ReportBuilderPermissions.DateFilter.permissionsId}
        >
          <XeleDateRangePicker
            onClearClicked={() => {
              setDateFilter(dateRangeDefault);
            }}
            onDialogClose={() => {}}
            ranges={[dateFilter]}
            parentTranslationPath={parentTranslationPath}
            onDateChanged={(selectedDate) => {
              setDateFilter({
                startDate:
                  selectedDate.selection && selectedDate.selection.startDate,
                endDate: new Date(
                  moment(
                    selectedDate.selection && selectedDate.selection.endDate
                  ).endOf('day')
                ),
                key: 'selection',
              });
            }}
          />
        </PermissionsComponent>
        <div className='middle' />

        <PermissionsComponent
          permissionsList={Object.values(ReportBuilderPermissions)}
          permissionsId={
            ReportBuilderPermissions.ExportReportAsExcel.permissionsId
          }
        >
          <Tooltip title={t(`Export Excel`)}>
            <a onClick={onExportClicked} className='home'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                version='1.0'
                width='27'
                height='29'
                viewBox='0 0 200.000000 200.000000'
                preserveAspectRatio='xMidYMid meet'
              >
                <g
                  transform='translate(0.000000,200.000000) scale(0.100000,-0.100000)'
                  fill='#000000'
                  stroke='none'
                >
                  <path d='M463 1873 c-23 -4 -23 -4 -23 -218 l0 -213 -79 -3 c-131 -6 -121 29 -121 -440 l0 -398 26 -20 c22 -17 41 -21 100 -21 l74 0 0 -214 0 -213 33 -7 c43 -9 1287 -7 1287 2 0 4 0 311 0 683 l1 676 -198 196 -197 196 -440 -1 c-243 0 -451 -3 -463 -5z m897 -213 l0 -190 190 0 190 0 0 -660 0 -660 -637 2 -638 3 0 200 0 200 303 5 304 5 19 24 c18 22 19 44 19 413 l0 389 -25 24 -24 25 -301 0 -300 0 0 198 c0 109 3 202 7 205 3 4 206 7 450 7 l443 0 0 -190z m195 0 l170 -170 -173 0 -172 0 0 170 c0 94 1 170 3 170 1 0 79 -76 172 -170z m-938 -478 c59 -107 54 -106 104 -19 l44 77 48 0 c26 0 47 -3 47 -6 0 -3 -29 -55 -65 -115 -36 -61 -65 -115 -65 -120 0 -5 29 -58 65 -119 89 -149 87 -142 30 -138 l-48 3 -47 79 c-25 43 -49 79 -53 80 -3 0 -28 -35 -54 -79 -46 -76 -50 -80 -87 -83 -21 -2 -42 0 -46 5 -5 4 23 60 61 123 37 63 69 120 69 127 0 7 -29 61 -65 122 -36 60 -65 111 -65 115 0 3 21 6 48 6 47 0 47 0 79 -58z' />
                  <path d='M1160 1165 l0 -45 105 0 105 0 0 45 0 45 -105 0 -105 0 0 -45z' />
                  <path d='M1420 1165 l0 -45 105 0 105 0 0 45 0 45 -105 0 -105 0 0 -45z' />
                  <path d='M1162 1023 l3 -48 103 -3 102 -3 0 51 0 50 -106 0 -105 0 3 -47z' />
                  <path d='M1420 1020 l0 -50 105 0 105 0 0 50 0 50 -105 0 -105 0 0 -50z' />
                  <path d='M1167 924 c-4 -4 -7 -27 -7 -51 l0 -43 106 0 105 0 -3 48 -3 47 -95 3 c-53 1 -99 0 -103 -4z' />
                  <path d='M1427 924 c-4 -4 -7 -27 -7 -51 l0 -43 106 0 105 0 -3 48 -3 47 -95 3 c-53 1 -99 0 -103 -4z' />
                </g>
              </svg>
            </a>
          </Tooltip>
        </PermissionsComponent>

        <PermissionsComponent
          permissionsList={Object.values(ReportBuilderPermissions)}
          permissionsId={
            ReportBuilderPermissions.ExportReportAsCSV.permissionsId
          }
        >
          <Tooltip title={t(`Export CSV`)}>
            <a onClick={onExportClickedcsv} className='market'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='33'
                height='28'
                version='1.1'
                id='Capa_1'
                x='0px'
                y='0px'
                viewBox='0 0 548.29 548.291'
                xmlSpace='preserve'
              >
                <g>
                  <path d='M486.2,196.121h-13.164V132.59c0-0.399-0.064-0.795-0.116-1.2c-0.021-2.52-0.824-5-2.551-6.96L364.656,3.677   c-0.031-0.034-0.064-0.044-0.085-0.075c-0.629-0.707-1.364-1.292-2.141-1.796c-0.231-0.157-0.462-0.286-0.704-0.419   c-0.672-0.365-1.386-0.672-2.121-0.893c-0.199-0.052-0.377-0.134-0.576-0.188C358.229,0.118,357.4,0,356.562,0H96.757   C84.893,0,75.256,9.649,75.256,21.502v174.613H62.093c-16.972,0-30.733,13.756-30.733,30.73v159.81   c0,16.966,13.761,30.736,30.733,30.736h13.163V526.79c0,11.854,9.637,21.501,21.501,21.501h354.777   c11.853,0,21.502-9.647,21.502-21.501V417.392H486.2c16.966,0,30.729-13.764,30.729-30.731v-159.81   C516.93,209.872,503.166,196.121,486.2,196.121z M96.757,21.502h249.053v110.006c0,5.94,4.818,10.751,10.751,10.751h94.973v53.861   H96.757V21.502z M258.618,313.18c-26.68-9.291-44.063-24.053-44.063-47.389c0-27.404,22.861-48.368,60.733-48.368   c18.107,0,31.447,3.811,40.968,8.107l-8.09,29.3c-6.43-3.107-17.862-7.632-33.59-7.632c-15.717,0-23.339,7.149-23.339,15.485   c0,10.247,9.047,14.769,29.78,22.632c28.341,10.479,41.681,25.239,41.681,47.874c0,26.909-20.721,49.786-64.792,49.786   c-18.338,0-36.449-4.776-45.497-9.77l7.38-30.016c9.772,5.014,24.775,10.006,40.264,10.006c16.671,0,25.488-6.908,25.488-17.396   C285.536,325.789,277.909,320.078,258.618,313.18z M69.474,302.692c0-54.781,39.074-85.269,87.654-85.269   c18.822,0,33.113,3.811,39.549,7.149l-7.392,28.816c-7.38-3.084-17.632-5.939-30.491-5.939c-28.822,0-51.206,17.375-51.206,53.099   c0,32.158,19.051,52.4,51.456,52.4c10.947,0,23.097-2.378,30.241-5.238l5.483,28.346c-6.672,3.34-21.674,6.919-41.208,6.919   C98.06,382.976,69.474,348.424,69.474,302.692z M451.534,520.962H96.757v-103.57h354.777V520.962z M427.518,380.583h-42.399   l-51.45-160.536h39.787l19.526,67.894c5.479,19.046,10.479,37.386,14.299,57.397h0.709c4.048-19.298,9.045-38.352,14.526-56.693   l20.487-68.598h38.599L427.518,380.583z' />
                </g>
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
              </svg>
            </a>
          </Tooltip>
        </PermissionsComponent>
        {/* <PermissionsComponent
          permissionsList={Object.values(ReportBuilderPermissions)}
          permissionsId={ReportBuilderPermissions.ExportReportAsCSV.permissionsId}
        > */}
        <PermissionsComponent
          permissionsList={Object.values(ReportBuilderPermissions)}
          permissionsId={
            ReportBuilderPermissions.SendSystemReportByEmail.permissionsId
          }
        >
          <Tooltip title={t(`Send E-mail`)}>
            <a onClick={onExportEmailFuncation} className='market'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='32'
                height='32'
                viewBox='0 0 32 32'
              >
                {' '}
                <path d='M7.416 3.604l4.605 4.98-3.251 6.395 6.855-1.229 3.12 7.532 13.255-17.38zM6.573 14.385l1.276-1.047-1.647 0.521-0.172-0.24 0.683-0.661-0.891 0.359c-3.407 1.323-5.823 4.62-5.823 8.485 0 2.599 1.093 4.937 2.844 6.593-0.751-1.317-1.183-2.844-1.183-4.475 0-3.817 2.417-7.219 5.755-8.557l0.423-1.020-1 0.437-0.281-0.38zM12.391 11.76l2.131-3.76 12.531-2.932z' />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
              </svg>
            </a>
          </Tooltip>
        </PermissionsComponent>
        {/* </PermissionsComponent> */}
        <div className='right'>
          <Fab
            color='inherit'
            aria-label='edit'
            className='mt-1'
            onClick={() => GlobalNavigate('/home/Reports-Builder/view')}
          >
            <span className='mdi mdi-arrow-left' />
          </Fab>
          <Fab
            color='secondary'
            aria-label='edit'
            className='mt-1'
            onClick={() => window.close()}
          >
            <span className='mdi mdi-close' />
          </Fab>
          <a href='' className='dropdown-menu'>
            <svg
              width='12'
              height='9'
              viewBox='0 0 12 9'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M7.09034 7.84589C6.49844 8.4724 5.50154 8.4724 4.90964 7.84589L0.596101 3.28012C-0.307594 2.32358 0.370535 0.75 1.68645 0.75L10.3135 0.750001C11.6294 0.750001 12.3076 2.32358 11.4039 3.28012L7.09034 7.84589Z'
                fill='#66676B'
              />
            </svg>
          </a>
        </div>
      </div>

      <div className='ReportPdfView-PAGE' />
      <div className='unit-card-view'>
        <CellsSpinner isActive={isLoading} isAbsolute />
        <div>
          <div className='PaginationComponentx-component-wrapper'>
            <XelePagination
              pageIndex={filter.pageIndex}
              pageSize={filter.pageSize}
              totalCount={TotalCount || 0}
              onPageIndexChanged={onPageIndexChanged}
              onPageSizeChanged={onPageSizeChanged}
            />
            <div className='fw-bold'>{`${t(`TotalCount`)} : ${
              Math.ceil(
                reportDto &&
                  reportDto[0] &&
                  reportDto[0].TotalCount / filter.pageSize
              ) || 0
            }`}</div>
            <div className='fw-bold'>{`${t(`TotalCount-`)} : ${
              Math.ceil(reportDto && reportDto[0] && reportDto[0].TotalCount) ||
              0
            }`}</div>
          </div>
          <div className='users-card-component-wrapper' id='divcontents'>
            <header className='header'>
              <img
                alt='HeaderPSITemplet'
                src={'https://static.psi-crm.com/header.png' || null}
                style={{ width: '100%', maxHeight }}
              />
            </header>
            <style>{mediaprint}</style>
            <XeleTable
              data={reportDto}
              headerData={reportSchema}
              defaultActions={[]}
              setSortBy={setSortBy}
              activePage={1}
              itemsPerPage={1000000}
              totalItems={(reportDto && reportDto.length) || 0}
              parentTranslationPath={parentTranslationPath}
            />
          </div>
        </div>
      </div>

      <footer>
        <img
          alt='FooterPSITemplet'
          src={'https://static.psi-crm.com/footer.png' || null}
          style={{ width: '100%', maxHeight }}
        />
      </footer>
    </div>
  );
};

// const getItemdATA = (type) => { SystemReportsResultAPIALLDATA(type, reportDto); };
// const onExportClickedPDF = () => { getItemdATA('pdf'); };
// const onExportClickedjson = () => { getItemdATA('json'); };
// const SystemReportsResultAPIALLDATA = useCallback(async (type, ssss) => {
//   setIsLoading(true);
//   exportDataToFormats(type, `${GetParams('Report') || 'ReportView'} ${DateNow.toString()}`, ssss);
//   setIsLoading(false);
// }, []);
// const exportDataToFormats = async (Format, fileName, data) => {
//   let exportType = null;
//   if (Format !== 'pdf') {
//     switch (Format) {
//       case 'xls':
//         exportType = exportFromJSON.types.xls;
//         break;
//       case 'csv':
//         exportType = exportFromJSON.types.csv;
//         break;
//       case 'xml':
//         exportType = exportFromJSON.types.xml;
//         break;
//       case 'json':
//         exportType = exportFromJSON.types.json;
//         break;
//       default:
//         exportType = exportFromJSON.types.xls;
//         break;
//     }
//     exportFromJSON({ data, fileName, exportType });
//     const ress = await ExportReportServices();
//     if (ress)
//       return;
//   }
// };
// const [withLogo, setwithLogo] = useState(GetParams('withLogo'));
// const DateNow = Date();
// const printPartOfPage = async () => {
//   window.print();
// };
