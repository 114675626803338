import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import { XeleAutocomplete, XeleInput } from '../../../Components';
import { useTranslation } from 'react-i18next';
import { OrganizationUserSearch } from '../../../Services/userServices';

const ShareViaWhatsAppForm = ({
  selected,
  setSelected,
  getWhatsAppDocUrl,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared'],  {
    keyPrefix: translationPath,
  });
  const searchTimer = useRef(null);
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [data, setData] = useReducer(reducer, {
    leads: [],
    systemUsers: [],
  });

  const getSystemUsers = async (value) => {
    const res = await OrganizationUserSearch({
      pageIndex: 0,
      pageSize: 10,
      name: value || '',
      userStatusId: 2,
    });
    if (!(res && res.status && res.status !== 200)) {
      const localValue = (res && res.result) || [];
      if (localValue.length > 0) {
        setData({
          id: 'systemUsers',
          value: localValue,
        });
      } else {
        setData({
          id: 'systemUsers',
          value: [],
        });
      }
    }
  };

  const fillInitialWhatsappMsgBody = async () => {
    const msg = await getWhatsAppDocUrl();
    setSelected({ id: 'initialWhatsappMsgBody', value: msg });
  };

  useEffect(() => {
    if (!selected.whatsappMsgBody) fillInitialWhatsappMsgBody();
  }, [selected.whatsappMsgBody]);

  return (
    <div className='dialog-content-wrapper'>
      <div className='dialog-content-item  d-flex mx-2'>
        <XeleInput
          idRef='whatsappMsgBodyRef'
          labelValue='message-Body'
          value={selected.whatsappMsgBody || selected.initialWhatsappMsgBody}
          multiline
          rows={6}
          parentTranslationPath={parentTranslationPath}
          inputPlaceholder={`Dear Customer, Please find the document you requested here https//example.com .`}
          translationPath={translationPath}
          onInputChanged={(event) => {
            const value = event.target.value || '';
            setSelected({ id: 'whatsappMsgBody', value: value });
          }}
        />
      </div>

      <fieldset className='styled-fieldset d-flex mx-3'>
        <legend className='styled-legend'>
          {t(`send-to`)}
        </legend>
        <div className='dialog-content-item d-flex-column-center'>
          <XeleAutocomplete
            idRef='System-usersRef'
            inputPlaceholder={t(`select`)}
            labelValue={t(`System-user`)}
            selectedValues={selected.recipient?.WhatsApp?.systemUser}
            wrapperClasses='mr-2 my-2'
            multiple={false}
            isDisabled={selected.recipient?.WhatsApp?.lead}
            data={data.systemUsers || []}
            withoutSearchButton
            displayLabel={(option) => option.fullName || ''}
            renderOption={(option) =>
              ((option.userName || option.fullName) &&
                `${option.fullName} (${option.userName})`) ||
              ''
            }
            onChange={(event, newValue) => {
              const newRecipient = {
                ...selected.recipient,
                WhatsApp: {
                  systemUser: newValue,
                  lead: null,
                },
              };
              setSelected({
                id: 'recipient',
                value: newRecipient,
              });
            }}
            onOpen={() => {
              if (data.systemUsers && data.systemUsers.length === 0)
                getSystemUsers();
            }}
            onInputKeyUp={(e) => {
              const { value } = e.target;
              if (searchTimer) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                getSystemUsers(value);
              }, 800);
            }}
          />
          <XeleAutocomplete
            idRef='leadsRef'
            inputPlaceholder={t(`select`)}
            labelValue={t(`lead`)}
            selectedValues={selected.recipient?.WhatsApp?.lead}
            wrapperClasses='mr-2 my-2'
            data={data.leads || []}
            multiple={false}
            isDisabled={selected.recipient?.WhatsApp?.systemUser}
            withoutSearchButton
            displayLabel={(option) =>
              option?.lead?.contact_name?.name ||
              option?.lead?.company_name ||
              ''
            }
            renderOption={(option) =>
              ((option.userName ||
                option?.lead?.contact_name?.name ||
                option?.lead?.company_name) &&
                `${
                  option?.lead?.contact_name?.name || option?.lead?.company_name
                }`) ||
              ''
            }
            onChange={(_, newValue) => {
              const newRecipient = {
                ...selected.recipient,
                WhatsApp: {
                  lead: newValue,
                  systemUser: null,
                },
              };
              setSelected({
                id: 'recipient',
                value: newRecipient,
              });
            }}
          />
        </div>
      </fieldset>
    </div>
  );
};

export default ShareViaWhatsAppForm;
