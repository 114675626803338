import React, { memo, useRef } from 'react';
import Collapse from '@material-ui/core/Collapse';
import { PropTypes } from 'prop-types';
import { useOnClickOutside } from '../../Hubs';

const XeleCollapseComponent = ({
  isOpen,
  top = 0,
  component,
  onClickOutside,
  classes = 'absolute-collapse',
  isCentered = false,
}) => {
  const collapseRef = useRef(null);
  useOnClickOutside(collapseRef, onClickOutside);
  return (
    <Collapse
      in={isOpen}
      ref={collapseRef}
      className={`collapses ${classes}${isCentered ? ' is-centered' : ''}`}
      style={{ top }}
    >
      {component}
    </Collapse>
  );
};
XeleCollapseComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  component: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.func,
    PropTypes.node,
  ]).isRequired,
  onClickOutside: PropTypes.func,
  top: PropTypes.number,
  classes: PropTypes.string,
  isCentered: PropTypes.bool,
};

export const XeleCollapse = memo(XeleCollapseComponent);
