import moment from 'moment';

export function PassportRule(id, item, itemList, value, setData) {
  if (id === 'passport_issue-date') {
    const i = itemList.indexOf(
      itemList.find((f) => f.field.id.toLowerCase() === 'passport_expiry-date')
    );
    if (i !== -1) itemList[i].data.minDate = moment(value).add(1, 'days');
    setData('passport_expiry_date', {});
    setData('passport_expiry-date', null);
  }
}

export function PassportRule2(id, item, itemList, value, onValueChanged) {
  if (id === 'passport_issue_date') {
    const i = itemList.indexOf(
      itemList.find((f) => f.field.id.toLowerCase() === 'passport_expiry_date')
    );
    if (i !== -1) itemList[i].data.minDate = moment(value).add(1, 'days');
    onValueChanged(null, 0, 'passport_expiry_date');
  }
}
