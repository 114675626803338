import { lazy } from 'react';


const TalentPoolView = lazy(() =>
    import('../../Views/Home/TalentPoolView').then((module) => ({
      default: module.TalentPoolView,
    }))
  );

export const TalentPoolRoutes = [
  {
    id: 1,
    path: '/view',
    name: '',
    component: TalentPoolView,
    layout: '/home/talent-pool',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 2,
    order: 2,
    icon: 'mdi mdi-menu c-black-light',
    iconActive: 'mdi mdi-menu c-black-light',
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'homeLayout.talent-pool-view.talent-pool',
        isDisabled: true,
        route: '/home/talentPool/View',
        groupName: 'candidates',
      },
    ],
  },
];
