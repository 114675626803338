import React from 'react';

export const SupportFooter = () => {
  return (
    <div className='support-footer-wrapper'>
      <p className='footer-content'>
        © 2023 Acceleration International Of Renewable Energy And Technology
      </p>
    </div>
  );
};
