export const preventSelectAll = () => {
  let keyCodeHolder = 0;
  document.onkeydown = (e) => {
    if (e.ctrlKey) keyCodeHolder = e.keyCode;
    if (keyCodeHolder === 65) e.preventDefault();
  };
  document.onkeyup = () => {
    keyCodeHolder = 0;
  };
};
