import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import genric from './genric/Reducer';
import login from './login/Reducer';
import forms from './forms/Reducer';
import formbuilder from './formbuilder';
import files from './file/Reducer';
import lookups from './lookups/Reducer';
import layout from './layout/Reducer';
import theme from './theme/Reducer';

import { LoggerReducer } from './Logger/LoggerReducer';
import { ActiveItemReducer } from './ActiveItem/ActiveItemReducer';
import { TableColumnsFilterReducer } from './TableColumnsFilter/TableColumnsFilterReducer';
import { GlobalOrderFilterReducer } from './GlobalOrderFilter/GlobalOrderFilterReducer';
import { GlobalAdvanceSearchReducer } from './GlobalAdvanceSearch/GlobalAdvanceSearchReducer';

const themePersistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['theme', 'layout'],
};


const rootReducer = combineReducers({
  genric,
  login,
  forms,
  formbuilder,
  files,
  lookups,
  LoggerReducer,
  ActiveItemReducer,
  TableColumnsFilterReducer,
  GlobalOrderFilterReducer,
  GlobalAdvanceSearchReducer,
  layout,
  theme,
});

const persistedReducer = persistReducer(themePersistConfig, rootReducer);

export default persistedReducer;
