import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StaticLookupsIds } from '../../assets/json/StaticLookupsIds.jsx';
import { XeleAutocomplete } from '../../Components';
import { lookupItemsGetId } from '../../Services/LookupsServices.jsx';

export const LocationFieldsComponent = ({
  parentTranslationPath,
  translationPath,
  isWithoutCountry,
  isWithoutCity,
  isWithoutDistrict,
  isWithoutCommunity,
  isWithoutSubCommunity,
  onChangeHandlers,
  isClearFiltersClicked,
}) => {
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const { t } = useTranslation(parentTranslationPath, {
    keyPrefix: translationPath,
  });

  const [selected, setSelected] = useReducer(reducer, {
    Country: null,
    City: null,
    District: null,
    Community: null,
    SubCommunity: null,
  });
  const [isLoading, setIsLoading] = useReducer(reducer, {
    Country: false,
    Cities: false,
    District: false,
    Community: false,
    SubCommunity: false,
  });
  const [data, setData] = useReducer(reducer, {
    Country: [],
    Cities: [],
    District: [],
    Community: [],
    SubCommunity: [],
  });

  const getAddressLookupsById = async (lookupTypeName, lookupParentId) => {
    setIsLoading({ id: lookupTypeName, value: true });
    const response = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds[lookupTypeName],
      lookupParentId,
    });
    if (!(response && response.status && response.status !== 200)) {
      setData({ id: lookupTypeName, value: response || [] });
    }
    setIsLoading({ id: lookupTypeName, value: false });
  };

  const autoFillLocationHandler = (locationKey, locationValue) => {
    const lookupItemParents = JSON.parse(locationValue.lookupItemParents);
    const autoFilledLocation = {};
    lookupItemParents.forEach(
      (item) =>
        (autoFilledLocation[item.LookupType] = {
          lookupItemId: item.LookupItemId,
          lookupItemName: item.LookupItemName,
        })
    );

    setSelected({
      id: 'edit',
      value: {
        Country: null,
        City: null,
        District: null,
        Community: null,
        SubCommunity: null,
        ...autoFilledLocation,
        [locationKey]: locationValue,
      },
    });
  };

  useEffect(() => {
    if (isClearFiltersClicked) {
      setSelected({
        id: 'edit',
        value: {
          Country: null,
          City: null,
          District: null,
          Community: null,
          SubCommunity: null,
        },
      });
    }
  }, [isClearFiltersClicked]);

  return (
    <div className='d-flex'>
      {!isWithoutCountry && (
        <XeleAutocomplete
          idRef='countryfilterRef'
          labelValue={t(`country`)}
          selectedValues={selected.Country || []}
          wrapperClasses='w-min-unset m-2'
          multiple={false}
          data={data.Country || []}
          displayLabel={(option) => option.lookupItemName || ''}
          withoutSearchButton
          isWithError
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          isLoading={isLoading.Country}
          onOpen={() => {
            if (data.Country && data.Country.length === 0)
              getAddressLookupsById('Country');
          }}
          onChange={(_, newValue) => {
            setSelected({
              id: 'edit',
              value: {
                Country: newValue,
                City: null,
                District: null,
                Community: null,
                SubCommunity: null,
              },
            });
            onChangeHandlers?.countryHandler &&
              onChangeHandlers.countryHandler(newValue);
          }}
        />
      )}
      {!isWithoutCity && (
        <XeleAutocomplete
          idRef='cityfilterRef'
          labelValue={t(`city`)}
          selectedValues={selected.City || []}
          wrapperClasses='w-min-unset m-2'
          multiple={false}
          data={data.Cities || []}
          isLoading={isLoading.City}
          displayLabel={(option) => option.lookupItemName || ''}
          withoutSearchButton
          isWithError
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onOpen={() => {
            const countryId = selected.Country?.lookupItemId;
            if (countryId) getAddressLookupsById('Cities', countryId);
            else getAddressLookupsById('Cities');
          }}
          onChange={(_, newValue) => {
            if (newValue) autoFillLocationHandler('City', newValue);
            else
              setSelected({
                id: 'edit',
                value: {
                  ...selected,
                  City: newValue,
                  District: null,
                  Community: null,
                  SubCommunity: null,
                },
              });

            onChangeHandlers?.cityHandler &&
              onChangeHandlers.cityHandler(newValue);
          }}
        />
      )}
      {!isWithoutDistrict && (
        <XeleAutocomplete
          idRef='lfilterDistricteIdRef'
          labelValue={t(`district`)}
          wrapperClasses='w-min-unset m-2'
          selectedValues={selected.District || []}
          multiple={false}
          data={data.District || []}
          isLoading={isLoading.District}
          onOpen={() => {
            const cityId = selected.City?.lookupItemId;
            if (cityId) getAddressLookupsById('District', cityId);
            else getAddressLookupsById('District');
          }}
          displayLabel={(option) => option.lookupItemName || ''}
          withoutSearchButton
          isWithError
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(_, newValue) => {
            if (newValue) autoFillLocationHandler('District', newValue);
            else
              setSelected({
                id: 'edit',
                value: {
                  ...selected,
                  District: newValue,
                  Community: null,
                  SubCommunity: null,
                },
              });

            onChangeHandlers?.districtHandler &&
              onChangeHandlers.districtHandler(newValue);
          }}
        />
      )}
      {!isWithoutCommunity && (
        <XeleAutocomplete
          idRef='lfilterCommunitiesIdRef'
          labelValue={t(`community`)}
          wrapperClasses='w-min-unset m-2'
          selectedValues={selected.Community || null}
          multiple={false}
          data={data.Community || []}
          isLoading={isLoading.Community}
          onOpen={() => {
            const districtId = selected.District?.lookupItemId;
            if (districtId) getAddressLookupsById('Community', districtId);
            else getAddressLookupsById('Community');
          }}
          displayLabel={(option) => option.lookupItemName || ''}
          withoutSearchButton
          isWithError
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(_, newValue) => {
            if (newValue) autoFillLocationHandler('Community', newValue);
            else
              setSelected({
                id: 'edit',
                value: { ...selected, Community: newValue, SubCommunity: null },
              });

            onChangeHandlers?.communityHandler &&
              onChangeHandlers.communityHandler(newValue);
          }}
        />
      )}

      {!isWithoutSubCommunity && (
        <XeleAutocomplete
          idRef='lfilterSubCommunitiesIdRef'
          labelValue={t(`SubCommunity`)}
          wrapperClasses='w-min-unset m-2'
          selectedValues={selected.SubCommunity || null}
          multiple={false}
          data={data.SubCommunity || []}
          isLoading={isLoading.SubCommunity}
          onOpen={() => {
            const communityId = selected.Community?.lookupItemId;
            if (communityId) getAddressLookupsById('SubCommunity', communityId);
            else getAddressLookupsById('SubCommunity');
          }}
          displayLabel={(option) => option.lookupItemName || ''}
          withoutSearchButton
          isWithError
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(_, newValue) => {
            if (newValue) autoFillLocationHandler('SubCommunity', newValue);
            else
              setSelected({
                id: 'edit',
                value: { ...selected, SubCommunity: newValue },
              });

            onChangeHandlers?.subCommunityHandler &&
              onChangeHandlers.subCommunityHandler(newValue);
          }}
        />
      )}
    </div>
  );
};
