import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch';
import { useTranslation } from 'react-i18next';

export const XeleSwitch = ({
  isChecked,
  onChangeHandler,
  parentTranslationPath = '',
  translationPath = '',
  wrapperClasses = '',
  themeClass = 'theme-default',
  switchLabelClasses = '',
  switchClasses = '',
  labelValue,
  idRef = 'switchRef',
  labelClasses = '',
  isDisabled,
  switchLabelRef,
  label,
  switchControlWrapperRef,
}) => {
  const { t } = useTranslation(parentTranslationPath, {
    keyPrefix: translationPath,
  });
  return (
    <FormControl
      ref={switchControlWrapperRef}
      className={`switch-control-wrapper ${wrapperClasses} ${themeClass || ''}`}
    >
      {labelValue && (
        <label
          htmlFor={idRef}
          className={`label-wrapper ${labelClasses}${
            isDisabled ? ' disabled' : ''
          }`}
        >
          {t(`${labelValue}`)}
        </label>
      )}
      <FormControlLabel
        id={idRef}
        ref={switchLabelRef}
        className={`switch-control-label-wrapper ${switchLabelClasses}`}
        control={
          <Switch
            className={`switch-control ${switchClasses}`}
            checked={isChecked}
            onChange={onChangeHandler}
          />
        }
        label={(label && t(`${label}`)) || undefined}
        disabled={isDisabled}
      />
    </FormControl>
  );
};

XeleSwitch.propTypes = {
  isChecked: PropTypes.bool,
  onChangeHandler: PropTypes.func,
  parentTranslationPath: PropTypes.string,
  translationPath: PropTypes.string,
  wrapperClasses: PropTypes.string,
  themeClass: PropTypes.oneOf(['theme-default', 'theme-line']),
  switchLabelClasses: PropTypes.string,
  switchClasses: PropTypes.string,
  labelValue: PropTypes.string,
  idRef: PropTypes.string,
  labelClasses: PropTypes.string,
  isDisabled: PropTypes.bool,
  switchLabelRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  switchControlWrapperRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  label: PropTypes.string,
};
