import { lazy } from 'react';

const RotationSchemaView = lazy(() =>
  import('../../Views/Home/RotationSchemaView/RotationSchemaView').then(
    (module) => ({
      default: module.RotationSchemaView,
    })
  )
);
const AssignRecruitersTable = lazy(() =>
  import(
    '../../Views/Home/RotationSchemaView/components/AssignRecruiters/AssignRecruitersTable'
  ).then((module) => ({
    default: module.AssignRecruitersTable,
  }))
);

export const RotationSchemaRoutes = [
  {
    id: 1,
    path: '/view',
    name: '',
    component: RotationSchemaView,
    layout: '/home/rotation-criteria',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 5,
    order: 2,
    icon: 'mdi mdi-menu c-black-light',
    iconActive: 'mdi mdi-menu c-black-light',
    isDisabled: false,
    showInMenu: false,
    breadcrumbs: [
      {
        name: 'RotationView:rotation-criteria',
        isDisabled: true,
        route: '/home/rotation-criteria/view',
        groupName: 'rotation-schema',
      },
    ],
  },
  {
    id: 1,
    path: '/manage/:id',
    name: '',
    component: AssignRecruitersTable,
    layout: '/home/rotation-criteria',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 5,
    order: 3,
    icon: 'mdi mdi-menu c-black-light',
    iconActive: 'mdi mdi-menu c-black-light',
    isDisabled: false,
    showInMenu: false,
    breadcrumbs: [
      {
        name: 'RotationView:rotation-criteria',
        isDisabled: false,
        route: '/home/rotation-criteria/view',
        groupName: 'rotation-schema',
      },
      {
        name: 'RotationView:assign-new-recruiter',
        isDisabled: true,
        route: '/home/RotationSchema/manage/:id',
      },
    ],
  },
];
