import React from 'react';
import PropTypes from 'prop-types';
import './CellsSpinner.scss';

export const CellsSpinner = ({ isActive, isAbsolute, isSmall }) => (
  <>
    {isActive && (
      <div
        className={`CellsSpinner-wrapper${isAbsolute ? '  is-absolute' : ''}${
          (isSmall && ' is-small') || ''
        }`}
      >
        <div className='app-spinner'>
          <div className='hex-border'>
            <div className='hexagons'>
              <div className='hexagon' />
              <div className='hexagon' />
              <div className='hexagon' />
              <div className='hexagon' />
              <div className='hexagon' />
              <div className='hexagon' />
              <div className='hexagon' />
            </div>
          </div>
        </div>
      </div>
    )}
  </>
);
CellsSpinner.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isAbsolute: PropTypes.bool,
  isSmall: PropTypes.bool,
  isWithoutText: PropTypes.bool,
};
