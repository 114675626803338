import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { showSuccess, showError, GetParams } from '../../../../../../../Helper';
import {
  Spinner,
  XeleAutocomplete,
  XeleDialog,
  XeleCheckbox,
} from '../../../../../../../Components';
import { GetAllRoles } from '../../../../../../../Services/roleServices';
import {
  AssignRolesToUser,
  RemoveRolesFromUser,
  GetAllRolesByUserId,
} from '../../../../../../../Services/UsersServices/userServices';
import {
  GetInActiveUserRoles,
  RemoveRoleFromInActiveUser,
  AssignRolesToInActiveUser,
  OrganizationUserSearch,
} from '../../../../../../../Services/userServices';
import './AssignRoleDialog.scss';

const AssignRoleDialog = ({
  userId,
  userFullName,
  isOpen,
  isOpenChanged,
  reloadData,
  userStatus,
}) => {
  const isUserActive = useMemo(() => userStatus === 'Active', [userStatus]);
  const [roles, setRoles] = useState({ result: [], totalCount: 0 });
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [isCopyChecked, setIsCopyChecked] = useState(false);
  const [savedRoles, setSavedRoles] = useState({
    result: [],
    totalCount: 0,
  });
  const [referredToList, setReferredToList] = useState({
    result: [],
    totalCount: 0,
  });
  const [referredToAgent, setReferredToAgent] = useState(null);
  const [optionsData, setOptionsData] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [referredToId, setReferredToId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [openDialogRoles, setOpenDialogRoles] = useState(false);
  const translationPath = 'AssignRoleDialog';
  const { t } = useTranslation('UsersView', {
    keyPrefix: translationPath,
  });
  const searchTimer = useRef(null);

  const getRoles = useCallback(async (value) => {
    setIsLoading(true);
    const response = await GetAllRoles(1, 20, value);

    setRoles({
      result: (response && response.result) || [],
      totalCount: (response && response.totalCount) || 0,
    });
    setIsLoading(false);
  }, []);

  const getSaleOrLeaseAgentsRoles = useCallback(
    async (searchValue, roleAgentId) => {
      setIsLoading(true);
      const filter = {
        pageSize: 25,
        name: searchValue,
        pageIndex: 1,
        userStatusId: 2,
        userTypeId: roleAgentId,
      };
      const rs = await OrganizationUserSearch({ ...filter });
      if (!(rs && rs.status && rs.status !== 200))
        setReferredToList({ result: rs.result, totalCount: rs.totalCount });
      else setReferredToList({ result: [], totalCount: 0 });

      setIsLoading(false);
    }
  );

  const getActiveUserRoles = async () => {
    setIsLoading(true);
    const response = await GetAllRolesByUserId(userId, 1, 30);
    setSavedRoles({
      result: response?.result ?? [],
      totalCount: response?.totalCount ?? 0,
    });
    setSelectedRoles(response?.result.map((item) => item.roles) ?? []);
    setIsLoading(false);
  };

  const getInActiveUserRoles = async () => {
    const pageSize = 30;
    const pageIndex = 1;
    const res = await GetInActiveUserRoles({ userId, pageIndex, pageSize });
    if (!(res && res.status && res.status !== 200)) {
      setSavedRoles({
        result: res?.result || [],
        totalCount: res?.totalCount || 0,
      });
      setSelectedRoles(res?.result || []);
    } else {
      setSavedRoles({ result: [], totalCount: 0 });
      setSelectedRoles([]);
    }
  };

  const saveHandler = async (event) => {
    event.preventDefault();
    const notSavedRoles = selectedRoles.filter(
      (item) =>
        savedRoles.result.findIndex(
          (element) => element.rolesId === item.rolesId
        ) === -1
    );
    if (notSavedRoles.length === 0) {
      if (isOpenChanged) isOpenChanged();
      return;
    }
    setIsLoading(true);
    const assignedRoles = notSavedRoles.map((el) => ({
      usersId: userId,
      rolesId: el.rolesId,
    }));
    const response = isUserActive
      ? await AssignRolesToUser(assignedRoles)
      : await AssignRolesToInActiveUser(assignedRoles);

    setIsLoading(false);
    if (response) {
      showSuccess(t`user-roles-saved-successfully`);
      getActiveUserRoles();
      if (reloadData) reloadData();
      if (isOpenChanged) isOpenChanged();
    } else showError(t`user-roles-save-failed`);
  };

  const deleteHandler = (savedDeletedRoles) => {
    setIsLoading(true);
    setData(savedDeletedRoles);
    savedDeletedRoles.map(async (item) => {
      const response = isUserActive
        ? await RemoveRolesFromUser(
            item.userRolesId,
            referredToId,
            isCopyChecked
          )
        : await RemoveRoleFromInActiveUser(userId, item.userRolesId);

      if (response) {
        setSelectedRoles((items) => {
          const deletedIndex = items.findIndex(
            (element) => element.rolesId === item.rolesId
          );
          if (deletedIndex > -1) items.splice(deletedIndex, 1);
          return [...items];
        });
        setSavedRoles((items) => {
          const deletedIndex = items.result.findIndex(
            (element) => element.rolesId === item.rolesId
          );
          if (deletedIndex > -1) {
            items.result.splice(deletedIndex, 1);
            --items.totalCount;
          }
          return { ...items };
        });
        if (reloadData) reloadData();
      }
    });
    setIsChecked(false);
    setReferredToId(null);
    setReferredToAgent(null);
    setIsCopyChecked(false);
    setOpenDialogRoles(false);
    setIsLoading(false);
  };

  const searchHandler = (e) => {
    const { value } = e.target;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      getRoles(value);
    }, 700);
  };

  useEffect(() => {
    setOptionsData(
      selectedRoles.concat(
        roles.result.filter(
          (item) =>
            selectedRoles.findIndex(
              (selectedItem) => selectedItem.rolesId === item.rolesId
            ) === -1
        )
      )
    );
  }, [roles.result, selectedRoles]);

  useEffect(() => {
    if (userId) {
      if (isUserActive) getActiveUserRoles();
      else getInActiveUserRoles();
    }
  }, [userId]);

  useEffect(
    () => () => {
      if (searchTimer.current) clearTimeout(searchTimer.current);
    },
    []
  );

  useEffect(() => {
    if (!isChecked) setReferredToAgent(null);
    if (!referredToId) setIsCopyChecked(false);
  }, [isChecked, referredToId]);

  return (
    <XeleDialog
      titleText={`${t(`save-changes`)} ${userFullName}`}
      saveText={t(`save-changes`)}
      dialogContent={
        <div className='asign-role-dialog view-wrapper'>
          <Spinner isActive={isLoading} />
          <div>
            <XeleDialog
              maxWidth='sm'
              saveType='button'
              saveText='confirm'
              disableBackdropClick
              titleText={t(`Remove-Assigned-Lead`)}
              dialogContent={
                <div>
                  <div className='remove-assigned-lead'>
                    <span className='mdi mdi-24px mdi-account-multiple-remove-outline' />
                  </div>
                  {t(`Warning`)}
                  <div className='remove-Sale-Lease-assigned-lead'>
                    <XeleCheckbox
                      idRef='RotationRef'
                      themeClass='theme-secondary'
                      singleChecked={isChecked}
                      onSelectedCheckboxClicked={() => setIsChecked(!isChecked)}
                    />
                  </div>

                  {isChecked && (
                    <div>
                      <XeleAutocomplete
                        idRef='roleAgentRef'
                        data={(referredToList && referredToList.result) || []}
                        selectedValues={referredToAgent || null}
                        onInputChange={(e) => {
                          if (e && e.target && e.target.value) {
                            const searchValue = e.target.value;
                            setTimeout(() => {
                              getSaleOrLeaseAgentsRoles(searchValue);
                            }, 300);
                          }
                        }}
                        multiple={false}
                        displayLabel={(option) =>
                          (option && option.fullName) || ''
                        }
                        chipsLabel={(option) =>
                          (option && option.fullName) || ''
                        }
                        filterOptions={(options) => {
                          return options.filter(
                            (el) => el.id !== GetParams('id')
                          );
                        }}
                        withoutSearchButton
                        parentTranslationPath={translationPath}
                        translationPath={translationPath}
                        onChange={(event, newValue) => {
                          setReferredToId((newValue && newValue.id) || null);
                          setReferredToAgent(newValue || null);
                        }}
                      />
                      {referredToId && (
                        <XeleCheckbox
                          idRef='withCopyAllActivitesRef'
                          label='withCopyAllActivites'
                          parentTranslationPath='UsersView'
                          translationPath={translationPath}
                          singleChecked={isCopyChecked}
                          onSelectedCheckboxClicked={() => {
                            setIsCopyChecked(!isCopyChecked);
                          }}
                        />
                      )}
                    </div>
                  )}

                  <div className='d-flex-v-center-h-end flex-wrap mt-3'>
                    <Button
                      className='MuiButtonBase-root MuiButton-root MuiButton-text MuiButtonBase-root btns theme-transparent mb-2'
                      type='button'
                      onClick={() => {
                        setOpenDialogRoles(false);
                        setIsChecked(false);
                      }}
                    >
                      <span className='MuiButton-label'>
                        <span className='mx-2'>{t(`Cancel`)}</span>

                        <span className='MuiTouchRipple-root' />
                      </span>
                      <span className='MuiTouchRipple-root' />
                    </Button>
                    <Button
                      className='MuiButtonBase-root btns theme-solid mb-2'
                      type='button'
                      onClick={() => deleteHandler(data)}
                    >
                      <span className='MuiButton-label'>
                        <span className='mx-2'>{t(`continue-anyway`)}</span>
                      </span>
                      <span className='MuiTouchRipple-root' />
                    </Button>
                  </div>
                </div>
              }
              onCloseClicked={() => {
                setOpenDialogRoles(false);
                setIsChecked(false);
              }}
              isOpen={openDialogRoles}
            />
          </div>
          <div className='form-item'>
            <XeleAutocomplete
              idRef='userRoleRef'
              labelValue={t(`user-roles`)}
              inputPlaceholder={t(`user-roles`)}
              selectedValues={selectedRoles}
              data={optionsData}
              chipsLabel={(option) => option?.rolesName || ''}
              displayLabel={(option) => option?.rolesName || ''}
              getOptionSelected={(option) =>
                selectedRoles &&
                selectedRoles.findIndex(
                  (item) => item?.rolesId === option?.rolesId
                ) !== -1
              }
              withoutSearchButton
              onInputKeyUp={(e) => searchHandler(e)}
              onChange={(event, newValue) => {
                setSelectedRoles(newValue);
                let deletedRoles = savedRoles.result.filter(
                  (savedRole) =>
                    !newValue.find((role) => role.rolesId === savedRole.rolesId)
                );
                if (
                  newValue.length === 0 &&
                  savedRoles.result.length > 0 &&
                  deletedRoles.length === 0
                ) {
                  deletedRoles = savedRoles.result;
                }
                if (deletedRoles.length > 0) {
                  deleteHandler(deletedRoles);
                }
              }}
            />
          </div>
        </div>
      }
      isOpen={isOpen}
      onSubmit={saveHandler}
      onCloseClicked={() => {
        setSelectedRoles(
          (savedRoles && savedRoles.result.map((item) => item.roles)) || []
        );
        isOpenChanged();
      }}
      onCancelClicked={() => {
        setSelectedRoles(
          (savedRoles && savedRoles.result.map((item) => item.roles)) || []
        );
        isOpenChanged();
      }}
    />
  );
};

export { AssignRoleDialog };
AssignRoleDialog.propTypes = {
  userFullName: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isOpenChanged: PropTypes.func.isRequired,
  reloadData: PropTypes.func,
};
AssignRoleDialog.defaultProps = {
  reloadData: undefined,
};
