import { ReportRunView } from '../../Views/Home/ReportsBuilderView/ReportRunView/ReportRunView';
import { ReportRunViewTemp } from '../../Views/Home/ReportsBuilderView/ReportRunView/ReportRunViewTemp';
import { ReportPdfView } from '../../Views/Share/ReportPdfView/ReportPdfView';
import { ReleaseNoteView } from '../../Views/Share/ReleaseNoteView';

export const ShareRoutes = [
    {
        path: '/ReportPdfView',
        name: 'ReportPdfView',
        component: ReportPdfView,
        layout: '/share',
        default: true,
        isExact: true,
        authorize: false,
        roles: [],
        showInMenu: false,
        isRoute: true,
    },
    {
        path: '/ReportRunView',
        name: 'ReportRunView',
        component: ReportRunView,
        layout: '/share',
        default: true,
        isExact: true,
        authorize: false,
        roles: [],
        showInMenu: false,
        isRoute: true,
    },
    {
        path: '/ReportRunTempView',
        name: 'ReportRunView',
        component: ReportRunViewTemp,
        layout: '/share',
        default: true,
        isExact: true,
        authorize: false,
        roles: [],
        showInMenu: false,
        isRoute: true,
    },
    {
        path: '/release-note',
        name: 'ReleaseNote',
        component: ReleaseNoteView,
        layout: '/share',
        default: true,
        isExact: true,
        authorize: false,
        roles: [],
        showInMenu: false,
        isRoute: true,
      },
];
