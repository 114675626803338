import { config } from '../config/config';
import { HttpServices } from '../Helper';

export const GetUserPages = async (formsId, pageIndex, pageSize) => {
  const result = await HttpServices.get(
    `${config.server_address}/FormBuilder/Pages/${formsId}/${pageIndex}/${pageSize}`
  )
    .then((data) => data)
    .catch((error) => {
      return undefined;
    });
  return result;
};

export const PostUserViews = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/FormBuilder/Views`,
    body
  )
    .then((data) => data)
    .catch((error) => {
      return undefined;
    });
  return result;
};

export const GetViews = async (pageIndex, pageSize, searchedItem) => {
  const result = await HttpServices.get(
    `${config.server_address}/FormBuilder/Views/${pageIndex}/${pageSize}?search=${searchedItem}`
  )
    .then((data) => data)
    .catch((error) => {
      return undefined;
    });
  return result;
};

export const DeleteViews = async (viewId) => {
  const result = await HttpServices.delete(
    `${config.server_address}/FormBuilder/Views/${viewId}`
  )
    .then((data) => data)
    .catch((error) => {
      return undefined;
    });
  return result;
};

export const GetViewsByViewId = async (viewId) => {
  const result = await HttpServices.get(
    `${config.server_address}/FormBuilder/Views/${viewId}`
  )
    .then((data) => data)
    .catch((error) => {
      return undefined;
    });
  return result;
};

export const EditViewsByViewId = async (viewId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/FormBuilder/Views/${viewId}`,
    body
  )
    .then((data) => data)
    .catch((error) => {
      return undefined;
    });
  return result;
};

export const SavePageViewAsTemplate = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/FormBuilder/Views/SavePageViewAsTemplate`,
    body
  )
    .then((data) => data)
    .catch((error) => {
      return undefined;
    });
  return result;
};

export const GetTemplateByPageId = async (pageId) => {
  const result = await HttpServices.get(
    `${config.server_address}/FormBuilder/Views/GetTemplateByPage/${pageId}`
  )
    .then((data) => data)
    .catch((error) => {
      return undefined;
    });
  return result;
};
