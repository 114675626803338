import { lazy } from 'react';

const Dashboard = lazy(() =>
  import('../../Views/Home/Dashboard/Dashboard').then((module) => ({
    default: module.Dashboard,
  }))
);
export const DashboardRoutes = [
  {
    path: '/dashboard-admin/view',
    name: 'Dashboard:admin-dashboard',
    component: Dashboard,
    layout: '/home/dashboard',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'Dashboard:admin-dashboard',
        isDisabled: true,
        route: '/home/dashboard-admin/view',
        groupName: 'dashboard',
      },
    ],
  },
];
