import React from 'react';
import psiNewLogo from '../../assets/images/defaults/psi-new-logohj.png';

export const SupportHeader = () => {
  return (
    <div className='support-header-wrapper'>
      <div className='header-content'>
        <p className='page-title'>
          <img className='header-logo' src={psiNewLogo} alt='' />
          <span>Property Shop Investment</span>
          <span className='vertical-line'></span>
          <span>Support</span>
        </p>
        <div className='user-section-wrapper'></div>
      </div>
    </div>
  );
};
