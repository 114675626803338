import React, { useCallback, useEffect, useState } from 'react';
import { Slider, Button, InputAdornment } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import ChipInput from 'material-ui-chip-input';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import {
  LookupRule,
  VisaRules,
  LookupsEffectedOnRules,
  UserRule,
  TitleRuleV2,
  UserDefaultRule,
  ListingDateRule2,
  ListingExpiryDateRule2,
  ListingDate,
  PassportRule2,
  ShortageInRule,
  ConstructionDateRule,
  CompletionDateRule,
} from '../../Rule';

import {
  XeleAutocomplete,
  XeleCheckbox,
  XeleDatePicker,
  XeleInput,
  XelePhone,
  XeleRadioGroup,
} from '../Controls';
import { RepeatedItemDialog } from '../../Views/Home/FormBuilder/Dialogs/RepeatedItemDialog';
import { NotHavePolicyToDisplayDataDialog } from '../../Views/Home/FormBuilder/Dialogs/NotHavePolicyToDisplayDataDialog';
import { UploadDialog } from '../../Views/Home/FormBuilder/Dialogs/UploadDialog';
import { RepeatedLinkDialog } from '../../Views/Home/FormBuilder/Dialogs/RepeatedLinkDialog';
import { GetParams, showWarn, GlobalTranslate } from '../../Helper';

import { HighPofileManagementDialog } from '../Utilities';
import { GetSensitiveFieldsForUser } from '../../Services/FormsServices';

export const ConvertJsonV2 = ({
  item, // item properties & data
  itemValue, // value of item
  allItems, // all items as properties
  allItemsValues, // all items values
  // onValueChanged, // on itemValue or on element of properties like enum changed
  onItemChanged, // on itemValue or on element of properties like enum changed
  onValueChanged, // on itemValue or on element of properties like enum changed
  error, // if this item is invalid (bool)
  helperText, // the error message (string)
  isSubmitted,
  // loadings, // array of current items loading on
  onLoadingsChanged,
  isLoading, // currentItemIsLoadingOrNot
  reload,
  save,
  hideDeposit,
  typeOf,
  isOpenInsideForm,
  sensitiveFieldsForUser,
  parentTranslationPath,
  translationPath,
  itemType,
  unitOpertaionTypeValue,
  canChange,
}) => {
  const { t } = useTranslation(['Shared']);
  const { id } = item.field;
  const [fielsdWithPermissions, setFielsdWithPermissions] = useState([
    'high_profile_management',
    'angry_bird',
    'listing_agent',
    'rent_listing_agent',
    'contact_consent_main',
    'contact_consent_assets',
    'contact_dncr',
  ]);
  const [valueIsObjectBefore, setValueIsObjectBefore] = useState([]);
  const label =
    item && item.data && item.data.caption && item.data.caption !== ''
      ? item.data.caption
      : item.data.title;

  const [isOpenHighProfileMangaement, setIsOpenHighProfileMangaement] =
    useState(false);

  const [unitModelPopoverAttachedWith, setUnitModelPopoverAttachedWith] =
    useState(null);
  const [unitOperationTypeField, setUnitOperationTypeField] = useState(null);
  const [isLeadOwnerForm, setIsLeadOwnerForm] = useState(false);
  const [dataInSideViewButton, setDataInSideViewButton] = useState(null);
  const [isSensitiveLoading2, setIsSensitiveLoading2] = useState(0);
  const [openMapDialog, setOpenMapDialog] = useState(false);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [modelsUnitsDialog, setModelsUnitsDialog] = useState(false);
  const [RepeatedDialog, setRepeatedDialog] = useState(false);
  const [repeatedLinkDialog, setRepeatedLinkDialog] = useState(false);
  const [isOpenContactsDialog, setIsOpenContactsDialog] = useState(false);
  const [isOpenLeadOwnerDialog, setIsOpenLeadOwnerDialog] = useState(false);
  const [leadOwnerValue, setLeadOwnerValue] = useState(null);
  const [isOpenLeaseLeadOwnerDialog, setisOpenLeaseLeadOwnerDialog] =
    useState(false);
  const [LeaseleadOwnerValue, setLeaseLeadOwnerValue] = useState(null);
  const [isOpenItemDetails, setIsOpenItemDetails] = useState(false);
  const [sensitiveFieldsForUser2, setSensitiveFieldsForUser2] = useState(null);
  const [isNotHavePolicyToDisplayData, setIsNotHavePolicyToDisplayData] =
    useState(false);
  const [isLoadingDetailsDialog, setIsLoadingDetailsDialog] = useState(false);

  const GetSensitiveFieldsForUserAPI = useCallback(
    async (formId, sensitiveFieldsForUser) => {
      const allSensitiveFields = await GetSensitiveFieldsForUser(
        formId,
        sensitiveFieldsForUser
      );

      if (
        !(
          allSensitiveFields &&
          allSensitiveFields.status &&
          allSensitiveFields.status !== 200
        )
      ) {
        setSensitiveFieldsForUser2(allSensitiveFields);
        setIsSensitiveLoading2(1);
      } else {
        setSensitiveFieldsForUser2([]);
        setIsSensitiveLoading2(2);
      }
    }
  );

  const onSearchFieldActionClicked = useCallback(async () => {
    setIsLoadingDetailsDialog(true);
    let havePolicyToDisplayDataInSideViewButton = false;
    const itemIndex = allItems.findIndex(
      (effectedItem) =>
        effectedItem.data.title.replace('*', '').trim() === item.data.dependOn
    );
    let l = {};
    if (allItemsValues[itemIndex] && itemValue)
      l = { ...itemValue, itemId: allItemsValues[itemIndex] };
    else if (!allItemsValues[itemIndex] && itemValue)
      l = { ...itemValue, itemId: item && item.data && item.data.searchKey };
    else if (allItemsValues[itemIndex])
      l = { itemId: allItemsValues[itemIndex] };
    else l = { itemId: item && item.data && item.data.searchKey };

    localStorage.setItem('current', JSON.stringify(l));
    if (!itemValue) setIsOpenContactsDialog(true);
    else if (havePolicyToDisplayDataInSideViewButton)
      setIsOpenItemDetails(true);
    else if (!havePolicyToDisplayDataInSideViewButton)
      setIsNotHavePolicyToDisplayData(true);

    setIsLoadingDetailsDialog(false);
  }, [
    allItems,
    allItemsValues,
    item.data.dependOn,
    item.data.searchKey,
    itemValue,
  ]);
  const openUnitModelPopoverHandler = useCallback((event) => {
    setUnitModelPopoverAttachedWith(event.currentTarget);
  }, []);
  const onPopoverClose = useCallback(() => {
    setUnitModelPopoverAttachedWith(null);
  }, []);

  useEffect(() => {
    if (id === 'title')
      setTimeout(() => {
        TitleRuleV2(id, onValueChanged, item, allItems, itemValue);
      }, 2000);
  }, []);

  // useEffect(() => {
  //   if (reload) {
  //     if (allItemsValues && allItemsValues.operation_type && allItemsValues.operation_type.lookupItemName && allItemsValues.operation_type.lookupItemName === 'SaleAndRent') {
  //       const operationTypeIndex = allItems.findIndex((f) => f.field.id.toLowerCase() === 'operation_type' && f.data.specialKey === 'UnitOperationType');
  //       if (operationTypeIndex !== -1)
  //         allItems[operationTypeIndex].data.isReadonly = true;
  //     }
  //   }
  // }, [reload]);

  useEffect(() => {
    setTimeout(() => {
      const leadStatus = localStorage.getItem('leadStatus');
      if (leadStatus) {
        const leadStatusJson = JSON.parse(leadStatus);
        if (item.field.id === 'status' && leadStatusJson)
          item.data.isReadonly = true;
        else if (item.field.id === 'status' && leadStatusJson)
          item.data.isReadonly = false;
      }
    }, 300);
  }, [item]);

  const getStatusValue = () => {
    if (item.field.id === 'referredto') {
      const { status } = allItemsValues;
      if (status) return !!(status && status.lookupItemName === 'Closed');
    }
    return false;
  };

  const disableIsMortgageInTenant = () => {
    if (
      item.field.id === 'is_mortgage' &&
      item.data.specialKey === 'is_mortgage_seeker_lead' &&
      allItemsValues &&
      allItemsValues.operation_type &&
      allItemsValues.operation_type.lookupItemName === 'Rent'
    )
      return true;
    if (
      item.field.id === 'mode_of_payment' &&
      item.data.DtoName === 'mode_of_payment_seeker_lead' &&
      allItemsValues &&
      allItemsValues.operation_type &&
      allItemsValues.operation_type.lookupItemName === 'Rent'
    )
      return true;

    return false;
  };

  const getStatusValue2 = () => {
    if (item.field.id === 'status') {
      const { status } = allItemsValues;
      if (status && status.lookupItemName === 'Closed') return true;
    }
    return false;
  };

  const disableQualification = () => {
    if (
      item.field.id === 'qualification' &&
      item.data.specialKey !== 'contact-qualification'
    )
      return true;
    else return false;
  };

  const disableListingExpiryPeriod = () => {
    if (
      item.field.id === 'listing_expiry_period' &&
      !allItemsValues?.listing_date
    )
      return true;
    else if (
      item.field.id === 'rent_listing_expiry_period' &&
      !allItemsValues?.rent_listing_date
    )
      return true;
    else return false;
  };

  const disableRatingLead = () => {
    if (item.field.id === 'rating' && item.data.specialKey === 'rating_lead')
      return true;
    else return false;
  };

  const disableOperationType = () => {
    if (item.field.id === 'operation_type' && item.data.specialKey) return true;
    return false;
  };

  const disableChangePropertyAge = () => {
    if (
      item.field.id === 'property_age' &&
      allItemsValues &&
      allItemsValues.completion_date
    )
      return true;
    else return false;
  };

  const fieldWithPermissionsToEdit = () => {
    if (fielsdWithPermissions.some((f) => f === id)) return true;
    return false;
  };

  const getSendLeadToRotationSelected = () => {
    const sendToRotationIndex = allItems.indexOf(
      allItems.find((f) => f.field.id === 'send_to_rotation')
    );
    if (
      sendToRotationIndex !== -1 &&
      item.field.id === 'referredto' &&
      allItemsValues &&
      allItemsValues.send_to_rotation &&
      allItemsValues.send_to_rotation === 'Yes'
    )
      return true;
    else if (
      sendToRotationIndex !== -1 &&
      item.field.id === 'assign_to' &&
      allItemsValues &&
      allItemsValues.send_to_rotation &&
      allItemsValues.send_to_rotation === 'Yes'
    )
      return true;

    return false;
  };

  const getLeadOperationValue = () => {
    if (item.field.id === 'lead_operation') return true;
    return false;
  };

  const saleTypeOwnerlead = () => {
    if (
      item.field.id === 'sale_type' &&
      item.data.specialKey === 'sale_type_lead_owner' &&
      allItemsValues &&
      allItemsValues.unit_ref_number
    )
      return true;
    return false;
  };

  const getIsLeadAuditor = () => {
    if (item.field.id === 'lead_auditor') return true;
    return false;
  };

  const disableLead = () => {
    if (
      item &&
      item.field &&
      item.field.id === 'lead' &&
      item.data.searchableKey === 'lead_inquiry'
    )
      return true;
    return false;
  };

  const customerProfileSelected = () => {
    const customerProfileIndex =
      allItems &&
      allItems.indexOf(allItems.find((f) => f.field.id === 'customer_profile'));
    if (
      customerProfileIndex !== -1 &&
      allItems &&
      allItems[customerProfileIndex].data.isReadonly
    )
      return true;

    if (
      item.field.id === 'confirmed_employee' &&
      !allItemsValues.customer_profile
    )
      return true;

    if (item.field.id === 'allowance' && !allItemsValues.customer_profile)
      return true;

    if (
      item.field.id === 'liability_from_company' &&
      !allItemsValues.customer_profile
    )
      return true;

    if (
      item.field.id === 'company_name' &&
      !allItemsValues.customer_profile &&
      item.data.specialKey !== 'contact_company_name'
    )
      return true;
    if (item.field.id === 'monthly_income' && !allItemsValues.customer_profile)
      return true;

    if (item.field.id === 'yearly_income' && !allItemsValues.customer_profile)
      return true;
    if (
      item.field.id === 'date_of_establishment' &&
      !allItemsValues.customer_profile
    )
      return true;
    if (
      item.field.id === 'number_of_partner' &&
      !allItemsValues.customer_profile
    )
      return true;

    return false;
  };

  const disableUnitRefNumber = () => {
    if (
      item.field.id === 'unit_ref_number' &&
      item.data.DtoName === 'unit_ref_number_owner_lead'
    ) {
      if (item.data.isReadonly) return true;

      if (!item.data.isReadonly && item.data && item.data.valueToEdit)
        return true;

      if (!item.data.isReadonly && !item.data.valueToEdit) return false;
    }
    return false;
  };

  const getIsUpdateCloseResonAndRemarks = () => {
    if (id === 'close_reason') {
      const leadStatus = localStorage.getItem('leadStatus');
      if (leadStatus) {
        const leadStatusJson = JSON.parse(leadStatus);
        if (leadStatusJson && leadStatusJson.lookupItemName === 'Closed')
          return true;
      }
    } else if (id === 'closing_remarks')
      return !(
        allItemsValues.status &&
        allItemsValues.status.lookupItemName === 'Closed'
      );

    return false;
  };

  const displyTextField = () => {
    if (
      id !== 'rented_value' &&
      item.field.FieldType === 'textField' &&
      (item.data.specialKey === 'currency' ||
        item.data.specialKey === 'size' ||
        item.data.specialKey === 'decimal')
    )
      return true;

    if (
      item.field.FieldType === 'textField' &&
      item.data.specialKey === 'currency' &&
      id === 'rented_value' &&
      allItemsValues &&
      allItemsValues.is_unit_rented &&
      allItemsValues.is_unit_rented === 'Yes'
    )
      return true;

    return false;
  };

  const notUpdateInsideForm = () => {
    if (
      isOpenInsideForm &&
      (item.field.id === 'first_name' ||
        item.field.id === 'last_name' ||
        item.field.id === 'property_name' ||
        item.field.id === 'company_name')
    )
      return true;
    return false;
  };

  const displySelectFields = () => {
    if (
      item.field.id === 'sale_type' &&
      item.data.specialKey === 'sale_type_lead_owner'
    ) {
      const saleTypeIndex = allItems.indexOf(
        allItems.find(
          (f) =>
            f.field.id === 'sale_type' &&
            f.data.specialKey === 'sale_type_lead_owner'
        )
      );
      const leadOperationIndex = allItems.indexOf(
        allItems.find((f) => f.field.id === 'lead_operation')
      );

      if (
        saleTypeIndex !== -1 &&
        leadOperationIndex !== -1 &&
        allItemsValues.lead_operation &&
        allItemsValues.lead_operation === 'Seller'
      )
        return true;

      return false;
    }
    if (item.field.id === 'close_reason')
      return !!(
        allItemsValues &&
        allItemsValues.status &&
        allItemsValues.status.lookupItemName === 'Closed'
      );

    return false;
  };

  const displySearchFields = () => {
    const field = item && item.data && item.data.DtoName;
    const fielsdHavePermissions = [
      'send_to_rotation_listing_shortage',
      'assign_to_listing_shortage',
      'send_to_rotation_zero_matching_sale',
      'assign_to_zero_matching_sale',
      'assign_to_zero_matching_lease',
      'send_to_rotation_zero_matching_lease',
    ];
    if (field && fielsdHavePermissions.some((f) => f === field)) return true;
    if (
      item.field.id === 'sale_type' &&
      item.data.specialKey === 'sale_type_seeker_lead' &&
      allItemsValues &&
      ((allItemsValues.operation_type &&
        (allItemsValues.operation_type.lookupItemName === 'Rent' ||
          allItemsValues.operation_type.lookupItemId === 492)) ||
        !allItemsValues.operation_type)
    )
      return false;

    return true;
  };

  const disableContactRate = () => {
    if (item.field.id === 'contact_rate') return true;
    else return false;
  };

  const isSelectDisallowed = (selectedOption) => {
    let isDisallowedOpt = false;

    if (item.field.id === 'close_reason') {
      isDisallowedOpt =
        selectedOption?.lookupItemName === 'Lead Close by Transaction';
    }

    if (isDisallowedOpt)
      showWarn(
        GlobalTranslate.t(
          'Shared:selecting-this-option-manually-is-not-allowed'
        )
      );
    return isDisallowedOpt;
  };

  useEffect(() => {
    if (item.data.multi && item.data.multi === 'true') {
      if (itemValue && itemValue.constructor === Object)
        setValueIsObjectBefore([itemValue]);
      else if (!itemValue) setValueIsObjectBefore([]);
    }
  }, [item.field.id]);

  useEffect(() => {
    const sellingPriceAgencyFeeIndex = allItems.indexOf(
      allItems.find((f) => f.field.id === 'selling_price_agency_fee')
    );
    if (
      (id === 'seller_has_paid' || id === 'selling_price_agency_fee') &&
      sellingPriceAgencyFeeIndex !== -1 &&
      !allItems[sellingPriceAgencyFeeIndex].data.isReadonly
    ) {
      const sellerhaspaid =
        allItemsValues && allItemsValues.seller_has_paid
          ? allItemsValues.seller_has_paid
          : 0;
      const sellingpriceagencyfee =
        allItemsValues && allItemsValues.selling_price_agency_fee
          ? allItemsValues.selling_price_agency_fee.salePrice
          : 0;
      const sub = sellingpriceagencyfee - sellerhaspaid;

      setTimeout(() => {
        onValueChanged(sub, 0, 'amount_due_to_developer');
      }, 300);
    } else if (
      id === 'email_address' &&
      allItemsValues &&
      allItemsValues.email_address
    ) {
      if (allItemsValues.email_address.email === '')
        onValueChanged(null, 0, 'email_address');
    }

    if (!isSubmitted && item.data.addressType) {
      setTimeout(() => {
        if (
          item &&
          item.data.enum.length === 0 &&
          item.data.uiType === 'select' &&
          !item.data.isFillData
        )
          LookupRule(item, allItems);
      }, 3800);
    } else if (isSubmitted && item.data.uiType === 'select' && error)
      LookupRule(item, allItems);

    const propertyOwnerIndex = allItems.indexOf(
      allItems.find((f) => f.field.id === 'property_owner')
    );

    // if (allItemsValues && allItemsValues.operation_type && (allItemsValues.operation_type.lookupItemName === 'Sale' || allItemsValues.operation_type.lookupItemName === 'SaleAndRent') && item.field.id === 'sale_type') {
    //   if (saleTypeIndex !== -1) {
    //     if(allItems[saleTypeIndex] && allItems[saleTypeIndex].field)
    //     {
    //       allItems[saleTypeIndex].field.isRequired = true;
    //       allItems[saleTypeIndex].data.title = 'Sale Type *';
    //     }
    //   }
    // } else if (allItemsValues && allItemsValues.operation_type && (allItemsValues.operation_type.lookupItemName === 'Rent') && item.field.id === 'sale_type') {
    //   if (saleTypeIndex !== -1) {
    //     if(allItems[saleTypeIndex] && allItems[saleTypeIndex].field)
    //     {
    //       allItems[saleTypeIndex].field.isRequired = false;
    //       allItems[saleTypeIndex].data.title = 'Sale Type';
    //     }
    //   }
    // }

    //felse
    if (
      propertyOwnerIndex &&
      allItemsValues &&
      allItemsValues.property_plan &&
      (allItemsValues.property_plan.lookupItemName === 'Off-Plan Primary' ||
        allItemsValues.property_plan.lookupItemName === 'Off-Plan')
    ) {
      if (allItems[propertyOwnerIndex] && allItems[propertyOwnerIndex].field) {
        allItems[propertyOwnerIndex].field.isRequired = true;
        allItems[propertyOwnerIndex].data.title = 'Property Owner *';
      }
    } else if (
      propertyOwnerIndex &&
      allItemsValues &&
      allItemsValues.property_plan &&
      (allItemsValues.property_plan.lookupItemName === 'Completed' ||
        allItemsValues.property_plan.lookupItemName === 'Pre Launch')
    ) {
      if (allItems[propertyOwnerIndex] && allItems[propertyOwnerIndex].field) {
        allItems[propertyOwnerIndex].field.isRequired = false;
        allItems[propertyOwnerIndex].data.title = 'Property Owner';
      }
    }
  }, [itemValue]);

  useEffect(() => {
    const unitRefNumleadOwnerIndex = allItems.indexOf(
      allItems.find((f) => f.data.DtoName === 'unit_ref_number_owner_lead')
    );
    setIsLeadOwnerForm(unitRefNumleadOwnerIndex !== -1 ? true : false);
  }, []);

  return (
    <>
      {item.field.FieldType === 'UnitsModels' && (
        <div className='form-item'>
          <div className='d-flex-column w-100'>
            <label htmlFor={item.field.id} className='label-wrapper'>
              {label}
            </label>
            <div className='chip-input-wrapper'>
              <ChipInput
                className='chip-input theme-form-builder'
                InputProps={{ autoComplete: 'new-password' }}
                id={item.field.id}
                value={itemValue && itemValue.Models ? itemValue.Models : []}
                onAdd={(chip) => {
                  if (!isNaN(chip)) return;

                  if (
                    itemValue &&
                    itemValue.Models &&
                    itemValue.Models.length > 0
                  ) {
                    Object.keys(itemValue).forEach((key) => {
                      if (
                        typeof itemValue[key] === 'object' &&
                        key !== 'Models'
                      ) {
                        const isExist = itemValue.Models.findIndex(
                          (modelItem) => modelItem === key
                        );
                        if (isExist === -1) delete itemValue[key];
                      }
                    });
                  } else if (
                    itemValue &&
                    itemValue.Models &&
                    itemValue.Models.length === 0
                  ) {
                    Object.keys(itemValue).forEach((key) => {
                      if (
                        typeof itemValue[key] === 'object' &&
                        key !== 'Models'
                      )
                        delete itemValue[key];
                    });
                  }
                  if (itemValue && itemValue.Models) {
                    onValueChanged({
                      ...itemValue,
                      Models: [...itemValue.Models, chip],
                    });
                  } else onValueChanged({ Models: [chip] });
                }}
                onDelete={(chip, itemIndex) => {
                  if (itemValue && itemValue.Models)
                    itemValue.Models.splice(itemIndex, 1);
                  delete itemValue[chip];
                  if (
                    itemValue &&
                    itemValue.Models &&
                    itemValue.Models.length > 0
                  ) {
                    Object.keys(itemValue).forEach((key) => {
                      if (
                        typeof itemValue[key] === 'object' &&
                        key !== 'Models'
                      ) {
                        const isExist = itemValue.Models.findIndex(
                          (modelItem) => modelItem === key
                        );
                        if (isExist === -1) delete itemValue[key];
                      }
                    });
                  } else if (
                    itemValue &&
                    itemValue.Models &&
                    itemValue.Models.length === 0
                  ) {
                    Object.keys(itemValue).forEach((key) => {
                      if (
                        typeof itemValue[key] === 'object' &&
                        key !== 'Models'
                      )
                        delete itemValue[key];
                    });
                    onValueChanged(null, undefined, 'units_models');
                    return;
                  }
                  onValueChanged({ ...itemValue });
                }}
                // variant="standard"
              />
              <Button
                className='ml-2-reversed btns-icon theme-solid bg-blue-lighter'
                disabled={
                  !(
                    itemValue &&
                    itemValue.Models &&
                    itemValue.Models.length > 0
                  )
                }
                onClick={() => {
                  if (
                    itemValue &&
                    itemValue.Models &&
                    itemValue.Models.length > 0
                  )
                    setModelsUnitsDialog(true);
                }}
              >
                <span className='mdi mdi-plus' />
              </Button>
            </div>
          </div>
          {(item?.data?.pendingFieldsHelperInfo?.trim() ||
            item?.data?.HelperInfo?.trim()) && (
            <div className='information-open-file'>
              <Tooltip
                size='large'
                title={
                  item?.data?.pendingFieldsHelperInfo || item.data.HelperInfo
                }
                className={item.data.className ? item.data.className : ''}
              >
                <span className='mdi mdi-information-outline' />
              </Tooltip>
            </div>
          )}
        </div>
      )}
      {((item.field.FieldType === 'address' &&
        (item.data.uiType === 'text' || item.data.uiType === 'map')) ||
        item.field.FieldType === 'MapField') && (
        <div className='form-item'>
          <XeleInput
            labelClasses={item.field.isRequired ? 'c-red' : ''}
            idRef={item.field.id}
            isRequired={item.field.isRequired}
            isDisabled={
              isLoading ||
              item.data.uiType === 'map' ||
              item.field.FieldType === 'MapField' ||
              item.data.specialKey === 'IsDisabled' ||
              item.data.specialKey === 'UnitRefNo' ||
              item.data.isReadonly
            }
            labelValue={label}
            value={
              (itemValue &&
                ((item.data.uiType === 'text' && itemValue.value) ||
                  ((item.data.uiType === 'map' ||
                    item.field.FieldType === 'MapField') &&
                    `${itemValue.latitude} , ${itemValue.longitude}`) ||
                  '')) ||
              ''
            }
            isSubmitted={isSubmitted}
            isWithError
            helperText={helperText}
            error={error}
            onInputChanged={
              (item.data.uiType === 'text' &&
                ((e) => {
                  if (item.data.isReadonly) return;

                  onValueChanged({
                    value: e.target.value,
                    type: item.data.addressType,
                  });
                })) ||
              undefined
            }
            buttonOptions={
              ((item.data.uiType === 'map' ||
                item.field.FieldType === 'MapField') && {
                className: 'btns-icon theme-outline c-blue-lighter',
                iconClasses: 'mdi mdi-map-marker',
                isDisabled: item.data.isReadonly || false,
                isRequired: false,
                onActionClicked: () => {
                  setOpenMapDialog(true);
                },
              }) ||
              undefined
            }
          />
          {(item?.data?.pendingFieldsHelperInfo?.trim() ||
            item?.data?.HelperInfo?.trim()) && (
            <div className='information-open-file'>
              <Tooltip
                size='large'
                title={
                  item?.data?.pendingFieldsHelperInfo || item.data.HelperInfo
                }
                className={item.data.className ? item.data.className : ''}
              >
                <span className='mdi mdi-information-outline' />
              </Tooltip>
            </div>
          )}
        </div>
      )}
      {((item.field.FieldType === 'textField' &&
        item.data.specialKey !== 'currency' &&
        item.data.specialKey !== 'size' &&
        item.data.specialKey !== 'decimal') ||
        item.field.FieldType === 'textarea') && (
        <div className='form-item'>
          <div className='w-100 p-relative'>
            {item.field.id !== 'unit_model' &&
              item.field.id !== 'closing_remarks' && (
                <XeleInput
                  labelClasses={item.field.isRequired ? 'c-red' : ''}
                  idRef={item.field.id}
                  isRequired={item.field.isRequired}
                  isDisabled={
                    isLoading ||
                    getIsUpdateCloseResonAndRemarks() ||
                    customerProfileSelected() ||
                    item.data.isReadonly ||
                    item.data.specialKey === 'IsDisabled' ||
                    item.data.specialKey === 'UnitRefNo' ||
                    notUpdateInsideForm() ||
                    fieldWithPermissionsToEdit()
                  }
                  labelValue={label}
                  value={itemValue || ''}
                  isWithError
                  multiline={item.field.FieldType === 'textarea'}
                  isSubmitted={isSubmitted}
                  helperText={helperText}
                  onInputFocus={
                    (item.field.id === 'unit_model' &&
                      openUnitModelPopoverHandler) ||
                    undefined
                  }
                  onInputBlur={
                    (item.field.id === 'unit_model' && onPopoverClose) ||
                    undefined
                  }
                  error={error}
                  rows={(item.field.FieldType === 'textarea' && 4) || undefined}
                  onInputChanged={(e) => {
                    if (item.data.isReadonly) return;

                    onValueChanged(e.target.value);
                  }}
                />
              )}

            {item.field.id !== 'unit_model' &&
              item.field.id === 'closing_remarks' &&
              allItemsValues.status &&
              allItemsValues.status.lookupItemName === 'Closed' && (
                <XeleInput
                  labelClasses={item.field.isRequired ? 'c-red' : ''}
                  idRef={item.field.id}
                  isRequired={item.field.isRequired}
                  isDisabled={
                    isLoading ||
                    getIsUpdateCloseResonAndRemarks() ||
                    item.data.isReadonly ||
                    item.data.specialKey === 'IsDisabled' ||
                    item.data.specialKey === 'UnitRefNo'
                  }
                  labelValue={label}
                  value={itemValue || ''}
                  isWithError
                  multiline={item.field.FieldType === 'textarea'}
                  isSubmitted={isSubmitted}
                  helperText={helperText}
                  onInputFocus={
                    (item.field.id === 'unit_model' &&
                      openUnitModelPopoverHandler) ||
                    undefined
                  }
                  onInputBlur={
                    (item.field.id === 'unit_model' && onPopoverClose) ||
                    undefined
                  }
                  error={error}
                  rows={(item.field.FieldType === 'textarea' && 4) || undefined}
                  onInputChanged={(e) => {
                    if (item.data.isReadonly) return;

                    onValueChanged(e.target.value);
                  }}
                />
              )}
          </div>
          {(item?.data?.pendingFieldsHelperInfo?.trim() ||
            item?.data?.HelperInfo?.trim()) && (
            <div className='information-open-file-2'>
              <Tooltip
                size='large'
                title={
                  item?.data?.pendingFieldsHelperInfo || item.data.HelperInfo
                }
                className={item.data.className ? item.data.className : ''}
              >
                <span className='mdi mdi-information-outline' />
              </Tooltip>
            </div>
          )}
        </div>
      )}

      {displyTextField() && (
        <div className='form-item'>
          <div className='w-100 p-relative'>
            <XeleInput
              isAttachedInput
              key={item.field.id}
              type={item.data.isReadonly ? 'text' : 'number'}
              endAdornment={
                item.data.specialKey === 'currency' && (
                  <InputAdornment position='start' className='px-2'>
                    AED
                  </InputAdornment>
                )
              }
              labelClasses={item.field.isRequired ? 'c-red' : ''}
              idRef={item.field.id}
              isRequired={item.field.isRequired}
              isDisabled={
                item.data.isReadonly ||
                id === 'amount_due_to_developer' ||
                id === 'pricesqm' ||
                customerProfileSelected() ||
                isLoading
              }
              labelValue={label}
              value={itemValue || ''}
              isWithError
              isSubmitted={isSubmitted}
              helperText={helperText}
              error={error}
              withNumberFormat={!item.data.isReadonly}
              onKeyUp={(e) => {
                if (item.data.isReadonly) return;
                const { value } = e.target;
                if (id === 'pricesqm') {
                  const fixed = value && value.replace(/,/g, '');
                  const editValue = fixed ? parseFloat(fixed) : 0;
                  if (editValue) onValueChanged(parseFloat(editValue));
                  else onValueChanged(editValue);
                }
                const sellingPrice =
                  allItemsValues && allItemsValues.selling_price_agency_fee;
              }}
              onInputChanged={(e) => {
                if (item.data.isReadonly) return;
              }}
            />
          </div>
          {(item?.data?.pendingFieldsHelperInfo?.trim() ||
            item?.data?.HelperInfo?.trim()) && (
            <div className='information-open-file-2'>
              <Tooltip
                size='large'
                title={
                  item?.data?.pendingFieldsHelperInfo || item.data.HelperInfo
                }
                className={item.data.className ? item.data.className : ''}
              >
                <span className='mdi mdi-information-outline' />
              </Tooltip>
            </div>
          )}
        </div>
      )}

      {item.field.FieldType === 'communication' &&
        (item.data.CommunicationType === 'Email' ||
          item.data.CommunicationType === 'SocialMedia') && (
          <div className='form-item'>
            <XeleInput
              labelClasses={item.field.isRequired ? 'c-red' : ''}
              idRef={item.field.id}
              isRequired={item.field.isRequired}
              isDisabled={
                isLoading ||
                item.data.isReadonly ||
                item.data.specialKey === 'IsDisabled' ||
                item.data.specialKey === 'UnitRefNo'
              }
              labelValue={label}
              value={
                (itemValue &&
                  ((item.data.CommunicationType === 'Email' &&
                    itemValue.email) ||
                    itemValue.link ||
                    '')) ||
                ''
              }
              isWithError
              isSubmitted={isSubmitted}
              helperText={helperText}
              error={error}
              onInputChanged={(e) => {
                if (item.data.isReadonly) return;
                if (item.data.CommunicationType === 'Email') {
                  if (e.target.value === '') {
                    onValueChanged(null);
                    return;
                  }
                  onValueChanged({
                    email: e.target.value.toLowerCase(),
                    typeId: item.data.lookupItem,
                  });
                } else {
                  onValueChanged({
                    link: e.target.value,
                    typeId: item.data.lookupItem,
                  });
                }
              }}
              buttonOptions={
                (item.data.CommunicationType === 'Email' && {
                  className: 'btns-icon theme-solid bg-blue-lighter',
                  iconClasses: 'mdi mdi-plus',
                  isDisabled:
                    item.data.isReadonly ||
                    !(
                      itemValue &&
                      new RegExp(item.data.regExp).test(itemValue.email) &&
                      itemValue.email &&
                      itemValue.email.length > 0
                    ),
                  isRequired: false,
                  onActionClicked: () => {
                    if (
                      itemValue &&
                      new RegExp(item.data.regExp).test(itemValue.email) &&
                      itemValue.email &&
                      itemValue.email.length > 0
                    )
                      setRepeatedDialog(true);
                  },
                }) ||
                undefined
              }
            />
            {(item?.data?.pendingFieldsHelperInfo?.trim() ||
              item?.data?.HelperInfo?.trim()) && (
              <div className='information-open-file-2'>
                <Tooltip
                  size='large'
                  title={
                    item?.data?.pendingFieldsHelperInfo || item.data.HelperInfo
                  }
                  className={item.data.className ? item.data.className : ''}
                >
                  <span className='mdi mdi-information-outline' />
                </Tooltip>
              </div>
            )}
          </div>
        )}
      {item.field.FieldType === 'rangeField' && (
        <div className='form-item flex-wrap px-0'>
          <div className='w-50 px-2'>
            <XeleInput
              idRef={item.field.id}
              labelClasses={item.field.isRequired ? 'c-red' : ''}
              labelValue={label}
              inputPlaceholder={t('from')}
              value={itemValue ? itemValue[0] : Number(item.data.minNumber)}
              isRequired={item.field.isRequired}
              helperText={helperText}
              withNumberFormat
              error={error}
              isWithError
              isSubmitted={isSubmitted}
              onInputChanged={(e) => {
                if (item.data.isReadonly) return;
                if (e.target.value === '' || /^\d+$/.test(e.target.value)) {
                  const v = itemValue || [];
                  if (e.target.value === '') {
                    v[0] = parseFloat(Number(item.data.minNumber));
                    onValueChanged(v);
                    return;
                  }
                  const withoutCommaInNumber = parseFloat(
                    e.target.value && e.target.value.replace(/,/g, '')
                  );

                  if (
                    v &&
                    v[0] &&
                    withoutCommaInNumber &&
                    v &&
                    v.length === 2 &&
                    v[1] &&
                    withoutCommaInNumber > Number(v[1])
                  )
                    v[0] = itemValue && itemValue[0];
                  else v[0] = parseFloat(e.target.value);

                  onValueChanged(v);
                }
              }}
              onInputBlur={(e) => {
                if (item.data.isReadonly) return;
                const sizeValue = e.target.value;
                const withoutCommaInNumber = parseFloat(
                  sizeValue && sizeValue.replace(/,/g, '')
                );

                const v = itemValue || [];
                if (
                  withoutCommaInNumber &&
                  v &&
                  v.length === 2 &&
                  v[1] &&
                  withoutCommaInNumber > Number(v[1])
                )
                  return;

                if (withoutCommaInNumber < Number(item.data.minNumber)) {
                  v[0] = Number(item.data.minNumber);
                  onValueChanged(v);
                } else if (
                  Number(withoutCommaInNumber) > Number(item.data.maxNumber)
                ) {
                  v[0] = Number(item.data.maxNumber);
                  onValueChanged(v);
                } else {
                  v[0] = withoutCommaInNumber;
                  onValueChanged(v);
                }
              }}
            />
          </div>
          <div className='w-50 px-2'>
            <XeleInput
              idRef={`${item.field.id}to`}
              labelValue={t('to')}
              value={itemValue ? itemValue[1] : Number(item.data.maxNumber)}
              isRequired={item.field.isRequired}
              labelClasses={item.field.isRequired ? 'c-red' : ''}
              helperText={helperText}
              error={error}
              isWithError
              withNumberFormat
              isSubmitted={isSubmitted}
              onInputChanged={(e) => {
                if (item.data.isReadonly) return;
                if (e.target.value === '' || /^\d+$/.test(e.target.value)) {
                  const v = itemValue || [];
                  if (e.target.value === '') {
                    v[1] = parseFloat(Number(item.data.maxNumber));
                    onValueChanged(v);
                    return;
                  }
                  const withoutCommaInNumber = parseFloat(
                    e.target.value && e.target.value.replace(/,/g, '')
                  );

                  v[1] = parseFloat(withoutCommaInNumber);

                  onValueChanged(v);
                }
              }}
              onInputBlur={(e) => {
                if (item.data.isReadonly) return;
                const sizeValue = e.target.value;
                const v = itemValue || [];
                const withoutCommaInNumber = parseFloat(
                  sizeValue && sizeValue.replace(/,/g, '')
                );

                const marks = [
                  {
                    value: parseFloat(item.data.minNumber),
                    label: `${item.data.minNumber}`,
                  },
                  {
                    value: parseFloat(item.data.maxNumber),
                    label: `${item.data.maxNumber}`,
                  },
                ];

                if (withoutCommaInNumber < Number(item.data.minNumber)) {
                  v[1] = Number(marks[1].value);

                  onValueChanged(v);
                } else if (withoutCommaInNumber > Number(item.data.maxNumber)) {
                  v[1] = Number(item.data.maxNumber);
                  onValueChanged(v);
                } else {
                  v[1] = withoutCommaInNumber;
                  onValueChanged(v);
                }
              }}
            />
          </div>

          <div className='helper_rangField px-4'>
            <Slider
              required={item.field.isRequired}
              className='sliders'
              aria-labelledby='range-slider'
              valueLabelDisplay='auto'
              onChange={(event, value) => {
                if (item.data.isReadonly) return;

                if (
                  !itemValue ||
                  value[0] !== itemValue[0] ||
                  value[1] !== itemValue[1]
                )
                  onValueChanged(value);
              }}
              value={
                itemValue
                  ? [
                      typeof itemValue[0] === 'number'
                        ? parseFloat(itemValue[0].toFixed(2))
                        : null,
                      typeof itemValue[1] === 'number'
                        ? parseFloat(itemValue[1].toFixed(2))
                        : null,
                    ]
                  : [
                      typeof item.data.minNumber === 'number'
                        ? item.data.minNumber
                        : null,
                      typeof item.data.maxNumber === 'number'
                        ? item.data.maxNumber
                        : null,
                    ]
              }
              step={item.field.id === 'size_sqft' ? 200 : 20}
              marks={[
                {
                  value: parseFloat(item.data.minNumber),
                  label: `${parseFloat(item.data.minNumber)}`,
                },
                {
                  value: item.data.maxNumber,
                  label: `${parseFloat(item.data.maxNumber)}`,
                },
              ]}
              min={Number(item.data.minNumber)}
              max={Number(item.data.maxNumber)}
            />
            {(item?.data?.pendingFieldsHelperInfo?.trim() ||
              item?.data?.HelperInfo?.trim()) && (
              // <div className="information-open-file-2">
              <Tooltip
                size='large'
                title={
                  item?.data?.pendingFieldsHelperInfo || item.data.HelperInfo
                }
                className={item.data.className ? item.data.className : ''}
              >
                <span className='mdi mdi-information-outline' />
              </Tooltip>
              // </div>
            )}
          </div>
        </div>
      )}
      {(item.data.CommunicationType === 'Phone' ||
        item.field.FieldType === 'phone') && (
        <div className='form-item'>
          <XelePhone
            labelClasses={item.field.isRequired ? 'c-red' : ''}
            idRef={item.field.id}
            labelValue={label}
            required={item.field.isRequired}
            value={
              itemValue === null || itemValue === undefined || !itemValue.phone
                ? item.data.defaultCountryCode
                : itemValue.phone
            }
            onInputChanged={(value) => {
              if (item.data.isReadonly) return;

              if (value.length > 14) return;

              onValueChanged(
                value !== ''
                  ? { phone: value, typeId: item.data.lookupItem }
                  : null
              );
            }}
            isDisabled={item.data.isReadonly || isLoading}
            isSubmitted={isSubmitted}
            error={error}
            helperText={helperText}
            buttonOptions={{
              className: 'btns-icon theme-solid bg-blue-lighter',
              iconClasses: 'mdi mdi-plus',
              isDisabled:
                item.data.isReadonly ||
                !(itemValue && itemValue.phone && itemValue.phone.length >= 9),
              isRequired: false,
              onActionClicked: () => {
                if (itemValue && itemValue.phone && itemValue.phone.length >= 9)
                  setRepeatedDialog(true);
              },
            }}
          />
          {(item?.data?.pendingFieldsHelperInfo?.trim() ||
            item?.data?.HelperInfo?.trim()) && (
            <div className='information-open-file-2'>
              <Tooltip
                size='large'
                title={
                  item?.data?.pendingFieldsHelperInfo || item.data.HelperInfo
                }
                className={item.data.className ? item.data.className : ''}
              >
                <span className='mdi mdi-information-outline' />
              </Tooltip>
            </div>
          )}
        </div>
      )}
      {item.field.FieldType === 'radio' && (
        <div className='form-item'>
          <XeleRadioGroup
            data={item.data.enum}
            idRef={item.field.id}
            onSelectedRadioChanged={(e) => onValueChanged(e.target.value)}
            value={itemValue}
            // name='Active'
            // labelInput='item.data.title'
            // valueInput='value'
          />
          {(item?.data?.pendingFieldsHelperInfo?.trim() ||
            item?.data?.HelperInfo?.trim()) && (
            <div className='information-open-file-2'>
              <Tooltip
                size='large'
                title={
                  item?.data?.pendingFieldsHelperInfo || item.data.HelperInfo
                }
                className={item.data.className ? item.data.className : ''}
              >
                <span className='mdi mdi-information-outline' />
              </Tooltip>
            </div>
          )}
        </div>
      )}
      {(item.field.FieldType === 'checkbox' ||
        item.field.FieldType === 'checkboxes') && (
        <div className='form-item'>
          <XeleCheckbox
            data={
              (item.field.FieldType === 'checkbox' && [itemValue]) ||
              item.data.items.enum ||
              []
            }
            onSelectedCheckboxChanged={(e) => {
              if (item.field.FieldType === 'checkboxes') {
                const result = (itemValue && [...itemValue]) || [];
                if (e.target.checked && result.indexOf(e.target.value) === -1)
                  result.push(e.target.value);
                else result.splice(result.indexOf(e.target.value), 1);
                onValueChanged(result);
              } else onValueChanged(e.target.checked);
            }}
            isRow
            idRef={item.field.id}
            labelClasses={item.field.isRequired ? 'c-red' : ''}
            labelValue={label}
            required={item.field.isRequired}
            checked={(selectedItem) =>
              itemValue && [...itemValue].indexOf(selectedItem) !== -1
            }
            value={
              (item.field.FieldType === 'checkbox' && itemValue) || undefined
            }
          />
          {(item?.data?.pendingFieldsHelperInfo?.trim() ||
            item?.data?.HelperInfo?.trim()) && (
            <div className='information-open-file-2'>
              <Tooltip
                size='large'
                title={
                  item?.data?.pendingFieldsHelperInfo || item.data.HelperInfo
                }
                className={item.data.className ? item.data.className : ''}
              >
                <span className='mdi mdi-information-outline' />
              </Tooltip>
            </div>
          )}
        </div>
      )}
      {item.field.FieldType === 'alt-date' && (
        <div className='form-item'>
          <XeleDatePicker
            required={item.field.isRequired}
            idRef={item.field.id}
            labelValue={label}
            isDisabled={
              customerProfileSelected() ||
              item.data.isReadonly ||
              isLoading ||
              disableListingExpiryPeriod()
            }
            labelClasses={item.field.isRequired ? 'c-red' : ''}
            value={
              (itemValue && moment(itemValue).isValid() && moment(itemValue)) ||
              null
            }
            helperText={helperText}
            error={error}
            isSubmitted={isSubmitted}
            maxDate={
              item.data.maxDate && moment(new Date(item.data.maxDate)).isValid()
                ? moment(new Date(item.data.maxDate))
                : item.field.id === 'listing_date' ||
                  item.field.id === 'rent_listing_date'
                ? undefined
                : undefined
            }
            minDate={
              item.data.minDate && moment(new Date(item.data.minDate)).isValid()
                ? moment(new Date(item.data.minDate))
                : undefined
            }
            onDateChanged={(e) => {
              if (item.data.isReadonly) return;
              VisaRules(e, item, onValueChanged, allItems);
              PassportRule2(id, item, allItems, e, onValueChanged);
              ListingDate(id, allItems, e);
              ListingExpiryDateRule2(item, onValueChanged);
              ConstructionDateRule(id, item, allItems, e, onValueChanged, 2);
              if (item.field.id === 'completion_date') {
                CompletionDateRule(
                  item.field.id,
                  allItems,
                  e,
                  onValueChanged,
                  2
                );
              }
              if (id === 'listing_date' || id === 'rent_listing_date')
                ListingDateRule2(
                  item,
                  e,
                  onValueChanged,
                  allItems,
                  allItemsValues
                );
              onValueChanged(e);
            }}
          />
          {(item?.data?.pendingFieldsHelperInfo?.trim() ||
            item?.data?.HelperInfo?.trim()) && (
            <div className='information-open-file-2'>
              <Tooltip
                size='large'
                title={
                  item?.data?.pendingFieldsHelperInfo || item.data.HelperInfo
                }
                className={item.data.className ? item.data.className : ''}
              >
                <span className='mdi mdi-information-outline' />
              </Tooltip>
            </div>
          )}
        </div>
      )}
      {displySearchFields() &&
        (item.data.uiType === 'select' ||
          item.field.FieldType === 'UploadFiles' ||
          (item.field.FieldType === 'searchField' &&
            item.data.searchKey === 'User' &&
            UserDefaultRule(
              item,
              onItemChanged,
              allItems,
              allItemsValues,
              GetParams('activeFormType')
            )) ||
          (item.field.FieldType === 'searchField' &&
            item.field.id !== 'owner') ||
          (item.field.FieldType === 'select' &&
            item.field.id !== 'close_reason' &&
            !(
              item.field.id === 'sale_type' &&
              item.data.specialKey === 'sale_type_lead_owner'
            ))) && (
          <div className='form-item'>
            <XeleAutocomplete
              labelClasses={item.field.isRequired ? 'c-red' : ''}
              idRef={item.field.id}
              isRequired={item.field.isRequired}
              isDisabled={
                getStatusValue() ||
                disableQualification() ||
                getLeadOperationValue() ||
                disableUnitRefNumber() ||
                disableIsMortgageInTenant() ||
                customerProfileSelected() ||
                disableLead() ||
                isLoading ||
                item.data.isReadonly ||
                item.data.specialKey === 'IsDisabled' ||
                item.data.specialKey === 'UnitRefNo' ||
                // AssignToInquiresHelper(item.field.id) ||
                getSendLeadToRotationSelected() ||
                getIsLeadAuditor() ||
                fieldWithPermissionsToEdit() ||
                disableContactRate() ||
                disableRatingLead() ||
                disableOperationType() ||
                disableChangePropertyAge() ||
                disableListingExpiryPeriod()
              }
              isLoading={isLoading}
              withLoader
              isWithError
              isSubmitted={isSubmitted}
              helperText={helperText}
              error={error}
              multiple={
                (item.data.multi && item.data.multi === 'true') || false
              }
              filterOptions={
                ((item.data.searchKey === 'property' ||
                  item.data.searchKey === 'contact' ||
                  item.data.searchKey === 'Leads' ||
                  item.data.searchKey === 'PropertyOrUnit') &&
                  ((option) => option)) ||
                undefined
              }
              renderOption={
                (item.data.searchKey === 'property' &&
                  ((option) => (
                    <span>
                      {`${option.name || ''} - ${option.city || ''}  ${
                        (option &&
                          option.property_plan !== undefined &&
                          (`      (      ${
                            (option && option.property_plan) || ''
                          }      ) ` ||
                            '')) ||
                        ''
                      }  ` || ''}
                    </span>
                  ))) ||
                (item.data.searchKey === 'User' &&
                  ((option) => (
                    <div className='d-flex-column'>
                      <div className='d-flex-v-center-h-between w-100 texts-truncate'>
                        {(option && option.name) || (option && option.fullName)}
                      </div>
                      <span className='c-gray-secondary'>
                        {option.branch || ''}
                      </span>
                    </div>
                  ))) ||
                (item.data.searchKey === 'contact' &&
                  ((option) => (
                    <div className='d-flex-column'>
                      <div className='d-flex-v-center-h-between w-100 texts-truncate'>
                        {option.name}
                      </div>
                      <span className='c-gray-secondary'>{option.phone}</span>
                    </div>
                  ))) ||
                (item.data.searchKey === 'Leads' &&
                  ((option) => (
                    <div className='d-flex-column'>
                      <div className='d-flex-v-center-h-between w-100 texts-truncate'>
                        {option.name}
                      </div>
                      <span className='c-gray-secondary'>
                        {option.leadClass}
                      </span>
                    </div>
                  ))) ||
                (item.data.searchKey === 'campaign_name' &&
                  ((option) => (
                    <div className='d-flex-column'>
                      <div className='d-flex-v-center-h-between w-100 texts-truncate'>
                        {option.name}
                      </div>
                    </div>
                  ))) ||
                (item.data.searchKey === 'PropertyOrUnit' &&
                  ((option) =>
                    (option.isProperty && (
                      <span>{`${option.name || ''} - ${
                        option.city || ''
                      }`}</span>
                    )) || (
                      <div className='d-flex-column'>
                        <span>{`${option.unitModel} - ${option.name} - ${option.unitType}`}</span>
                        <span className='c-gray-secondary'>
                          {`${option.unitBedrooms} - ${option.unitRefNo}`}
                        </span>
                      </div>
                    ))) ||
                undefined
              }
              selectedValues={
                item.data.multi && item.data.multi === 'true'
                  ? (itemValue &&
                      item.field.FieldType === 'UploadFiles' &&
                      itemValue &&
                      itemValue.selected &&
                      itemValue.selected.length > 0 && [
                        ...itemValue.selected,
                      ]) ||
                    (itemValue && Array.isArray(itemValue)
                      ? itemValue
                      : valueIsObjectBefore || []) ||
                    []
                  : itemValue || null
              }
              data={[]}
              chipsLabel={
                (item.data &&
                  item.data.multi &&
                  item.data.multi === 'true' &&
                  ((item.field.FieldType === 'searchField' &&
                    ((option) => (option && option.name) || '')) ||
                    ((option) =>
                      !item.data.lookup
                        ? option
                        : (option && option.lookupItemName) || undefined))) ||
                undefined
              }
              onInputChange={
                (item.field.FieldType === 'searchField' &&
                  ((e, v) => {
                    UserRule(
                      item,
                      v,
                      onItemChanged,
                      allItems,
                      allItemsValues,
                      GetParams('activeFormType'),
                      2,
                      onvolumechange
                    );
                  })) ||
                undefined
              }
              inputClasses='inputs theme-form-builder'
              displayLabel={
                (item.field.FieldType === 'searchField' &&
                  ((option) =>
                    (option && `${option.name} ${option.barnch || ''}`) ||
                    '')) ||
                ((option) =>
                  (!item.data.lookup ? option : option.lookupItemName) || '')
              }
              getOptionSelected={
                (item.field.FieldType === 'searchField' &&
                  ((option) =>
                    (item.data.multi &&
                      item.data.multi === 'true' &&
                      itemValue &&
                      (
                        (!Array.isArray(itemValue) && [itemValue]) ||
                        itemValue
                      ).findIndex(
                        (element) =>
                          (element && element.id && element.id === option.id) ||
                          option === element
                      ) !== -1) ||
                    (itemValue.id && itemValue.id === option.id) ||
                    option === itemValue)) ||
                ((option) =>
                  (item.data.multi &&
                    item.data.multi === 'true' &&
                    (
                      (itemValue &&
                        ((item.field.FieldType === 'UploadFiles' &&
                          itemValue &&
                          itemValue.selected &&
                          itemValue.selected.length &&
                          itemValue.selected) ||
                          (!Array.isArray(itemValue) && [itemValue]) ||
                          itemValue)) ||
                      []
                    ).findIndex(
                      (element) =>
                        (element &&
                          element.lookupItemId &&
                          element.lookupItemId === option.lookupItemId) ||
                        option === element
                    ) !== -1) ||
                  ((
                    (item.field.FieldType === 'UploadFiles' &&
                      itemValue &&
                      itemValue.selected) ||
                    itemValue
                  ).lookupItemId &&
                    (
                      (item.field.FieldType === 'UploadFiles' &&
                        itemValue &&
                        itemValue.selected) ||
                      itemValue
                    ).lookupItemId === option.lookupItemId) ||
                  option === itemValue)
              }
              onChange={(e, v) => {
                if (item.data.isReadonly) return;
                if (
                  (item.field.id === 'property_name' &&
                    item.data.DtoName === 'property_name_unit_form') ||
                  (item.field.id === 'property' &&
                    item.data.searchableKey === 'property_inquiries')
                ) {
                  showWarn(
                    t(`${t('Shared:Whoa-hold-on-This-lead-seems-A-bit')}`)
                  );
                }
                if (
                  (id === 'high_profile_management' && v && v === 'Yes') ||
                  (id === 'angry_bird' && v && v === 'Yes')
                ) {
                  setIsOpenHighProfileMangaement(true);
                  return;
                }
                if (
                  item.field.id === 'listing_expiry_period' ||
                  item.field.id === 'rent_listing_expiry_period'
                )
                  ListingDateRule2(
                    item,
                    v,
                    onValueChanged,
                    allItems,
                    allItemsValues
                  );

                if (item.field.id === 'shortage_in')
                  ShortageInRule(allItems, onValueChanged, 2);

                if (item.field.FieldType === 'UploadFiles') {
                  onValueChanged(
                    v && v.length
                      ? itemValue
                        ? { ...itemValue, selected: v }
                        : { selected: v }
                      : null
                  );
                  return;
                }
                TitleRuleV2(id, onValueChanged, item, allItems, v);
                LookupsEffectedOnRules(
                  v,
                  item,
                  onValueChanged,
                  onItemChanged,
                  allItems,
                  allItemsValues,
                  onLoadingsChanged
                );

                onValueChanged(v);
              }}
              withoutSearchButton
              buttonOptions={
                (item.field.FieldType === 'UploadFiles' && {
                  className: 'btns-icon theme-solid bg-blue-lighter',
                  iconClasses:
                    item.field.id === 'external_url'
                      ? 'mdi mdi-plus'
                      : 'mdi mdi-attachment',
                  isDisabled:
                    item.data.isReadonly ||
                    !(
                      itemValue &&
                      itemValue.selected &&
                      itemValue.selected.length > 0
                    ),
                  //||
                  //  (item.field.id === 'unit_ref_number' && !itemValue && isLeadOwnerForm)
                  isRequired: false,
                  onActionClicked: () => {
                    if (
                      itemValue &&
                      itemValue.selected &&
                      itemValue.selected.length > 0 &&
                      item.field.id !== 'external_url'
                    )
                      setOpenUploadDialog(true);
                    if (
                      itemValue &&
                      itemValue.selected &&
                      itemValue.selected.length > 0 &&
                      item.field.id === 'external_url'
                    ) {
                    }
                  },
                }) ||
                (item.field.FieldType === 'searchField' &&
                  item.field.id !== 'referredto' &&
                  item.field.id !== 'referredby' &&
                  !(
                    item.field.id === 'unit_ref_number' &&
                    isLeadOwnerForm &&
                    !itemValue
                  ) &&
                  item.field.id !== 'listing_agent' &&
                  item.field.id !== 'assign_to' &&
                  item.field.id !== 'lead' &&
                  item.field.id !== 'rent_listing_agent' &&
                  item.field.id !== 'inquiry_unit_model' &&
                  item.field.id !== 'campaign_name' &&
                  item.field.id !== 'lead_auditor' && {
                    className: 'btns-icon theme-outline c-blue-lighter',
                    iconClasses: itemValue
                      ? 'mdi mdi-eye-outline'
                      : 'mdi mdi-plus',
                    isDisabled:
                      (item.data.multi && item.data.multi === 'true') ||
                      item.field.id === 'listing_agent',
                    isRequired: false,
                    onActionClicked: onSearchFieldActionClicked,
                  }) ||
                undefined
              }
              labelValue={label}
            />
            {(item?.data?.pendingFieldsHelperInfo?.trim() ||
              item?.data?.HelperInfo?.trim()) && (
              <div className='information-open-file-2'>
                <Tooltip
                  size='large'
                  title={
                    item?.data?.pendingFieldsHelperInfo || item.data.HelperInfo
                  }
                  className={item.data.className ? item.data.className : ''}
                >
                  <span className='mdi mdi-information-outline' />
                </Tooltip>
              </div>
            )}
          </div>
        )}
      {/* /************************ display colse reason when lead status  is closed only  ************************** */}
      {displySelectFields() && (
        <div className='form-item'>
          <XeleAutocomplete
            labelClasses={item.field.isRequired ? 'c-red' : ''}
            idRef={item.field.id}
            isRequired={item.field.isRequired}
            isDisabled={
              getIsUpdateCloseResonAndRemarks() ||
              saleTypeOwnerlead() ||
              isLoading
            }
            isLoading={isLoading}
            withLoader
            isWithError
            isSubmitted={isSubmitted}
            helperText={helperText}
            error={error}
            multiple={(item.data.multi && item.data.multi === 'true') || false}
            filterOptions={
              ((item.data.searchKey === 'property' ||
                item.data.searchKey === 'contact' ||
                item.data.searchKey === 'PropertyOrUnit') &&
                ((option) => option)) ||
              undefined
            }
            renderOption={
              (item.data.searchKey === 'property' &&
                ((option) => (
                  <span>{`${option.name || ''} - ${option.city || ''}`}</span>
                ))) ||
              (item.data.searchKey === 'contact' &&
                ((option) => (
                  <div className='d-flex-column'>
                    <div className='d-flex-v-center-h-between w-100 texts-truncate'>
                      {option.name}
                    </div>
                    <span className='c-gray-secondary'>{option.phone}</span>
                  </div>
                ))) ||
              (item.data.searchKey === 'PropertyOrUnit' &&
                ((option) =>
                  (option.isProperty && (
                    <span>{`${option.name || ''} - ${option.city || ''}`}</span>
                  )) || (
                    <div className='d-flex-column'>
                      <span>{`${option.unitModel} - ${option.name} - ${option.unitType}`}</span>
                      <span className='c-gray-secondary'>
                        {`${option.unitBedrooms} - ${option.unitRefNo}`}
                      </span>
                    </div>
                  ))) ||
              undefined
            }
            selectedValues={
              item.data.multi && item.data.multi === 'true'
                ? (itemValue &&
                    ((item.field.FieldType === 'UploadFiles' &&
                      itemValue &&
                      itemValue.selected && [...itemValue.selected]) ||
                      (!Array.isArray(itemValue) && [itemValue]) || [
                        ...itemValue,
                      ])) ||
                  []
                : itemValue || null
            }
            data={item.data.enum}
            chipsLabel={
              (item.data &&
                item.data.multi &&
                item.data.multi === 'true' &&
                ((item.field.FieldType === 'searchField' &&
                  ((option) => (option && option.name) || '')) ||
                  ((option) =>
                    !item.data.lookup
                      ? option
                      : (option && option.lookupItemName) || undefined))) ||
              undefined
            }
            onInputChange={
              (item.field.FieldType === 'searchField' &&
                ((e, v) => {
                  UserRule(item, v, onItemChanged, allItems, allItemsValues);
                })) ||
              undefined
            }
            inputClasses='inputs theme-form-builder'
            displayLabel={
              (item.field.FieldType === 'searchField' &&
                ((option) => (option && option.name) || '')) ||
              ((option) =>
                (!item.data.lookup ? option : option.lookupItemName) || '')
            }
            getOptionSelected={
              (item.field.FieldType === 'searchField' &&
                ((option) =>
                  (item.data.multi &&
                    item.data.multi === 'true' &&
                    itemValue &&
                    (
                      (!Array.isArray(itemValue) && [itemValue]) ||
                      itemValue
                    ).findIndex(
                      (element) =>
                        (element && element.id && element.id === option.id) ||
                        option === element
                    ) !== -1) ||
                  (itemValue.id && itemValue.id === option.id) ||
                  option === itemValue)) ||
              ((option) =>
                (item.data.multi &&
                  item.data.multi === 'true' &&
                  (
                    (itemValue &&
                      ((item.field.FieldType === 'UploadFiles' &&
                        itemValue &&
                        itemValue.selected) ||
                        (!Array.isArray(itemValue) && [itemValue]) ||
                        itemValue)) ||
                    []
                  ).findIndex(
                    (element) =>
                      (element &&
                        element.lookupItemId &&
                        element.lookupItemId === option.lookupItemId) ||
                      option === element
                  ) !== -1) ||
                ((
                  (item.field.FieldType === 'UploadFiles' &&
                    itemValue &&
                    itemValue.selected) ||
                  itemValue
                ).lookupItemId &&
                  (
                    (item.field.FieldType === 'UploadFiles' &&
                      itemValue &&
                      itemValue.selected) ||
                    itemValue
                  ).lookupItemId === option.lookupItemId) ||
                option === itemValue)
            }
            onChange={(e, v) => {
              if (item.data.isReadonly) return;
              if (isSelectDisallowed(v)) return;

              if (
                item.field.id === 'bedrooms' ||
                item.field.id === 'bathrooms'
              ) {
                const findAnyBathroomsAndBedrooms = v.find(
                  (num) => num === 'Any' || num === 'any'
                );
                if (findAnyBathroomsAndBedrooms) {
                  onValueChanged([findAnyBathroomsAndBedrooms]);
                  return;
                }
              }
              TitleRuleV2(id, onValueChanged, item, allItems, v);
              if (item.field.FieldType === 'UploadFiles')
                onValueChanged({ ...itemValue, selected: v });
              else onValueChanged(v);
              LookupsEffectedOnRules(
                v,
                item,
                onValueChanged,
                onItemChanged,
                allItems,
                allItemsValues,
                onLoadingsChanged
              );
            }}
            withoutSearchButton
            buttonOptions={
              (item.field.FieldType === 'UploadFiles' && {
                className: 'btns-icon theme-solid bg-blue-lighter',
                iconClasses:
                  item.field.id === 'external_url'
                    ? 'mdi mdi-plus'
                    : 'mdi mdi-attachment',
                isDisabled: true,
                // item.data.isReadonly ||
                // !(
                //   itemValue &&
                //   itemValue.selected &&
                //   itemValue.selected.length > 0
                // ) ,

                isRequired: false,
                onActionClicked: () => {
                  if (
                    itemValue &&
                    itemValue.selected &&
                    itemValue.selected.length > 0 &&
                    item.field.id !== 'external_url'
                  )
                    setOpenUploadDialog(true);
                  if (
                    itemValue &&
                    itemValue.selected &&
                    itemValue.selected.length > 0 &&
                    item.field.id === 'external_url'
                  )
                    setRepeatedLinkDialog(true);
                },
              }) ||
              (item.field.FieldType === 'searchField' &&
                item.field.id !== 'referredto' &&
                item.field.id !== 'referredby' && {
                  className: 'btns-icon theme-outline c-blue-lighter',
                  iconClasses: itemValue
                    ? 'mdi mdi-eye-outline'
                    : 'mdi mdi-plus',
                  isDisabled:
                    (item.data.multi && item.data.multi === 'true') ||
                    item.field.id === 'listing_agent',
                  isRequired: false,
                  onActionClicked: onSearchFieldActionClicked,
                }) ||
              undefined
            }
            labelValue={label}
          />
          {(item?.data?.pendingFieldsHelperInfo?.trim() ||
            item?.data?.HelperInfo?.trim()) && (
            <div className='information-open-file-1'>
              <Tooltip
                size='large'
                title={
                  item?.data?.pendingFieldsHelperInfo || item.data.HelperInfo
                }
                className={item.data.className ? item.data.className : ''}
              >
                <span className='mdi mdi-information-outline' />
              </Tooltip>
            </div>
          )}
        </div>
      )}

      {itemValue && itemValue.selected && itemValue.selected.length > 0 && (
        <UploadDialog
          open={openUploadDialog}
          onChange={(e) => {
            onValueChanged({ ...e, selected: itemValue && itemValue.selected });
          }}
          initialState={itemValue}
          closeDialog={() => {
            setOpenUploadDialog(false);
          }}
        />
      )}
      {}

      {isNotHavePolicyToDisplayData && (
        <NotHavePolicyToDisplayDataDialog
          isOpen={isNotHavePolicyToDisplayData}
          isOpenChanged={() => {
            setIsNotHavePolicyToDisplayData(false);
          }}
        />
      )}

      {RepeatedDialog && (
        <RepeatedItemDialog
          open={RepeatedDialog}
          item={item}
          type={(item.data.CommunicationType === 'Email' && 'email') || 'phone'}
          initialState={itemValue}
          label={label}
          closeDialog={() => {
            setRepeatedDialog(false);
          }}
          selectedValues={allItemsValues}
          itemList={allItems}
          onChange={
            (item.data.CommunicationType === 'Email' &&
              ((value) => {
                onValueChanged({
                  ...value,
                  email: itemValue.email,
                  typeId: item.data.lookupItem,
                });
              })) ||
            ((newValue) => {
              onValueChanged({
                ...newValue,
                phone: itemValue.phone,
                typeId: item.data.lookupItem,
              });
            })
          }
        />
      )}
      {repeatedLinkDialog && (
        <RepeatedLinkDialog
          item={item}
          itemList={allItems}
          label={label}
          initialState={itemValue}
          open={repeatedLinkDialog}
          selectedValues={allItemsValues}
          onChange={(newValue) => {
            onValueChanged({
              ...newValue,
            });
          }}
          closeDialog={() => setRepeatedLinkDialog(false)}
        />
      )}
      {isNotHavePolicyToDisplayData && (
        <NotHavePolicyToDisplayDataDialog
          isOpen={isNotHavePolicyToDisplayData}
          isOpenChanged={() => {
            setIsNotHavePolicyToDisplayData(false);
          }}
        />
      )}
      {isOpenHighProfileMangaement && (
        <HighPofileManagementDialog
          isOpenDialog={isOpenHighProfileMangaement}
          onClose={() => setIsOpenHighProfileMangaement(false)}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onCancelClicked={() => {
            setIsOpenHighProfileMangaement(false);
          }}
          onSave={() => {
            onValueChanged('Yes', null, item.field.id);
          }}
        />
      )}
    </>
  );
};
const convertJsonItemShape = PropTypes.shape({
  data: PropTypes.shape({
    enum: PropTypes.instanceOf(Array),
    CommunicationType: PropTypes.string,
    searchKey: PropTypes.string,
    title: PropTypes.string,
    regExp: PropTypes.string,
    dependOn: PropTypes.string,
    uiType: PropTypes.string,
    defaultCountryCode: PropTypes.string,
    minNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isReadonly: PropTypes.bool,
    maxDate: PropTypes.string,
    minDate: PropTypes.string,
    lookup: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    controlType: PropTypes.string, // this type is not sure is string
    multi: PropTypes.oneOf(['true', 'false']),
    lookupItem: PropTypes.number,
    items: PropTypes.shape({
      enum: PropTypes.instanceOf(Array),
    }),
  }),
  field: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    FieldType: PropTypes.string,
    isRequired: PropTypes.bool,
  }),
});
const convertJsonValueShape = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.bool,
  PropTypes.instanceOf(Object),
  PropTypes.instanceOf(Array),
]);
ConvertJsonV2.propTypes = {
  item: convertJsonItemShape.isRequired,
  itemValue: convertJsonValueShape,
  allItems: PropTypes.arrayOf(convertJsonItemShape).isRequired,
  allItemsValues: PropTypes.objectOf(convertJsonValueShape).isRequired,
  onItemChanged: PropTypes.func.isRequired,
  onValueChanged: PropTypes.func.isRequired,
  helperText: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  onLoadingsChanged: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  reload: PropTypes.bool.isRequired,
  save: PropTypes.bool.isRequired,
  hideDeposit: PropTypes.bool,
  typeOf: PropTypes.string,
  isOpenInsideForm: PropTypes.bool,
  sensitiveFieldsForUser: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};
ConvertJsonV2.defaultProps = {
  itemValue: undefined,
  reload: false,
  save: false,
  hideDeposit: false,
  typeOf: '',
  isOpenInsideForm: false,
  sensitiveFieldsForUser: [],
};
