import { HttpServices } from '../Helper';
import { config } from '../config/config';

export const uploadFile = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/FileManager/File/Upload`,
    body
  )
    .then((data) => data)
    .catch((error) => undefined);
  return result;
};

export const ImportFile = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/FileManager/File/Import`,
    body
  )
    .then((data) => data)
    .catch((error) => undefined);
  return result;
};

export const GetMyImportProcess = async (pageIndex, pageSize) => {
  const result = await HttpServices.get(
    `${config.server_address}/FileManager/File/GetMyImportProcess/${pageIndex}/${pageSize}`
  )
    .then((data) => data)
    .catch((error) => undefined);
  return result;
};
export const GetAllImportProcess = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/FileManager/File/GetAllImportProcess`,
    body
  )
    .then((data) => data)
    .catch((error) => undefined);
  return result;
};
