import React, { useState, useCallback, useEffect } from 'react';
import { config } from '../../../config';
import moment from 'moment';
import Mustache from 'mustache';
import Button from '@material-ui/core/Button';
import editIcon from '../../../assets/images/icons/edit-pen.png';
import archiveIcon from '../../../assets/images/icons/release-archive.png';
import loaderOrange from '../../../assets/images/loader-orange.gif';
import { SupportHeader } from '../../../SharedComponents/SupportHeader/SupportHeader';
import { SupportFooter } from '../../../SharedComponents/SupportFooter/SupportFooter';
import { AccordionComponent } from '../../../SharedComponents/AccordionComponent/AccordionComponent';
import { ReleaseDetailsComponent } from './ReleaseDetailsComponent';
import { ReleaseProductManagementForm } from './ReleaseProductManagementForm/ReleaseProductManagementForm';
import {
  XelePopover,
  XeleDialog,
  PermissionsComponent,
} from '../../../Components';
import {
  showError,
  showSuccess,
  returnPropsByPermissions3,
  getDownloadableLink,
} from '../../../Helper';
import { useTitle } from '../../../Hooks';
import { ReleaseNotePermissions } from '../../../Permissions';
import { productReleaseTemplate } from './ProductReleaseTemplate.js';
import { GetLookupItemsByLookupTypeName } from '../../../Services/LookupsServices.jsx';
import {
  DeleteProductRelease,
  ExportReleaseNotePdf,
  GetAllProductReleases,
  GetAllReleaseNotesByProductReleaseId,
} from '../../../Services/ReleaseNotesServices.jsx';

export const ReleaseNoteView = () => {
  useTitle('Release Notes');
  const [productReleases, setProductReleases] = useState([]);
  const [activeReleaseNotes, setActiveReleaseNotes] = useState([]);
  const [categorizedReleaseNotes, setCategorizedReleaseNotes] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [activeAction, setActiveAction] = useState('view');
  const [selectedPopoverAction, setSelectedPopoverAction] = useState(null);
  const [releaseNoteLookups, setReleaseNoteLookups] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isPdfDownloadnig, setIsPdfDownloadnig] = useState(false);

  const handlePopoverOpen = useCallback(
    (event, item) => {
      setActiveItem(item);
      if (activeAction === 'edit') setActiveAction('view');
      event.preventDefault();
      setSelectedPopoverAction(event.currentTarget);
    },
    [activeAction]
  );

  const handlePopoverClose = () => {
    setSelectedPopoverAction(null);
  };

  const reloadData = (isPointOnFirst) => {
    getAllProductReleases(isPointOnFirst);
  };

  const handleProductReleaseCardClick = (item) => {
    setActiveItem(item);
    setActiveAction('view');
  };

  const releaseEditClickHandler = () => {
    setActiveAction('edit');
    handlePopoverClose();
  };

  const displayWithPermissions = () => {
    return returnPropsByPermissions3(
      ReleaseNotePermissions.EditReleaseNote.permissionsId,
      ReleaseNotePermissions.ArchiveReleaseNote.permissionsId,
      ReleaseNotePermissions.ExportReleaseNoteAsPDF.permissionsId
    );
  };

  const releaseDeleteClickHandler = () => {
    const isPointOnFirst = true;
    setActiveAction('view');
    handlePopoverClose();
    if (activeItem) deleteProductRelease();
    reloadData(isPointOnFirst);
  };

  const getReleaseNoteLookups = useCallback(async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: 'ReleaseNoteType',
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      const mappedNoteTypes =
        res?.result &&
        res.result.map((item) => ({
          lookupItemId: item.lookupItemId,
          lookupItemName: item.lookupItemName,
          isVisable: false,
        }));

      setReleaseNoteLookups(mappedNoteTypes || []);
    } else setReleaseNoteLookups([]);
  }, []);

  const deleteProductRelease = useCallback(async () => {
    const productReleaseId = activeItem?.productReleaseId;
    const res = await DeleteProductRelease(productReleaseId);

    if (!(res && res.status && res.status !== 200)) {
      const isPointOnFirst = true;

      getAllProductReleases(isPointOnFirst);
      setIsDeleteDialogOpen(false);
      showSuccess('Product Release Deleted Successfully');
    } else {
      showError('Deleting Product Release Has Failed');
    }
  }, [activeItem]);

  const getAllProductReleases = useCallback(async (isPointOnFirst) => {
    const res = await GetAllProductReleases({
      pageIndex: 1,
      pageSize: 150,
    });
    if (!(res && res.status && res.status !== 200)) {
      setProductReleases(res.result);
      if (isPointOnFirst && res?.result && res.result.length > 0) {
        setActiveItem(res.result[0]);
        setActiveAction('view');
      }
    } else {
      setProductReleases([]);
    }
  }, []);

  function replaceVideoTags(inputString) {
    var videoTagPattern = /<video\b[^>]*>.*?<\/video>/gs;

    inputString = inputString.replace(videoTagPattern, function (match) {
      var srcMatches = match.match(
        /<source\s+src="(.*?)"\s+type="video\/mp4">.*?<source\s+src="(.*?)"\s+type="video\/ogg">/
      );

      if (srcMatches) {
        var oldMp4Source = srcMatches[1];
        var oldOggSource = srcMatches[2];

        return `<video width="400" controls>
                        <source src="${oldMp4Source}" type="video/mp4">
                        <source src="${oldOggSource}" type="video/ogg">
                        Your browser does not support HTML video.
                    </video>`;
      }

      return match;
    });

    return inputString;
  }

  const getAllReleaseNotes = useCallback(async () => {
    const res = await GetAllReleaseNotesByProductReleaseId({
      pageIndex: 1,
      pageSize: 150,
      productReleaseId: activeItem?.productReleaseId,
    });

    if (!(res && res.status && res.status !== 200)) {
      if (res?.result && res.result.length > 0) {
        let categorizedReleaseNotesLocal = {};

        res.result.forEach((item) => {
          categorizedReleaseNotesLocal[item.releaseNoteType] = [];
        });

        Object.keys(categorizedReleaseNotesLocal).forEach((noteCategory) => {
          res.result.forEach((item) => {
            const categoryItems = categorizedReleaseNotesLocal[noteCategory];
            if (noteCategory === item.releaseNoteType) {
              const mappedNoteItem = {
                ...item,
                id: item.releasesNoteId,
                title: item.noteTitle,
                content: replaceVideoTags(item.noteDescription),
              };
              categorizedReleaseNotesLocal[noteCategory] = [
                ...categoryItems,
                mappedNoteItem,
              ];
            }
          });
        });
        setActiveReleaseNotes(res?.result);
        setCategorizedReleaseNotes(categorizedReleaseNotesLocal);
      } else {
        setActiveReleaseNotes([]);
        setCategorizedReleaseNotes(null);
      }
    } else {
      setActiveReleaseNotes([]);
      setCategorizedReleaseNotes(null);
    }
  }, [activeItem]);

  const exportReleaseNotePdf = async (template) => {
    const fileName = `Version_${activeItem.versionNumber}_${activeItem.productTypeDto?.productTypeName}_Release_Note`;
    const body = {
      template,
      releaseNoteTitle: fileName,
      apiKey: config.ApiKey,
      serverName: config.server_name,
    };
    const res = await ExportReleaseNotePdf(body);
    if (!(res && res.status && res.status !== 200)) {
      if (res && res.fileName && res.fileGuid) downloadPdfFile(res);
    }
  };

  const fillTemplateWithData = async () => {
    const dateFormatter = () => (date, render) =>
      date ? moment(render(date)).format('ll') : '';

    const mappedReleaseNotes = [];
    if (categorizedReleaseNotes) {
      for (const [key, value] of Object.entries(categorizedReleaseNotes)) {
        mappedReleaseNotes.push({
          categoryName: key,
          categoryReleaseNotes: value,
        });
      }
    }
    const data = {
      ...activeItem,
      releaseNotes: mappedReleaseNotes,
      dateFormatter,
    };
    const filledTemplate = await Mustache.render(productReleaseTemplate, data);
    return filledTemplate;
  };

  const downloadPdfFile = (pdfFile) => {
    try {
      const linkElement = document.createElement('a');
      linkElement.setAttribute('download', pdfFile?.fileName);
      linkElement.href = getDownloadableLink(pdfFile?.fileGuid);
      document.body.appendChild(linkElement);
      linkElement.click();
      linkElement.remove();
    } catch (error) {}
  };

  const PdfExportHandler = async () => {
    setIsPdfDownloadnig(true);
    if (activeItem) {
      const template = await fillTemplateWithData();
      await exportReleaseNotePdf(template);
    }
    setIsPdfDownloadnig(false);
  };
  useEffect(() => {
    const isPointOnFirst = true;
    getAllProductReleases(isPointOnFirst);
    getReleaseNoteLookups();
  }, []);

  useEffect(() => {
    if (activeItem) getAllReleaseNotes();
  }, [activeItem]);

  return (
    <div className='support-view-wrapper'>
      <SupportHeader />
      <div className='release-note-view-wrapper'>
        <div className='side-panel'>
          <p className='side-panel-title'>Release Notes</p>
          <PermissionsComponent
            permissionsList={Object.values(ReleaseNotePermissions)}
            permissionsId={ReleaseNotePermissions.AddReleaseNote.permissionsId}
          >
            {(activeItem ||
              (productReleases && productReleases.length === 0)) && (
              <Button
                className='add-btn'
                variant='outlined'
                onClick={() => {
                  setActiveAction('create');
                  setActiveItem(null);
                }}
              >
                <span className='mdi mdi-plus' />
                <span> Add New</span>
              </Button>
            )}
          </PermissionsComponent>
          <div className='release-notes-container'>
            {productReleases &&
              productReleases.map((item) => (
                <div
                  className='release-note-item-wrapper'
                  key={item?.productReleaseId}
                >
                  {displayWithPermissions() && (
                    <div
                      className='popover-button'
                      onClick={(event) => handlePopoverOpen(event, item)}
                    >
                      <span className='mdi mdi-dots-vertical'></span>
                    </div>
                  )}

                  <div
                    className={`release-note-item 
                    ${
                      activeItem?.productReleaseId === item?.productReleaseId &&
                      'active-release-note'
                    }`}
                    onClick={() => {
                      if (selectedPopoverAction === null)
                        handleProductReleaseCardClick(item);
                    }}
                  >
                    <div className='item-header'>
                      <span>{item?.versionName}</span>
                    </div>
                    <div className='item-body'>
                      <span>{`version ${item?.versionNumber}`}</span>
                      <span>{moment(item?.releaseDate).format('ll')}</span>
                      {displayWithPermissions() && selectedPopoverAction && (
                        <XelePopover
                          idRef={`productRelease${item?.productReleaseId}Ref`}
                          attachedWith={selectedPopoverAction}
                          popoverClasses='release-note-popover'
                          handleClose={handlePopoverClose}
                          component={
                            <>
                              <PermissionsComponent
                                permissionsList={Object.values(
                                  ReleaseNotePermissions
                                )}
                                permissionsId={
                                  ReleaseNotePermissions.EditReleaseNote
                                    .permissionsId
                                }
                              >
                                <div
                                  className='popover-item'
                                  onClick={releaseEditClickHandler}
                                >
                                  <img src={editIcon} />
                                  <span className='option-name'>Edit</span>
                                </div>
                              </PermissionsComponent>

                              <PermissionsComponent
                                permissionsList={Object.values(
                                  ReleaseNotePermissions
                                )}
                                permissionsId={
                                  ReleaseNotePermissions.ExportReleaseNoteAsPDF
                                    .permissionsId
                                }
                              >
                                <div
                                  className='d-flex d-flex-h-between d-flex-v-center'
                                  onClick={PdfExportHandler}
                                >
                                  <div className='popover-item'>
                                    <span className='mdi mdi-file-pdf'></span>
                                    <span className='option-name'>
                                      Export as PDF
                                    </span>
                                  </div>
                                  {isPdfDownloadnig && (
                                    <img
                                      className='popover-loader m-2'
                                      src={loaderOrange}
                                      alt='loader'
                                    />
                                  )}
                                </div>
                              </PermissionsComponent>

                              <PermissionsComponent
                                permissionsList={Object.values(
                                  ReleaseNotePermissions
                                )}
                                permissionsId={
                                  ReleaseNotePermissions.ArchiveReleaseNote
                                    .permissionsId
                                }
                              >
                                <div
                                  className='popover-item'
                                  onClick={() => {
                                    setIsDeleteDialogOpen(true);
                                  }}
                                >
                                  <img src={archiveIcon} />
                                  <span className='option-name'>Archive</span>
                                </div>
                              </PermissionsComponent>
                            </>
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className='release-note-content'>
          {activeAction === 'view' && (
            <>
              <ReleaseDetailsComponent activeItem={activeItem} />
              {categorizedReleaseNotes &&
                Object.keys(categorizedReleaseNotes).map((item) => (
                  <AccordionComponent
                    data={categorizedReleaseNotes[item]}
                    accordionHeaderText={item}
                    key={item}
                  />
                ))}
            </>
          )}
          {activeAction === 'create' && (
            <ReleaseProductManagementForm
              reloadData={reloadData}
              getAllReleaseNotes={getAllReleaseNotes}
              releaseNoteLookups={releaseNoteLookups}
              activeItem={activeItem}
              setActiveItem={setActiveItem}
              activeItemReleaseNotes={activeReleaseNotes}
            />
          )}
          {activeAction === 'edit' && (
            <ReleaseProductManagementForm
              isEditForm
              reloadData={reloadData}
              getAllReleaseNotes={getAllReleaseNotes}
              releaseNoteLookups={releaseNoteLookups}
              activeItem={activeItem}
              activeItemReleaseNotes={activeReleaseNotes}
            />
          )}
        </div>

        {isDeleteDialogOpen && (
          <XeleDialog
            titleTextClasses={'release-confirm-text'}
            titleText=''
            saveText='confirm'
            saveType='button'
            maxWidth='sm'
            dialogContent={
              <div className='d-flex-column-center'>
                <span className='mdi mdi-close-octagon c-danger mdi-48px' />
                <span>
                  {' '}
                  {`Are You Sure You Want To Archive "${
                    activeItem?.versionName || ''
                  }"?`}
                </span>
              </div>
            }
            saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
            isOpen={isDeleteDialogOpen}
            onSaveClicked={releaseDeleteClickHandler}
            onCloseClicked={() => {
              setIsDeleteDialogOpen(false);
            }}
          />
        )}
      </div>
      <SupportFooter />
    </div>
  );
};
