import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

export const XelePhone = ({
  isValid,
  country = 'ae',
  onInputChanged,
  idRef,
  isRequired,
  isDisabled,
  value,
  wrapperClasses = '',
  inputClasses = 'inputs theme-form-builder',
  labelClasses = '',
  translationPath = '',
  parentTranslationPath = '',
  labelValue,
  buttonOptions,
  errorClasses = '',
  inputPlaceholder,
  onInputBlur,
  error,
  helperText,
  isSubmitted,
  isLoading,
  afterIconClasses = '',
  themeClass = 'theme-default',
  onKeyDown,
  onKeyUp,
}) => {
  const [isBlurOrChanged, setIsBlurOrChanged] = useState(false);
  const { t, i18n } = useTranslation(parentTranslationPath, {keyPrefix: translationPath});
  const CustomizationMasks = {
    ci: '(...) ...-...-..-....',
    mx: '(...) ...-...-..',
    ar: '(..) ...-....-..',
    jo: '(..) ...-....',
  };
  return (
    <div className={`phones-wrapper ${wrapperClasses} ${themeClass}`}>
      {labelValue && (
        <label
          htmlFor={idRef}
          className={`label-wrapper ${labelClasses}${
            isDisabled ? ' disabled' : ''
          }`}
        >
          {t(`${labelValue}`)}
        </label>
      )}
      <div className='phone-input-wrapper'>
        <PhoneInput
          specialLabel={false}
          isValid={isValid}
          country={country}
          masks={CustomizationMasks}
          excludeCountries={['JE', 'je']}
          enableLongNumbers
          inputProps={{
            required: isRequired,
            id: idRef || labelValue || inputClasses || labelClasses || '',
            autoComplete: 'new-password',
            onKeyDown,
            onKeyUp,
          }}
          containerClass={`${
            (helperText &&
              error &&
              (isSubmitted || isBlurOrChanged) &&
              ' phone-error') ||
            ''
          }`}
          disabled={isDisabled}
          value={value}
          inputClass={`phone-input ${inputClasses}`}
          onBlur={(event) => {
            setIsBlurOrChanged(true);
            if (onInputBlur) onInputBlur(event);
          }}
          onChange={(newValue, newCountry, event) => {
            setIsBlurOrChanged(true);
            if (newValue && !newValue.startsWith(newCountry.dialCode)) {
              if (onInputChanged)
                onInputChanged(
                  newCountry.dialCode + newValue,
                  newCountry,
                  event
                );
              return;
            }
            if (onInputChanged) onInputChanged(newValue, newCountry, event);
          }}
          enableTerritories
          enableSearch
          searchPlaceholder={t('shared:search')}
          localization={i18n.language}
          placeholder={
            (inputPlaceholder && t(`${inputPlaceholder}`)) ||
            undefined
          }
        />
        {isLoading && <span className='mdi mdi-refresh mdi-spin loader' />}
        {afterIconClasses && <span className={`mx-2 ${afterIconClasses}`} />}
        {buttonOptions && (
          <Button
            className={`ml-2-reversed ${buttonOptions.className}`}
            onClick={buttonOptions.onActionClicked}
            disabled={buttonOptions.isDisabled}
            required={buttonOptions.isRequired}
          >
            <span className={buttonOptions.iconClasses} />
          </Button>
        )}
      </div>
      {helperText && error && (isSubmitted || isBlurOrChanged) && (
        <div className={`error-wrapper ${errorClasses}`}>{helperText}</div>
      )}
    </div>
  );
};

XelePhone.propTypes = {
  value: PropTypes.oneOfType([PropTypes.any]),
  onInputChanged: PropTypes.func,
  isValid: PropTypes.func,
  idRef: PropTypes.string.isRequired,
  wrapperClasses: PropTypes.string,
  inputClasses: PropTypes.string,
  labelClasses: PropTypes.string,
  translationPath: PropTypes.string,
  parentTranslationPath: PropTypes.string,
  labelValue: PropTypes.string,
  country: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  errorClasses: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  onInputBlur: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  isSubmitted: PropTypes.bool,
  isLoading: PropTypes.bool,
  themeClass: PropTypes.oneOf(['theme-default']),
  afterIconClasses: PropTypes.string,
  buttonOptions: PropTypes.shape({
    className: PropTypes.string,
    iconClasses: PropTypes.string,
    onActionClicked: PropTypes.func,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
  }),
};
