import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { XeleInput } from '../../../../Controls';
import { TableFilterOperatorsComponent } from './TableFilterOperatorsComponent';
import { TableFilterOperatorsEnum, TableFilterTypesEnum } from '../../../../../Enums';
import { floatHandler } from '../../../../../Helper';
import { GlobalAdvanceSearchActions } from '../../../../../store/GlobalAdvanceSearch/GlobalAdvanceSearchActions';
import { useDispatch, useSelector } from 'react-redux';

export const TableFilterTextInputComponent = ({
  idRef,
  operators,
  filterKey,
  displayPath,
  searchableKey,
  filterValues,
  onFilterValuesChanged,
  defaultSelectedOperator,
  inputPlaceholder,
  textInputType,
  textInputMax,
  textInputMin,
  parentTranslationPath,
  translationPath,
  FilterDisabledButton,
  isClearFiltersClicked,
  setIsClearFiltersClicked,
  isNumerInput,
  localStorageKey , 

}) => {
  const dispatch = useDispatch();
  const advanceSearch = useSelector((state) => state.GlobalAdvanceSearchReducer);
  const [searchValue, setSearchValue] = useState(advanceSearch && advanceSearch[localStorageKey] || null);
  const [filterValue, setFilterValue] = useState(null);
  const [selectedOperator, setSelectedOperator] = useState(defaultSelectedOperator);
  const searchTimer = useRef(null);
  const [filterIcon, setFilterIcon] = useState(null);
  const getOperatorValue = useCallback(
    (key) =>
      (Object.values(TableFilterOperatorsEnum).findIndex((item) => item.key === key) !== -1 &&
        Object.values(TableFilterOperatorsEnum).find((item) => item.key === key)) ||
      '',
    []
  );

  const clearOperator = () => {
    const searchValueView = advanceSearch && advanceSearch[localStorageKey]
    for (const key in searchValueView) {
      const element = searchValueView[key];
      let elementKey = element.searchableKey || element.displayPath || element.input;
      let activeItemKey = searchableKey || displayPath;
      if (elementKey === activeItemKey) delete searchValueView[key];
      dispatch(
        GlobalAdvanceSearchActions.globalAdvanceSearchRequest({
          ...advanceSearch,
          [localStorageKey]: searchValueView && Object.keys(searchValueView).length ? searchValueView : null
        })
      );
    }
  }

  useEffect(
    () => () => {
      if (searchTimer.current) clearTimeout(searchTimer.current);
    }, []);

  useEffect(() => {
    const searchValueView = advanceSearch && advanceSearch[localStorageKey]
    const searchKey = searchValueView && Object.keys(searchValueView).find((e) => e === searchableKey || e === displayPath);

    if (!searchValueView) {
      setSearchValue(null)
      setFilterValue('');
    }

    const storedFilterValue = searchValueView && searchValueView[searchKey] && searchValueView[searchKey].value || null;
    if (storedFilterValue){ 
    setFilterValue(storedFilterValue);
    setSearchValue(searchValueView)
    }
    else setFilterValue('');

    const storedFilterOperator = searchValueView && searchValueView[searchKey] && searchValueView[searchKey].operator || null;
    setFilterIcon(getOperatorValue(storedFilterOperator).icon);
    if (storedFilterOperator) setSelectedOperator(storedFilterOperator);
    else setSelectedOperator(defaultSelectedOperator);

    let isBlank = storedFilterOperator === TableFilterOperatorsEnum.isBlank.key
      || storedFilterOperator === TableFilterOperatorsEnum.isNotBlank.key;
    if (isBlank) setFilterValue('');

  }, [advanceSearch]);

  useEffect(() => {
    if (searchValue) {
      for (const key in searchValue) {
        const element = searchValue[key];

        let isBlank = element.operator === TableFilterOperatorsEnum.isBlank.key
          || element.operator === TableFilterOperatorsEnum.isNotBlank.key;
        if (!element.value && !isBlank) delete searchValue[key]
      }
    }
    localStorageKey && dispatch(
      GlobalAdvanceSearchActions.globalAdvanceSearchRequest({
        ...advanceSearch,
        [localStorageKey]: searchValue && Object.keys(searchValue).length ? searchValue : null
      })
    );
    if (onFilterValuesChanged) onFilterValuesChanged(searchValue);
  }, [searchValue]);

  useEffect(() => {
    if (isClearFiltersClicked) {
      setFilterValue('')
      setIsClearFiltersClicked(false)
      setFilterIcon(null);
      setSearchValue('');
      setSelectedOperator(defaultSelectedOperator);
    }
  }, [isClearFiltersClicked]);

  useEffect(() => 
  {
     if(filterValues &&  filterValues[filterKey]  && (filterKey &&  filterValues)){
      setFilterValue(filterValues[filterKey] && filterValues[filterKey].value)
     }
    
    
  }, [filterValues]);

  return (
    <div className='table-filter-text-input-wrapper control-wrapper'>
      <XeleInput
        value={(filterKey && filterValue) || ''}
        idRef={idRef}
        onInputChanged={(e) => {
          let value = e.target.value ;
      
           if (!filterIcon && !isNumerInput) setFilterIcon('mdi mdi-contain');
          else  if (!filterIcon) setFilterIcon('mdi mdi-equal');

          if (textInputType === 'number') {
            value = floatHandler(value, 3);
            if (value > textInputMax) value = textInputMax;
          }

          let isBlank = selectedOperator === TableFilterOperatorsEnum.isBlank.key
            || selectedOperator === TableFilterOperatorsEnum.isNotBlank.key;

          if (isBlank && value) {
            setSelectedOperator(defaultSelectedOperator);
            setFilterIcon('mdi mdi-contain');
          }
          setFilterValue(value);
          if (onFilterValuesChanged) {
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              let searchValueView = advanceSearch && advanceSearch[localStorageKey];
              const localFilterValues = (filterValues && { ...filterValues }) || {};
              if (filterKey ) localFilterValues[filterKey] = {
                value,
                operator: isBlank && value ? defaultSelectedOperator : selectedOperator,
                displayPath,
                searchableKey
              };
              setSearchValue((item) => ({ ...item, ...searchValueView, ...localFilterValues }));
              onFilterValuesChanged(localFilterValues);
            }, 700);
          }
        }}
        type={textInputType}
        max={textInputMax}
        min={textInputMin}
        inputPlaceholder={inputPlaceholder}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
      <TableFilterOperatorsComponent
        operators={operators || TableFilterTypesEnum.textInput.defaultOperators}
        selectedOperator={selectedOperator}
        FilterDisabledButton={FilterDisabledButton}
        filterIcon={filterIcon}
        onSelectedOperatorChanged={(newValue) => () => {
          setFilterIcon(getOperatorValue(newValue).icon)
          setSelectedOperator(newValue);

          let isBlank = (newValue === TableFilterOperatorsEnum.isBlank.key)
            || (newValue === TableFilterOperatorsEnum.isNotBlank.key)

          if (onFilterValuesChanged && (filterValue || isBlank)) {
            const localFilterValues = (filterValues && { ...filterValues }) || {};
            if (filterKey) localFilterValues[filterKey] = {
              value: filterValue,
              operator: newValue,
              displayPath,
              searchableKey
            };
            if (isBlank) {
              if (filterKey) localFilterValues[filterKey].value = null;
              setFilterValue('');
            }
            setSearchValue((item) => ({ ...item, ...localFilterValues }));
            onFilterValuesChanged(localFilterValues);
          }
        }}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        clearOperator={clearOperator}
      />
    </div>
  );
};

TableFilterTextInputComponent.propTypes = {
  idRef: PropTypes.string.isRequired,
  FilterDisabledButton: PropTypes.bool,
  onFilterValuesChanged: PropTypes.func,
  operators: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOf(Object.values(TableFilterOperatorsEnum).map((item) => item.key)),
      isDisabled: PropTypes.bool,
    })
  ),
  filterKey: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number]).isRequired,
  filterValues: PropTypes.instanceOf(Object),
  displayPath: PropTypes.string,
  searchableKey: PropTypes.string,
  defaultSelectedOperator: PropTypes.oneOf(
    Object.values(TableFilterOperatorsEnum).map((item) => item.key)
  ),
  inputPlaceholder: PropTypes.string.isRequired,
  textInputType: PropTypes.oneOf(['number', 'string']),
  textInputMax: PropTypes.number,
  textInputMin: PropTypes.number,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  isNumerInput: PropTypes.bool
};
TableFilterTextInputComponent.defaultProps = {
  defaultSelectedOperator: TableFilterTypesEnum.textInput.defaultSelectedOperator,
  textInputType: undefined,
  textInputMax: undefined,
  textInputMin: undefined,
  displayPath: undefined,
  searchableKey: undefined,
  onFilterValuesChanged: undefined,
  operators: undefined,
  filterValues: undefined,
  FilterDisabledButton: undefined,
};
