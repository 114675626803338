import React, { useEffect, useReducer, useRef, useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import Joi from 'joi';
import moment from 'moment';
import { Button, ButtonBase } from '@material-ui/core';
import {
  getErrorByName,
  GetParams,
  showError,
  showSuccess,
  bottomBoxComponentUpdate,
  returnPropsByPermissions,
} from '../../../../../Helper';
import {
  getDownloadableLink,
  GlobalNavigate,
  GlobalTranslate,
} from '../../../../../Helper/Middleware.Helper';
import {
  GetUserTeamsInfo,
  GetAllTeamsByManagerId,
  GetAllBranches,
  GetApplicationUserById,
  GetInActiveUserRoles,
  EditOrganizationUserProfile,
  OrganizationUserSearch,
} from '../../../../../Services/userServices';
import {
  atLeastOneNumber,
  charactersLength,
  containSpecialCharacter,
  containUpper,
  phoneExpression,
} from '../../../../../Utils/Expressions';
import { StaticLookupsIds } from '../../../../../assets/json/StaticLookupsIds';
import {
  XeleAutocomplete,
  XeleInput,
  XelePhone,
  XeleDatePicker,
} from '../../../../../Components/Controls';
import {
  PermissionsComponent,
  Spinner,
  XeleSwitch,
  UploaderComponentCircular,
} from '../../../../../Components';
import { TeamDetails } from '../Team/TeamDetails';
import { TeamManager } from '../Team/TeamManager';
import { DefaultImagesEnum, UserStatusEnum } from '../../../../../Enums';
import { useDebouncedAction, useTitle } from '../../../../../Hooks';
import { AssignRoleDialog } from '../UserUtilties';
import { UserLoginPermissions } from '../../../../../Permissions';
import { ChangePasswordDialog } from './ChangePasswordDialog';
import { UserLoginDialog } from './UserLoginDialog';
import { UserLeaves } from './UserLeaves/UserLeaves';
import { emailExpression } from '../../../../../Utils/Expressions';
import { MultipleTypesDocumentsUploader } from '../../../../../SharedComponents';
import {
  GetLookupItemsByLookupTypeName,
  GetLookupTypeItems,
  lookupItemsGetId,
} from '../../../../../Services/LookupsServices';
import {
  CheckExistEmail,
  CheckExistPhone,
  CheckExistUserName,
  CreateOrganizationUser,
  GetAllRolesByUserId,
  GetApplicationUserDetailsByUserId,
  GetUserId,
} from '../../../../../Services/UsersServices';
const translationPath = 'UsersManagementView';

const EditUserView = () => {
  const parentTranslationPath = 'UsersView';
  const { t } = useTranslation(parentTranslationPath, {
    keyPrefix: translationPath,
  });
  useTitle(t(`edit-user`));
  const typingTimer = useRef(null);
  const URLUserId = GetParams('id');
  const searchTimer = useRef(null);
  const reducer = (select, action) => {
    if (action.id !== 'edit') return { ...select, [action.id]: action.value };
    if (action.id === 'edit') {
      return {
        ...action.value,
      };
    }
  };

  const initialState = {
    firstName: '',
    secondName: '',
    lastName: '',
    userName: '',
    email: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    profileImg: null,
    whatsAppNumber: '',
    nationality: null,
    dateOfBirth: null,
    dateOfJoining: null,
    jobTitleId: null,
    branchId: null,
    reportsToId: null,
    languages: [],
    targetMin: null,
    targetMax: null,
    aboutMe: '',
    totalYearsOfExperience: null,
    userAreaOfFocusSubCommunities: [],
    userDigitalCards: { files: [] },
    totalYearsOfExperienceInPSI: null,
  };

  const [state, setState] = useReducer(reducer, initialState);
  const [selected, setSelected] = useReducer(reducer, {
    reportsTo: null,
    branch: null,
    jobTitle: null,
    department: null,
    developerSpecialistFor: null,
    carAvailability: false,
    drivingLicenseAvailability: false,
  });
  const [data, setData] = useReducer(reducer, {
    users: [],
    branches: [],
    jobTitles: [],
    languages: [],
    countries: [],
    departments: [],
  });

  const [isExist, setIsExist] = useState({
    email: null,
    phoneNumber: null,
    username: null,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [appUserId, setAppUserId] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [userTeams, setUserTeams] = useState(null);
  const [managerRelatedTeams, setManagerRelatedTeams] = useState(null);
  const [userRoles, setUserRoles] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [isOpenUserLoginDialog, setIsOpenUserLoginDialog] = useState(false);
  const [isOpenRole, setIsOpenRole] = useState(false);
  const [showPasswords, setShowPasswords] = useState({
    new: false,
    confirm: false,
  });
  const schema = Joi.object({
    firstName: Joi.string()
      .regex(/^.*\S*.*$/)
      .trim()
      .required()
      .messages({
        'string.empty': t(`first-name-is-required`),
      }),
    lastName: Joi.string()
      .regex(/^.*\S*.*$/)
      .trim()
      .required()
      .messages({
        'string.empty': t(`last-name-is-required`),
      }),
    email: Joi.string()
      .required()
      .regex(emailExpression)
      .custom(
        (value, helpers) =>
          (isExist?.email && helpers.error('any.invalid')) || value,
        t(`email-is-already-exist`)
      )
      .messages({
        'any.invalid': t(`email-is-already-exist`),
        'string.empty': t(`email-is-required`),
        'string.pattern.base': t(`invalid-email`),
      }),
    phoneNumber: Joi.string()
      .required()
      .regex(phoneExpression)
      .custom(
        (value, helpers) =>
          (isExist?.phoneNumber && helpers.error('any.invalid')) || value,
        t(`mobile-number-is-already-exist`)
      )
      .messages({
        'any.invalid': t(`mobile-number-is-already-exist`),
        'string.empty': t(`mobile-number-is-required`),
        'string.pattern.base': t(`invalid-mobile-number`),
      }),
    userName: Joi.string()
      .required()
      .custom(
        (value, helpers) =>
          (isExist?.username && helpers.error('any.invalid')) || value,
        t(`username-is-already-exist`)
      )
      .messages({
        'any.invalid': t(`username-is-already-exist`),
        'string.empty': t(`username-is-required`),
        'string.pattern.base': t(`invalid-username`),
      }),
    // reportsToId: Joi.string()
    //   .required()
    //   .messages({
    //     'string.base': t(`field_is_required`, { field: 'Reports To' }),
    //     'string.empty': t(`field_is_required`, { field: 'Reports To' }),
    //   }),
    // targetMin:
    //   (state.targetMax &&
    //     Joi.number()
    //       .required()
    //       .messages({
    //         'number.base': t(`targetMin-is-required`),
    //         'number.empty': t(`targetMin-is-required`),
    //       })) ||
    //   Joi.any(),
    nationality: Joi.object()
      .required()
      .messages({
        // 'any.required':  (`nationality-is-required`),
        'object.base': t(`nationality-is-required`),
      }),
    password: Joi.string()
      .required()
      .regex(atLeastOneNumber)
      .regex(containSpecialCharacter)
      .regex(containUpper)
      .regex(charactersLength)
      .messages({
        'string.empty': t(`password-is-required`),
        'string.pattern.base': t(`weak-password`),
      }),
    confirmPassword: Joi.string()
      .required()
      .valid(Joi.ref('password'))
      .messages({
        'any.only': t(`password-not-matched`),
        'string.empty': t(`confirm-password-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);
  const manageRolesClicked = () => {
    setIsOpenRole(true);
  };
  const displayDataOnly = (permissionsId) => {
    if (URLUserId) return !returnPropsByPermissions(permissionsId);
    return false;
  };

  const getCountries = async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.Country,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: 'countries', value: res || [] });
    else setData({ id: 'countries', value: [] });
  };
  const getJobTitle = async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.JobTitle,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: 'jobTitles', value: res || [] });
    else setData({ id: 'jobTitles', value: [] });
  };

  const getLanguages = async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.Languages,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: 'languages', value: res || [] });
    else setData({ id: 'languages', value: [] });
  };
  const getAllUsers = async ({ name, userStatusId }) => {
    const res = await OrganizationUserSearch({
      userStatusId,
      name: name || '',
      pageIndex: 0,
      pageSize: 10,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'users', value: res?.result || [] });
    } else setData({ id: 'users', value: [] });
  };
  const getBranches = async (branchName) => {
    const res = await GetAllBranches({
      pageSize: 25,
      pageIndex: 1,
      branchName,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'branches', value: res.result || [] });
    } else setData({ id: 'branches', value: [] });
  };
  const getUserDetailsById = async () => {
    const res = await GetApplicationUserById(URLUserId);
    if (!(res && res.status && res.status !== 200)) setUserDetails(res);
  };
  const getAppUserId = async () => {
    const res = await GetUserId(URLUserId);
    if (!(res && res.status && res.status !== 200)) {
      localStorage.setItem('userid', JSON.stringify(res));
      setAppUserId(res);
    }
  };
  const getUserTeams = async () => {
    const res = await GetUserTeamsInfo(URLUserId);
    if (!(res && res.status && res.status !== 200)) {
      localStorage.setItem('userTeamsList', JSON.stringify(res));
      setUserTeams(res);
    }
  };
  const getAllTeamsByManagerId = async () => {
    const res = await GetAllTeamsByManagerId(URLUserId);
    if (!(res && res.status && res.status !== 200))
      setManagerRelatedTeams(res?.result || []);
  };
  const getActiveUserRoles = async () => {
    const pageSize = 30;
    const pageIndex = 1;
    const res = await GetAllRolesByUserId(URLUserId, pageIndex, pageSize);
    if (!(res && res.status && res.status !== 200))
      setUserRoles(res?.result || []);
  };

  const getInActiveUserRoles = async () => {
    const pageSize = 30;
    const pageIndex = 1;
    const res = await GetInActiveUserRoles({
      userId: URLUserId,
      pageIndex,
      pageSize,
    });
    if (!(res && res.status && res.status !== 200))
      setUserRoles(res?.result || []);
    else setUserRoles([]);
  };

  const getDepartments = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: 'Departments',
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: 'departments', value: res?.result || [] });
    else setData({ id: 'departments', value: [] });
  };

  const fillInitDetails = (data) => {
    setState({
      id: 'drivingLicenseAvailability',
      value: data?.drivingLicenseAvailability || false,
    });

    setState({ id: 'carAvailability', value: data?.carAvailability || false });
  };

  const getApplicationUserDetailsByUserId = async () => {
    const res = await GetApplicationUserDetailsByUserId(URLUserId);
    if (!(res && res.status && res.status !== 200)) {
      if (res) fillInitDetails(res);
    }
  };

  const getIsExistEmail = async (email) => {
    if (email && email.match(emailExpression)) {
      const response = await CheckExistEmail(email);
      setIsExist({
        ...isExist,
        email: response?.isExist || false,
      });
    }
  };
  const getIsExistPhoneNumber = useDebouncedAction(async (phone) => {
    phone = phone.replaceAll(' ', '');
    if (phone?.match(phoneExpression)) {
      const response = await CheckExistPhone('+' + phone);
      setIsExist({
        ...isExist,
        phoneNumber: response?.isExist || false,
      });
    }
  });
  const getIsExistUsername = useDebouncedAction(async (username) => {
    if (username) {
      const response = await CheckExistUserName(username);
      setIsExist({
        ...isExist,
        username: response?.isExist || false,
      });
    }
  });
  const saveHandler = async (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    if (schema.error) {
      showError(GlobalTranslate.t('Shared:please-fix-all-errors'));
      return;
    }
    setIsLoading(true);
    const languages = [];
    state.languages.map((e) => {
      languages.push({
        languageId: e.lookupItemId || e.lookupsId,
      });
    });
    const CardsDigitalUser =
      (state.userDigitalCards.files &&
        state?.userDigitalCards?.files.map((e) => {
          return {
            fileName: e.fileName,
            fileId: e.uuid,
          };
        })) ||
      [];
    const saveDto = {
      ...state,
      phoneNumber:
        (state.phoneNumber &&
          !state.phoneNumber.startsWith('+') &&
          `+${state.phoneNumber}`) ||
        state.phoneNumber ||
        '',
      whatsAppNumber:
        (state.whatsAppNumber &&
          !state.whatsAppNumber.startsWith('+') &&
          `+${state.whatsAppNumber}`) ||
        (state.whatsAppNumber &&
          state.whatsAppNumber.match(phoneExpression) &&
          state.whatsAppNumber) ||
        '',
      nationalityId: state.nationality.lookupItemId,
      reportsTo:
        (state.reportsToId &&
          selected.reportsTo &&
          selected.reportsTo.fullName) ||
        null,
      jobTitle:
        (state.jobTitleId &&
          selected.jobTitle &&
          selected.jobTitle.lookupItemName) ||
        null,
      userLanguages: languages || null,
      branch:
        (state.branchId && selected.branch && selected.branch.branchName) ||
        null,
      departmentId: selected.department?.lookupItemId || null,
      userDigitalCards: CardsDigitalUser || null,
    };
    const res = URLUserId
      ? await EditOrganizationUserProfile(URLUserId, saveDto)
      : await CreateOrganizationUser(saveDto);
    setIsLoading(false);
    if (res && !(res.status && res.status !== 200)) {
      showSuccess(t(`user-updated-successfully`));
      if (JSON.parse(localStorage.getItem('session')).userId === URLUserId) {
        const updatedState = JSON.parse(localStorage.getItem('session'));
        const update = { ...updatedState, profileImg: state.profileImg };
        localStorage.setItem('session', JSON.stringify(update));
      }
      GlobalNavigate('/home/Users');
    } else if (URLUserId) showError(t(`user-update-failed`));
  };

  useEffect(() => {
    if (userDetails) {
      const reportsTo =
        userDetails.reportsToId && userDetails.reportsTo
          ? {
              id: userDetails.reportsToId,
              fullName: userDetails.reportsTo,
            }
          : null;

      const branch =
        userDetails.branchId && userDetails.branch
          ? {
              branchId: userDetails.branchId,
              branchName: userDetails.branch,
            }
          : null;

      const jobTitle =
        userDetails.jobTitleId && userDetails.jobTitle
          ? {
              lookupItemId: userDetails.jobTitleId,
              lookupItemName: userDetails.jobTitle,
            }
          : null;

      const department =
        userDetails.departmentId && userDetails.department
          ? {
              lookupItemId: userDetails.departmentId,
              lookupItemName: userDetails.department,
            }
          : null;

      setSelected({
        id: 'edit',
        value: {
          reportsTo,
          branch,
          jobTitle,
          department,
        },
      });

      const nationality =
        userDetails.nationalityId && userDetails.nationalityName
          ? {
              lookupItemId: userDetails.nationalityId,
              lookupItemName: userDetails.nationalityName,
            }
          : null;

      setState({
        id: 'edit',
        value: {
          ...userDetails,
          nationality,
          password: '',
          confirmPassword: '',
          whatsAppNumber: userDetails.whatsAppNumber || '',
          phoneNumber: userDetails.phoneNumber || '',
          userDigitalCards: userDetails.userDigitalCards.map(
            (e) =>
              (e = {
                fileName: e.fileName,
                uuid: e.fileId,
              })
          ),
        },
      });
    }
  }, [userDetails]);

  useEffect(() => {
    getUserDetailsById();
    getAppUserId();
    getUserTeams();
    getAllTeamsByManagerId();
    getApplicationUserDetailsByUserId();
  }, []);

  useEffect(() => {
    if (userDetails) {
      if (userDetails.userStatus === 'Active') {
        getActiveUserRoles();
      } else {
        getInActiveUserRoles();
      }
    }
  }, [userDetails]);

  useEffect(
    () => () => typingTimer.current ? clearTimeout(typingTimer.current) : {},
    []
  );

  useEffect(() => {
    bottomBoxComponentUpdate(null);
  }, []);

  return (
    <div className='user-management-view view-wrapper'>
      <Spinner isActive={isLoading} />
      <form
        noValidate
        onSubmit={saveHandler}
        className='management-form-content'
      >
        <div className='view-management-header mb-2 px-2'>
          <PermissionsComponent
            permissionsList={Object.values(UserLoginPermissions)}
            permissionsId={
              UserLoginPermissions.AdminChangePassword.permissionsId
            }
          >
            {URLUserId && (
              <Button
                className='btns theme-solid mx-2 mb-2'
                onClick={() => setOpenChangePassword(true)}
              >
                <span className='mdi mdi-form-textbox-password   mx-1 ' />
                <span className=' mx-1 '> {t('change-password')} </span>
              </Button>
            )}
          </PermissionsComponent>

          <Button type='submit' className='btns theme-solid mx-2 mb-2'>
            <span className='mdi mdi-content-save-outline mx-1 ' />
            <span> {t(URLUserId ? `edit-user` : 'add-user')}</span>
          </Button>
          <Button
            className='btns theme-solid bg-cancel mb-2 mx-2'
            onClick={() => {
              GlobalNavigate('/home/Users');
              localStorage.removeItem('userTeamsList');
            }}
          >
            <span> {t(`cancel`)}</span>
          </Button>
        </div>
        <div className='body-content'>
          <div className='container'>
            <div className='information-section'>
              <div className='information-box-wrapper'>
                <div className='information-section-content'>
                  <div className='image-wrapper'>
                    <UploaderComponentCircular
                      idRef='profileImgRef'
                      circleDefaultImage={DefaultImagesEnum.man.defaultImg}
                      initUploadedFiles={
                        (state &&
                          state.profileImg && [
                            { uuid: state.profileImg, fileName: 'user-image' },
                          ]) ||
                        []
                      }
                      uploadedChanged={(files) =>
                        setState({
                          id: 'profileImg',
                          value: (files.length > 0 && files[0].uuid) || null,
                        })
                      }
                      isDisabled={displayDataOnly(
                        UserLoginPermissions.ChangeProfileImage.permissionsId
                      )}
                    />
                  </div>
                  {userDetails && (
                    <div className='information-content-wrapper'>
                      <div className='fullName'>
                        <div className='fullName-wrapper'>
                          <span className='fz-30px'>
                            {userDetails.fullName}
                          </span>
                        </div>
                        <div className='endPart'>
                          <div className='mb-2 userId'>
                            <Button
                              className='btns theme-solid btns theme-solid  mb-2 mx-2'
                              onClick={() => {
                                setIsOpenUserLoginDialog(true);
                              }}
                            >
                              <span> {t(`UserLogin`)}</span>
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className='mb-2'>
                        <span className='fw-bold'>
                          <span>{t(`Username`)}:</span>
                          <span className='px-2'>{userDetails.userName}</span>
                        </span>
                      </div>
                      <div className='roles-wrapper'>
                        <div className='roles-content'>
                          {userRoles &&
                            userRoles.map((item, index) => (
                              <span
                                key={`userRolesRef${index + 1}`}
                                className='px-1'
                              >
                                <span>
                                  {item.roles?.rolesName || item.rolesName}
                                </span>
                                {index < userRoles.length - 1 && <span>,</span>}
                              </span>
                            ))}
                        </div>
                        <PermissionsComponent
                          permissionsList={Object.values(UserLoginPermissions)}
                          permissionsId={
                            UserLoginPermissions.AddRole.permissionsId
                          }
                        >
                          <ButtonBase
                            className='btns theme-outline roles-button'
                            onClick={manageRolesClicked}
                            id='manage-ref'
                          >
                            <span>{t(`manage-roles`)}</span>
                            <span className='mdi mdi-menu-swap' />
                          </ButtonBase>
                        </PermissionsComponent>
                      </div>
                    </div>
                  )}
                </div>
                {userDetails && (
                  <div className='information-section-footer'>
                    <div className='separator-h' />
                    <div className='footer-content-wrapper'>
                      <div className='footer-section'>
                        <div className='section-item'>
                          <span className='mdi mdi-cellphone-android' />
                          <span className='px-2'>
                            {userDetails.phoneNumber || 'N/A'}
                          </span>
                        </div>
                        <div className='section-item'>
                          <span className='mdi mdi-email-outline' />
                          <span className='px-2'>
                            {userDetails.email || 'N/A'}
                          </span>
                        </div>
                        <div className='section-item'>
                          <span className='mdi mdi-whatsapp' />
                          <span className='px-2'>
                            {userDetails.whatsAppNumber || 'N/A'}
                          </span>
                        </div>
                      </div>
                      <div className='footer-section'>
                        <div className='section-item'>
                          <span className='mdi mdi-map-marker-outline' />
                          <span className='px-2'> {t(`nationality`)} :</span>
                          <span>{userDetails.nationalityName || 'N/A'}</span>
                        </div>
                        <div className='section-item'>
                          <span className='mdi mdi-calendar' />
                          <span className='px-2'> {t(`register`)}:</span>
                          <span>
                            {(userDetails.createdOn &&
                              moment(userDetails.createdOn)
                                .locale(i18next.language)
                                .format('DD, MMM YYYY')) ||
                              'N/A'}
                          </span>
                        </div>
                        <div className='section-item'>
                          <span className='mdi mdi-handshake' />
                          <span className='px-2'>{t(`data-source`)} :</span>
                          <span>{userDetails.dataSource || 'N/A'}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className='px-3'>
                  <div className='form-item'>
                    <XeleInput
                      inputPlaceholder='FirstName'
                      idRef='firstNameRef'
                      value={state.firstName || ''}
                      isWithError
                      isSubmitted={isSubmitted}
                      helperText={getErrorByName(schema, 'firstName').message}
                      error={getErrorByName(schema, 'firstName').error}
                      onInputChanged={(event) =>
                        setState({ id: 'firstName', value: event.target.value })
                      }
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      labelValue={`FirstName`}
                    />
                  </div>
                  <div className='form-item'>
                    <XeleInput
                      parentTranslationPath={parentTranslationPath}
                      inputPlaceholder='SecondName'
                      translationPath={translationPath}
                      labelValue={`SecondName`}
                      idRef='secondNameRef'
                      value={state.secondName || ''}
                      isWithError
                      isSubmitted={isSubmitted}
                      helperText={getErrorByName(schema, 'secondName').message}
                      error={getErrorByName(schema, 'secondName').error}
                      onInputChanged={(event) =>
                        setState({
                          id: 'secondName',
                          value: event.target.value,
                        })
                      }
                    />
                  </div>
                  <div className='form-item'>
                    <XeleInput
                      inputPlaceholder='LastName'
                      labelValue={t(`LastName`)}
                      idRef='lastNameRef'
                      value={state.lastName || ''}
                      isWithError
                      isSubmitted={isSubmitted}
                      helperText={getErrorByName(schema, 'lastName').message}
                      error={getErrorByName(schema, 'lastName').error}
                      onInputChanged={(event) =>
                        setState({ id: 'lastName', value: event.target.value })
                      }
                    />
                  </div>
                  <div className='form-item'>
                    <XeleAutocomplete
                      idRef='nationality2Ref'
                      labelValue={t(`nationality-req`)}
                      selectedValues={state.nationality}
                      inputPlaceholder='nationality'
                      filterOptions={(options) => options}
                      value={state.nationality}
                      data={data.countries || []}
                      chipsLabel={(option) =>
                        (option.lookupItemName && option.lookupItemName) || ''
                      }
                      displayLabel={(option) =>
                        (option.lookupItemName && option.lookupItemName) || ''
                      }
                      multiple={false}
                      withoutSearchButton
                      helperText={getErrorByName(schema, 'nationality').message}
                      error={getErrorByName(schema, 'nationality').error}
                      isWithError
                      isSubmitted={isSubmitted}
                      onOpen={() => {
                        if (data.countries && data.countries.length === 0)
                          getCountries();
                      }}
                      onChange={(event, newValue) => {
                        setState({ id: 'nationality', value: newValue });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {userTeams && userTeams.length > 0 && (
              <div className='team'>
                <div className='team-header'>{t(`teams`)}</div>
                <div className='team-scroll'>
                  <div className='team-section'>
                    <TeamDetails />
                  </div>
                </div>
              </div>
            )}
            {managerRelatedTeams && managerRelatedTeams.length > 0 && (
              <div className='team'>
                <div className='team-header'>
                  {t(`team-maneger`)}
                  {/* <span className='i-managerCrown manager-Crown' /> */}
                </div>

                <div className='team-scroll'>
                  <div className='team-section'>
                    <TeamManager
                      teamsRelatedToManager={managerRelatedTeams}
                      parentTranslationPath={parentTranslationPath}
                      translationPath='UsersManagementView'
                    />
                  </div>
                </div>
              </div>
            )}
            <UserLeaves
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
            />
          </div>

          <div className='account-dialog-section'>
            <div className='dialog-header'> {t(`profile-details`)}</div>
            <div className='dialog-body'>
              {!URLUserId && (
                <div className='form-item'>
                  <XeleInput
                    inputPlaceholder='userName'
                    idRef='userName'
                    value={state.userName || ''}
                    isWithError
                    isSubmitted={isSubmitted}
                    helperText={getErrorByName(schema, 'userName').message}
                    error={getErrorByName(schema, 'userName').error}
                    onInputChanged={(event) => {
                      setState({ id: 'userName', value: event.target.value });
                      getIsExistUsername(event.target.value);
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    labelValue={`userName`}
                  />
                </div>
              )}

              <div className='form-item'>
                <XeleInput
                  labelValue={t(`Email`)}
                  inputPlaceholder={t(`Email`)}
                  idRef='emailRef'
                  value={state.email || ''}
                  isWithError
                  isSubmitted={isSubmitted}
                  isDisabled={displayDataOnly(
                    UserLoginPermissions.EditEmailAddressInUserRecord
                      .permissionsId
                  )}
                  onKeyUp={(event) => {
                    const email = event?.target?.value;
                    if (searchTimer.current) clearTimeout(searchTimer.current);
                    searchTimer.current = setTimeout(() => {
                      getIsExistEmail(email);
                    }, 700);
                  }}
                  onInputChanged={(event) => {
                    const email = event?.target?.value;
                    setState({ id: 'email', value: email });
                  }}
                  helperText={getErrorByName(schema, 'email').message}
                  error={getErrorByName(schema, 'email').error}
                  withLoader
                  afterIconClasses={
                    (isExist?.email === true &&
                      'mdi mdi-close-outline c-danger mt-1') ||
                    (!getErrorByName(schema, 'email').error &&
                      'mdi mdi-check-outline c-success mt-1') ||
                    null
                  }
                />
              </div>
              <div className='form-item'>
                <XelePhone
                  country='ae'
                  idRef='MobileRef'
                  labelValue={t(`Mobile`)}
                  value={state.phoneNumber}
                  helperText={getErrorByName(schema, 'phoneNumber').message}
                  isDisabled={displayDataOnly(
                    UserLoginPermissions.EditMobileNumberInUserRecord
                      .permissionsId
                  )}
                  error={getErrorByName(schema, 'phoneNumber').error}
                  inputPlaceholder='whatsapp'
                  isSubmitted={isSubmitted}
                  onInputChanged={(value) => {
                    setState({ id: 'phoneNumber', value });
                    getIsExistPhoneNumber(value);
                  }}
                />
              </div>
              <div className='form-item'>
                <XelePhone
                  country='ae'
                  idRef='whatsappNumberRef'
                  labelValue={t(`whatsapp`)}
                  value={state.whatsAppNumber}
                  helperText={getErrorByName(schema, 'whatsAppNumber').message}
                  error={getErrorByName(schema, 'whatsAppNumber').error}
                  inputPlaceholder='whatsapp'
                  isSubmitted={isSubmitted}
                  onInputChanged={(value) =>
                    setState({ id: 'whatsAppNumber', value })
                  }
                />
              </div>
              {!URLUserId && (
                <>
                  <div className='form-item'>
                    <XeleInput
                      type={showPasswords.new ? 'text' : 'password'}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      labelValue={'InputsPassword'}
                      DisabledPaste
                      DisabledCopy
                      value={state.newPassword}
                      isWithError
                      helperText={getErrorByName(schema, 'password').message}
                      error={getErrorByName(schema, 'password').error}
                      onInputChanged={(e) =>
                        setState({ id: 'password', value: e.target.value })
                      }
                      endAdornment={
                        <ButtonBase
                          onClick={() =>
                            setShowPasswords((prev) => ({
                              ...prev,
                              new: !prev.new,
                            }))
                          }
                          className='p-2'
                        >
                          {!showPasswords.new ? (
                            <span className='mdi mdi-eye-outline' />
                          ) : (
                            <span className='mdi mdi-eye-off-outline' />
                          )}
                        </ButtonBase>
                      }
                    />
                  </div>
                  <div className='form-item'>
                    <XeleInput
                      type={showPasswords.confirm ? 'text' : 'password'}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      labelValue={'Inputsconfirm-password'}
                      DisabledPaste
                      DisabledCopy
                      value={state.confirmPassword}
                      isWithError
                      helperText={
                        getErrorByName(schema, 'confirmPassword').message
                      }
                      error={getErrorByName(schema, 'confirmPassword').error}
                      onInputChanged={(e) =>
                        setState({
                          id: 'confirmPassword',
                          value: e.target.value,
                        })
                      }
                      endAdornment={
                        <ButtonBase
                          onClick={() =>
                            setShowPasswords((prev) => ({
                              ...prev,
                              confirm: !prev.confirm,
                            }))
                          }
                          className='p-2'
                        >
                          {!showPasswords.confirm ? (
                            <span className='mdi mdi-eye-outline' />
                          ) : (
                            <span className='mdi mdi-eye-off-outline' />
                          )}
                        </ButtonBase>
                      }
                    />
                  </div>
                </>
              )}

              {/* <div className='form-item'>
                <XeleAutocomplete
                  idRef='branch2Ref'
                  labelValue={t(`branch-req`)}
                  selectedValues={selected.branch}
                  inputPlaceholder={t(`InputsBranch`)}
                  filterOptions={(options) => options}
                  data={data.branches || []}
                  chipsLabel={(option) =>
                    (option.branchName && option.branchName) || ''
                  }
                  displayLabel={(option) =>
                    (option.branchName && option.branchName) || ''
                  }
                  multiple={false}
                  withoutSearchButton
                  onInputKeyUp={(event) => {
                    const { value } = event.target;
                    if (typingTimer.current) clearTimeout(typingTimer.current);
                    typingTimer.current = setTimeout(() => {
                      getBranches(value);
                    }, 700);
                  }}
                  onOpen={() => {
                    if (data.branches && data.branches.length === 0)
                      getBranches();
                  }}
                  onChange={(event, newValue) => {
                    setState({
                      id: 'branchId',
                      value: (newValue && newValue.branchId) || null,
                    });
                    setSelected({ id: 'branch', value: newValue });
                  }}
                />
              </div> */}
              {/* <div className='form-item'>
                <XeleAutocomplete
                  idRef='jobTitle2Ref'
                  labelValue={t(`jobTitle-req`)}
                  inputPlaceholder={t(`InputsJobTitle`)}
                  selectedValues={selected.jobTitle}
                  filterOptions={(options) => options}
                  data={data.jobTitles || []}
                  chipsLabel={(option) =>
                    (option.lookupItemName && option.lookupItemName) || ''
                  }
                  displayLabel={(option) =>
                    (option.lookupItemName && option.lookupItemName) || ''
                  }
                  multiple={false}
                  withoutSearchButton
                  onOpen={() => {
                    if (data.jobTitles && data.jobTitles.length === 0)
                      getJobTitle();
                  }}
                  onChange={(event, newValue) => {
                    setState({
                      id: 'jobTitleId',
                      value: (newValue && newValue.lookupItemId) || null,
                    });
                    setSelected({ id: 'jobTitle', value: newValue });
                  }}
                />
              </div> */}
              <div className='form-item'>
                <XeleAutocomplete
                  idRef='languagesRef2'
                  labelValue={t(`languages`)}
                  inputPlaceholder={t(`languages`)}
                  selectedValues={state.languages}
                  filterOptions={(options) => options}
                  data={data.languages || []}
                  chipsLabel={(option) =>
                    (option.lookupItemName && option.lookupItemName) || ''
                  }
                  displayLabel={(option) =>
                    (option.lookupItemName && option.lookupItemName) || ''
                  }
                  multiple
                  onOpen={() => {
                    if (data.languages && data.languages.length === 0)
                      getLanguages();
                  }}
                  withoutSearchButton
                  onChange={(event, newValue) => {
                    setState({
                      id: 'languages',
                      value: newValue,
                    });
                  }}
                />
              </div>
              {/* <div className='form-item'>
                <XeleDatePicker
                  idRef='DateOfBirthRef'
                  labelValue='Date Of Birth'
                  placeholder='DD/MM/YYYY'
                  value={state.dateOfBirth || null}
                  onDateChanged={(newValue) => {
                    setState({
                      id: 'dateOfBirth',
                      value:
                        (newValue &&
                          moment(newValue).format('YYYY-MM-DDTHH:mm:ss')) ||
                        null,
                    });
                  }}
                />
              </div>
              <div className='form-item'>
                <XeleDatePicker
                  idRef='DateOfJoiningRef'
                  labelValue='Date of Joining'
                  placeholder='DD/MM/YYYY'
                  value={state.dateOfJoining || null}
                  onDateChanged={(newValue) => {
                    setState({
                      id: 'dateOfJoining',
                      value:
                        (newValue &&
                          moment(newValue).format('YYYY-MM-DDTHH:mm:ss')) ||
                        null,
                    });
                  }}
                />
              </div>
              <div className='form-item'>
                <XeleAutocomplete
                  idRef='RegistrationNo2Ref'
                  labelValue='Reports To'
                  inputPlaceholder='Reports To'
                  selectedValues={selected.reportsTo}
                  filterOptions={(options) => options}
                  value={state.reportsTo}
                  data={data.users || []}
                  chipsLabel={(option) =>
                    (option && option.fullName) ||
                    (option && option.firstName) ||
                    ''
                  }
                  displayLabel={(option) =>
                    (option && option.fullName) ||
                    (option && option.firstName) ||
                    ''
                  }
                  multiple={false}
                  withoutSearchButton
                  onOpen={() => {
                    if (data.users && data.users.length === 0)
                      getAllUsers({ userStatusId: UserStatusEnum.Active.key });
                  }}
                  onChange={(event, newValue) => {
                    setState({
                      id: 'reportsToId',
                      value: (newValue && newValue.id) || null,
                    });
                    setSelected({ id: 'reportsTo', value: newValue });
                  }}
                  onInputKeyUp={(e) => {
                    const { value } = e.target;
                    if (typingTimer.current) clearTimeout(typingTimer.current);
                    typingTimer.current = setTimeout(() => {
                      getAllUsers({
                        name: value,
                        userStatusId: UserStatusEnum.Active.key,
                      });
                    }, 700);
                  }}
                  isWithError
                  isSubmitted={isSubmitted}
                  error={getErrorByName(schema, 'reportsToId').error}
                  helperText={getErrorByName(schema, 'reportsToId').message}
                />
              </div>
              <div className='form-item'>
                <XeleAutocomplete
                  idRef='departmentRef'
                  labelValue={t(`department`)}
                  wrapperClasses='w-100 mb-2'
                  selectedValues={selected.department || []}
                  filterOptions={(options) => options}
                  data={data.departments || []}
                  chipsLabel={(option) =>
                    (option.lookupItemName && option.lookupItemName) || ''
                  }
                  displayLabel={(option) =>
                    (option.lookupItemName && option.lookupItemName) || ''
                  }
                  multiple={false}
                  withoutSearchButton
                  onOpen={() => {
                    if (data.departments && data.departments.length === 0)
                      getDepartments();
                  }}
                  onChange={(event, newValue) => {
                    setSelected({ id: 'department', value: newValue });
                  }}
                />
              </div>
              <XeleInput
                idRef='TotalYearOfExperienceRef2'
                // inputPlaceholder="TotalYearsOfCareerExperience"
                labelValue='TotalYearsOfCareerExperience'
                value={state.totalYearsOfExperience || ''}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                isWithError
                wrapperClasses={'mb-2'}
                isSubmitted={isSubmitted}
                type='number'
                withLoader
                max={900000000}
                onInputChanged={(event) =>
                  setState({
                    id: 'totalYearsOfExperience',
                    value: +event.target.value,
                  })
                }
              />
              <XeleInput
                idRef='TotalYearOfExperienceRef2'
                labelValue='TotalYearsOfExperienceInPSI'
                value={state.totalYearsOfExperienceInPSI}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                isWithError
                wrapperClasses={'mb-2'}
                isSubmitted={isSubmitted}
                type='number'
                withLoader
                isDisabled
                max={900000000}
                onInputChanged={(event) =>
                  setState({
                    id: 'totalYearsOfExperienceInPSI',
                    value: +event.target.value,
                  })
                }
              /> */}
              {/* <div className='form-item'>
                <p className='m-0'>{t(`hasCar`)}</p>
                <XeleSwitch
                  idRef='carAvailabilityRef'
                  themeClass='thick-theme'
                  labelInput='hasCar'
                  isChecked={state.carAvailability || false}
                  labelValue={`${state.carAvailability ? 'Yes' : 'No'}`}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChangeHandler={(_, isChecked) => {
                    setState({
                      id: 'carAvailability',
                      value: isChecked || false,
                    });
                  }}
                />
              </div>
              <div className='form-item'>
                <p className='m-0'>{t(`hasDrivingLicense`)}</p>
                <XeleSwitch
                  idRef='drivingLicenseRef'
                  themeClass='thick-theme'
                  isChecked={state.drivingLicenseAvailability || false}
                  labelValue={`${
                    state.drivingLicenseAvailability ? 'Yes' : 'No'
                  }`}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChangeHandler={(_, isChecked) => {
                    setState({
                      id: 'drivingLicenseAvailability',
                      value: isChecked || false,
                    });
                  }}
                />
              </div>
              <div className='form-item'>
                <XeleInput
                  idRef='aboutMeRef2'
                  labelValue='about-me'
                  value={state.aboutMe}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  isWithError
                  rows={4}
                  multiline
                  isSubmitted={isSubmitted}
                  wrapperClasses={'about-me mb-0'}
                  withLoader
                  onInputChanged={(event) =>
                    setState({ id: 'aboutMe', value: event.target.value })
                  }
                />
              </div>
              <div className='my-2'>
                <MultipleTypesDocumentsUploader
                  initUploadedFiles={state?.userDigitalCards || []}
                  labelValue='userDigitalCards'
                  uploadedChanged={(files) => {
                    setState({
                      id: 'userDigitalCards',
                      value: {
                        files,
                      },
                    });
                  }}
                  setIsUploading={setIsUploading}
                  isUploading={isUploading}
                  multiple
                  isOpenGallery
                  idRef='importFileRef'
                  viewUploadedFilesCount={false}
                  openGallery
                  isDocuments
                  chipHandler={(value) => () => {
                    const link = document.createElement('a');
                    link.setAttribute('download', value.fileName);
                    link.href = getDownloadableLink(value.uuid);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                  }}
                  isSubmitted={isSubmitted}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                />
              </div> */}
            </div>
          </div>
        </div>
      </form>
      {userDetails && isOpenRole && (
        <AssignRoleDialog
          isOpen={isOpenRole}
          isOpenChanged={() => {
            setIsOpenRole(false);
          }}
          reloadData={() => {
            if (userDetails.userStatus === 'Active') getActiveUserRoles();
            else getInActiveUserRoles();
          }}
          userStatus={userDetails.userStatus}
          userFullName={userDetails.fullName || ''}
          userId={URLUserId}
          parentTranslationPath={parentTranslationPath}
        />
      )}
      {openChangePassword && (
        <ChangePasswordDialog
          open={openChangePassword}
          close={() => {
            setOpenChangePassword(false);
          }}
          userId={URLUserId}
        />
      )}

      {isOpenUserLoginDialog && (
        <UserLoginDialog
          open={isOpenUserLoginDialog}
          parentTranslationPath={parentTranslationPath}
          close={() => {
            setIsOpenUserLoginDialog(false);
          }}
          userId={URLUserId}
        />
      )}
    </div>
  );
};

export default EditUserView;
