import React, { useEffect, useCallback, useState, Children } from 'react';
import { Button, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  ActionsEnum,
  LoadableImageEnum,
  UserStatusEnum,
} from '../../../../../../Enums';
import { XeleCheckbox, LoadableImage } from '../../../../../../Components';
import {
  getDownloadableLink,
  sideMenuIsOpenUpdate,
  sideMenuComponentUpdate,
  returnPropsByPermissions,
  returnPropsByPermissions2,
} from '../../../../../../Helper';
// import { useEventListener } from '../../../../../../Hooks';
import UserSlider from '../UserSlider/UserSlider';
import { UserLoginPermissions } from '../../../../../../Permissions';
import { getIsAllowedPermission } from '../../../../../../Helper/Permissions.Helper';
import { GetUserId } from '../../../../../../Services/UsersServices';

export function UserCardsComponent({
  data,
  // page,
  // rowsPerPage,
  // onLoadMore,
  // isLoading,
  enableUserSelect,
  onSelectUser,
  onDeselectUser,
  actionClicked,
}) {
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const translationPath = 'UsersManagementView';
  const { t } = useTranslation('UsersView', {
    keyPrefix: translationPath,
  });
  const [activeItem, setActiveItem] = useState(null);
  const getTableActionsByPermissions = () => {
    // eslint-disable-next-line prefer-const
    let list = [];
    if (
      returnPropsByPermissions2(
        UserLoginPermissions.EditButtonInUserRecord.permissionsId,
        UserLoginPermissions.DeleteButtonOnCard.permissionsId
      )
    ) {
      list.push(ActionsEnum.edit, ActionsEnum.delete);
      return list;
    }
    if (
      returnPropsByPermissions(
        UserLoginPermissions.EditButtonInUserRecord.permissionsId
      )
    ) {
      list.push(ActionsEnum.edit);
      return list;
    }
    if (
      returnPropsByPermissions(
        UserLoginPermissions.DeleteButtonOnCard.permissionsId
      )
    ) {
      list.push(ActionsEnum.delete);
      return list;
    }
    return list;
  };
  const getUserActions = (userStatus) => {
    // eslint-disable-next-line prefer-const
    let actionsList = [];
    // eslint-disable-next-line prefer-const
    const editPermission = getIsAllowedPermission(
      Object.values(UserLoginPermissions),
      loginResponse,
      UserLoginPermissions.EditButtonInUserRecord.permissionsId
    );
    const deletePermission = getIsAllowedPermission(
      Object.values(UserLoginPermissions),
      loginResponse,
      UserLoginPermissions.DeleteButtonOnCard.permissionsId
    );
    if (userStatus === 'Pending') {
      actionsList.push(ActionsEnum.close);
      if (deletePermission) actionsList.push(ActionsEnum.delete);
    } else if (userStatus === 'Canceled') {
      if (deletePermission) actionsList.push(ActionsEnum.delete);
    } else if (userStatus === 'Active') {
      if (editPermission) actionsList.push(ActionsEnum.edit);
      actionsList.push(ActionsEnum.close);
      if (deletePermission) actionsList.push(ActionsEnum.delete);
    }

    return actionsList || getTableActionsByPermissions;
  };


  const DriversSliderClicked = useCallback(async (item) => {
    const res = await GetUserId(item.id);
    item.userId = res;
    sideMenuComponentUpdate(<UserSlider item={item} />);
    sideMenuIsOpenUpdate(true);
  }, []);

  const onSelectedChangeHandler = (isSelected, user) => {
    if (isSelected) {
      onSelectUser(user);
    } else {
      onDeselectUser(user);
    }
  };

  useEffect(
    () => () => {
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );

  return (
    <div className='users-card-component-wrapper'>
      {data &&
        data.result.map((item, index) => (
          <div className='users-card-wrapper' key={`userCardRef${index + 1}`}>
            <div
              className={`cards-wrapper ${
                activeItem === index ? 'active' : ''
              } `}
              onClick={() => {
                DriversSliderClicked(item);
                setActiveItem(index);
              }}
            >
              <div className='cards-header'>
                <div>
                  <LoadableImage
                    type={LoadableImageEnum.div.key}
                    classes='user-cover-image'
                    alt={t(`user-image`)}
                    src={getDownloadableLink(item.profileImg)}
                  />
                </div>
                <div className='d-flex-column'>
                  <div className='item-wrapper px-2'>
                    <span className='item-header'>{item.fullName}</span>
                  </div>
                  <div className='item-wrapper'>
                    <span className='item-header px-2'>
                      {t(`Username`)}:
                    </span>
                    <span className='item-body'>{item.userName}</span>
                  </div>
                  <div className='item-userTypes'>
                    {item.userTypes && item.userTypes.length > 0 && (
                      <div className='item-wrapper'>
                        <span className='item-body c-secondary'>
                          {item.userTypes &&
                            item.userTypes.map((subItem, subIndex) => (
                              <span
                                key={`userRolesRef${
                                  (subIndex + 1) * (index + 1)
                                }`}
                              >
                                {subItem.rolesName}
                                {subIndex < item.userTypes.length - 1 && (
                                  <span className='pr-1'>,</span>
                                )}
                              </span>
                            ))}
                        </span>
                      </div>
                    )}
                  </div>
                  <div>
                    {item.policy && (
                      <div className='item-wrapper'>
                        <span className='item-body c-success'>
                          <span>{item?.policy?.policyName}</span>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className='user-status-container'>
                  <Tooltip
                    size='small'
                    placement='right-end'
                    title={item.userStatus}
                  >
                    <span className={`user-status-${item && item.userStatus}`}>
                      {item && item.userStatus === 'Canceled'
                        ? 'Deactivated'
                        : UserStatusEnum[item?.userStatus]?.label || 'N/A'}
                    </span>
                  </Tooltip>
                </div>
                {enableUserSelect && (
                  <XeleCheckbox
                    onSelectedCheckboxChanged={(e) => {
                      e.stopPropagation();
                      onSelectedChangeHandler(e.target.checked, item);
                    }}
                    onSelectedCheckboxClicked={(e) => e.stopPropagation()}
                  />
                )}
              </div>
              <div className='cards-body'>
                <div className='item-wrapper'>
                  <span className='item-header'>
                    <span className='mdi mdi-phone px-2' />
                    <span>{t(`Mobile`)} :</span>
                  </span>
                  <span className='item-body'>{item.phoneNumber}</span>
                </div>
                <div className='item-wrapper flex-nowrap'>
                  <div className='texts-truncate d-flex'>
                    <span className='item-header'>
                      <span className='mdi mdi-email-outline px-2' />
                      <span>{t(`Email`)} :</span>
                    </span>
                    <span
                      className='item-body texts-truncate d-inline-block'
                      title={item.email || 'N/A'}
                    >
                      {item.email || 'N/A'}
                    </span>
                  </div>
                </div>
                <div className='item-wrapper'>
                  <span className='item-header'>
                    <span className='mdi mdi-whatsapp px-2' />
                    <span> {t(`whatsapp`)} :</span>
                  </span>
                  <span className='item-body'>
                    {item.whatsAppNumber || 'N/A'}
                  </span>
                </div>
                <div className='item-wrapper'>
                  <span className='item-header'>
                    <span className='mdi mdi-map-marker px-2' />
                    <span> {t(`nationality`)} :</span>
                  </span>
                  <span className='item-body'>
                    {item.nationalityName || 'N/A'}
                  </span>
                </div>
                <div className='item-wrapper'>
                  <span className='item-header'>
                    <span className='mdi mdi-calendar-blank px-2' />
                    <span>{t(`register`)} :</span>
                  </span>
                  <span className='item-body'>
                    {(item.createdOn &&
                      moment(item.createdOn)
                        .locale(i18next.language)
                        .format('DD/MM/YYYY')) ||
                      'N/A'}
                  </span>
                </div>
                <div className='item-wrapper'>
                  <span className='item-header'>
                    <span className='mdi mdi-database px-2 icon' />

                    <span>{t(`data-source`)} :</span>
                  </span>
                  <span className='item-body'>{item.dataSource || 'N/A'}</span>
                </div>
                <div className='item-wrapper'>
                  <span className='item-header'>
                    <span className='mdi mdi-domain px-2 icon' />

                    <span>{t(`branch`)} :</span>
                  </span>
                  <span className='item-body'>{item.branch || 'N/A'}</span>
                </div>
                <div className='item-wrapper mb-3'>
                  <span className='item-header'>
                    <span className='mdi mdi-login-variant px-2 icon' />

                    <span>{t(`last-login`)} :</span>
                  </span>
                  <span className='item-body'>
                    {(item && moment(item.lastLogin).format('lll')) || 'N/A'}
                  </span>
                  <div className='item-wrapper mb-3'>
                    <span className='item-header'>
                      <span className='mdi mdi-id-card px-2 icon' />

                      <span>{t(`staffCode`)} :</span>
                    </span>
                    <span className='item-body'>
                      {(item && item.staffCode) || 'N/A'}
                    </span>
                  </div>
                </div>
              </div>
              <div className='item-wrapper actions'>
                {Children.toArray(
                  getUserActions(item.userStatus).map((action) => (
                    <Tooltip title={t(`${action.key}`)}>
                      <span>
                        <Button
                          className='btns theme-outline'
                          disabled={action.key === 'delete'}
                          onClick={actionClicked(action.key, item, index)}
                        >
                          <span className={action.icon} />
                        </Button>
                      </span>
                    </Tooltip>
                  ))
                )}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}
