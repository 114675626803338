/* eslint-disable max-len */
import i18next from 'i18next';
import moment from 'moment-timezone/moment-timezone-utils';
import { GlobalRerender } from './Middleware.Helper';
import { config } from '../config';
import { store } from '../store/RootStore';

import SharedEn from '../Layouts/Common/I18n/en.json';
import SharedAr from '../Layouts/Common/I18n/ar.json';
import SharedRu from '../Layouts/Common/I18n/ru.json';
import SharedCn from '../Layouts/Common/I18n/cn.json';

import SharedV2En from '../Layouts/Common/I18nV2/en.json';
import SharedV2Ar from '../Layouts/Common/I18nV2/ar.json';
import SharedV2Ru from '../Layouts/Common/I18nV2/ru.json';
import SharedV2Cn from '../Layouts/Common/I18nV2/cn.json';

import UsersViewEn from '../Views/Home/Administration/UsersView/I18n/en.json';
import UsersViewCn from '../Views/Home/Administration/UsersView/I18n/cn.json';
import UsersViewRu from '../Views/Home/Administration/UsersView/I18n/ru.json';
import UsersViewAr from '../Views/Home/Administration/UsersView/I18n/ar.json';

import ImportDetailsViewCn from '../Views/Home/Common/ImportDetailsView/I18n/cn.json';
import ImportDetailsViewRu from '../Views/Home/Common/ImportDetailsView/I18n/ru.json';
import ImportDetailsViewEn from '../Views/Home/Common/ImportDetailsView/I18n/en.json';
import ImportDetailsViewAr from '../Views/Home/Common/ImportDetailsView/I18n/ar.json';

// Start Reports Builder View
import ReportsBuildergRu from '../Views/Home/ReportsBuilderView/I18n/ru.json';
import ReportsBuildergCn from '../Views/Home/ReportsBuilderView/I18n/cn.json';
import ReportsBuildergEn from '../Views/Home/ReportsBuilderView/I18n/en.json';
import ReportsBuildergAr from '../Views/Home/ReportsBuilderView/I18n/ar.json';

import RolesManagementViewAr from '../Views/Home/Administration/RolesView/RolesManagement/I18n/ar.json';
import RolesManagementViewEn from '../Views/Home/Administration/RolesView/RolesManagement/I18n/en.json';
import RolesManagementViewCn from '../Views/Home/Administration/RolesView/RolesManagement/I18n/cn.json';
import RolesManagementViewRu from '../Views/Home/Administration/RolesView/RolesManagement/I18n/ru.json';

import RolesViewCn from '../Views/Home/Administration/RolesView/I18n/cn.json';
import RolesViewRu from '../Views/Home/Administration/RolesView/I18n/ru.json';
import RolesViewEn from '../Views/Home/Administration/RolesView/I18n/en.json';
import RolesViewAr from '../Views/Home/Administration/RolesView/I18n/ar.json';

import UserDataViewRu from '../Views/Home/Administration/UserDataView/I18n/ru.json';
import UserDataViewCn from '../Views/Home/Administration/UserDataView/I18n/cn.json';
import UserDataViewEn from '../Views/Home/Administration/UserDataView/I18n/en.json';
import UserDataViewAr from '../Views/Home/Administration/UserDataView/I18n/ar.json';

import DashboardRu from '../Views/Home/Dashboard/I18n/ru.json';
import DashboardCn from '../Views/Home/Dashboard/I18n/cn.json';
import DashboardEn from '../Views/Home/Dashboard/I18n/en.json';
import DashboardAr from '../Views/Home/Dashboard/I18n/ar.json';

// Start Account Views
import LoginViewRu from '../Views/Account/LoginView/I18n/ru.json';
import LoginViewCn from '../Views/Account/LoginView/I18n/cn.json';
import LoginViewEn from '../Views/Account/LoginView/I18n/en.json';
import LoginViewAr from '../Views/Account/LoginView/I18n/ar.json';
// End Account Views

import FormBuilderRu from '../Views/Home/FormBuilder/I18n/ru.json';
import FormBuilderCn from '../Views/Home/FormBuilder/I18n/cn.json';
import FormBuilderEn from '../Views/Home/FormBuilder/I18n/en.json';
import FormBuilderAr from '../Views/Home/FormBuilder/I18n/ar.json';

// Start Form Builder Views
import FormBuilderViewCn from '../Views/FormBuilder/I18n/cn.json';
import FormBuilderViewRu from '../Views/FormBuilder/I18n/ru.json';
import FormBuilderViewEn from '../Views/FormBuilder/I18n/en.json';
import FormBuilderViewAr from '../Views/FormBuilder/I18n/ar.json';
// End Form Builder Views

import TeamViewCn from '../Views/Home/Administration/TeamView/I18n/cn.json';
import TeamViewRu from '../Views/Home/Administration/TeamView/I18n/ru.json';
import TeamViewEn from '../Views/Home/Administration/TeamView/I18n/en.json';
import TeamViewAr from '../Views/Home/Administration/TeamView/I18n/ar.json';

import LookupsViewEn from '../Views/Home/LookupsView/I18n/en.json';
import LookupsViewAr from '../Views/Home/LookupsView/I18n/ar.json';
import LookupsViewRu from '../Views/Home/LookupsView/I18n/ru.json';
import LookupsViewCn from '../Views/Home/LookupsView/I18n/cn.json';

import BranchViewEn from '../Views/Home/Administration/BranchesView/I18n/en.json';
import BranchViewRu from '../Views/Home/Administration/BranchesView/I18n/ru.json';
import BranchViewCn from '../Views/Home/Administration/BranchesView/I18n/cn.json';
import BranchViewAr from '../Views/Home/Administration/BranchesView/I18n/ar.json';

import HeaderViewCn from '../Views/Home/Common/HeaderView/I18n/cn.json';
import HeaderViewRu from '../Views/Home/Common/HeaderView/I18n/ru.json';
import HeaderViewEn from '../Views/Home/Common/HeaderView/I18n/en.json';
import HeaderViewAr from '../Views/Home/Common/HeaderView/I18n/ar.json';

// Start User Management View
import UsersManagementAr from '../Views/Settings/UserManagement/i18n/ar.json';
import UsersManagementEn from '../Views/Settings/UserManagement/i18n/en.json';
import UsersManagementRu from '../Views/Settings/UserManagement/i18n/ru.json';
import UsersManagementCn from '../Views/Settings/UserManagement/i18n/cn.json';
// End User Management View

import TalentViewAr from '../Views/Home/TalentPoolView/I18n/ar.json';
import TalentViewEn from '../Views/Home/TalentPoolView/I18n/en.json';
import TalentViewRu from '../Views/Home/TalentPoolView/I18n/ru.json';
import TalentViewCn from '../Views/Home/TalentPoolView/I18n/cn.json';

import RotationViewAr from '../Views/Home/RotationSchemaView/I18n/ar.json';
import RotationViewEn from '../Views/Home/RotationSchemaView/I18n/en.json';

import GlobalSuccessDialog from '../Views/Home/Common/GlobalSuccessDialog/GlobalSuccessDialog.i18n.json';

export function localizationInit() {
  i18next.init({
    interpolation: { escapeValue: false }, // React already does escaping
    fallbackLng: ['en', 'ar', 'ru', 'cn'],
    lng: 'en', // language to use
    resources: {
      en: {
        Shared: SharedEn,
        SharedV2: SharedV2En,
        TeamView: TeamViewEn,
        UserDataView: UserDataViewEn,
        UsersView: UsersViewEn,
        BranchView: BranchViewEn,
        RolesView: RolesViewEn,
        HeaderView: HeaderViewEn,
        ImportDetailsView: ImportDetailsViewEn,
        FormBuilder: FormBuilderEn,
        LookupsView: LookupsViewEn,
        LoginView: LoginViewEn,
        FormBuilderView: FormBuilderViewEn,
        RolesManagementView: RolesManagementViewEn,
        Dashboard: DashboardEn,
        ReportsBuilderg: ReportsBuildergEn,
        GlobalSuccessDialog: GlobalSuccessDialog.en,
        UsersManagement: UsersManagementEn,
        TalentView: TalentViewEn,
        RotationView: RotationViewEn,
      },
      ar: {
        Shared: SharedAr,
        SharedV2: SharedV2Ar,
        TeamView: TeamViewAr,
        UserDataView: UserDataViewAr,
        UsersView: UsersViewAr,
        BranchView: BranchViewAr,
        RolesView: RolesViewAr,
        HeaderView: HeaderViewAr,
        ImportDetailsView: ImportDetailsViewAr,
        FormBuilder: FormBuilderAr,
        LookupsView: LookupsViewAr,
        LoginView: LoginViewAr,
        FormBuilderView: FormBuilderViewAr,
        RolesManagementView: RolesManagementViewAr,
        Dashboard: DashboardAr,
        ReportsBuilderg: ReportsBuildergAr,
        GlobalSuccessDialog: GlobalSuccessDialog.ar,
        UsersManagement: UsersManagementAr,
        TalentView: TalentViewAr,
        RotationView: RotationViewAr,
      },
      ru: {
        Shared: SharedRu,
        SharedV2: SharedV2Ru,
        TeamView: TeamViewRu,
        UserDataView: UserDataViewRu,
        UsersView: UsersViewRu,
        BranchView: BranchViewRu,
        RolesView: RolesViewRu,
        HeaderView: HeaderViewRu,
        ImportDetailsView: ImportDetailsViewRu,
        FormBuilder: FormBuilderRu,
        LookupsView: LookupsViewRu,
        LoginView: LoginViewRu,
        FormBuilderView: FormBuilderViewRu,
        RolesManagementView: RolesManagementViewRu,
        Dashboard: DashboardRu,
        ReportsBuilderg: ReportsBuildergRu,
        UsersManagement: UsersManagementRu,
        GlobalSuccessDialog: GlobalSuccessDialog.ru,
        TalentView: TalentViewRu,
      },
      cn: {
        Shared: SharedCn,
        SharedV2: SharedV2Cn,
        TeamView: TeamViewCn,
        UserDataView: UserDataViewCn,
        UsersView: UsersViewCn,
        BranchView: BranchViewCn,
        RolesView: RolesViewCn,
        HeaderView: HeaderViewCn,
        ImportDetailsView: ImportDetailsViewCn,
        FormBuilder: FormBuilderCn,
        LookupsView: LookupsViewCn,
        LoginView: LoginViewCn,
        FormBuilderView: FormBuilderViewCn,
        RolesManagementView: RolesManagementViewCn,
        Dashboard: DashboardCn,
        ReportsBuilderg: ReportsBuildergCn,
        GlobalSuccessDialog: GlobalSuccessDialog.cn,
        UsersManagement: UsersManagementCn,
        TalentView: TalentViewCn,
      },
    },
  });
  if (localStorage.getItem('localization')) {
    moment.tz.setDefault(config.timeZone);
    i18next.changeLanguage(
      JSON.parse(localStorage.getItem('localization')).currentLanguage
    );
    const isRtl =
      JSON.parse(localStorage.getItem('localization')).currentLanguage === 'ar';
    if (isRtl) {
      const direction =
        JSON.parse(localStorage.getItem('localization')).currentLanguage ===
        'ar'
          ? 'rtl'
          : '';
      document.body.setAttribute('class', direction);
      document.body.setAttribute('dir', direction);
      document.documentElement.lang = JSON.parse(
        localStorage.getItem('localization')
      ).currentLanguage;
    }
  } else {
    localStorage.setItem(
      'localization',
      JSON.stringify({ currentLanguage: 'en', isRtl: false })
    );
    i18next.changeLanguage('en');
  }
}

export const languageChange = (currentLanguage, isDarkMode) => {
  const { dispatch } = store;
  const isRtl = currentLanguage === 'ar';
  const direction = currentLanguage === 'ar' ? 'rtl' : '';
  localStorage.setItem(
    'localization',
    JSON.stringify({ currentLanguage, isRtl })
  );
  document.body.setAttribute('class', direction);
  document.body.setAttribute('dir', direction);
  const themeDirection = direction ? 'rtl' : 'ltr';
  dispatch({ type: 'TOGGLE_THEME_DIRECTION', payload: themeDirection });
  document.documentElement.lang = currentLanguage;
  i18next.changeLanguage(currentLanguage);

  if (typeof isDarkMode === 'boolean') {
    document.body.classList.add('new-layout');
    if (isDarkMode) {
      document.body.classList.add('new-layout__dark-mode');
    }
  }

  GlobalRerender();
};

localizationInit();
