import React, { useCallback, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './MessageConfirmDialog.scss';
import { useTranslation } from 'react-i18next';
import { DialogActions, DialogContent, ButtonBase } from '@material-ui/core';
import { XeleDialog } from '../../Components';

export const XeleConfirmDialog = ({
  isDialogOpen,
  wrapperClasses = '',
  theme = 'alert',
  cancelButton = {
    label: 'cancel',
    handler: () => {},
  },
  confirmButton = {
    label: 'confirm',
    handler: () => {},
  },
  content = {
    body: '',
  },
  parentTranslationPath = '',
  translationPath = '',
}) => {
  const { t } = useTranslation(parentTranslationPath, {
    keyPrefix: translationPath,
  });

  return (
    <XeleDialog
      isOpen={isDialogOpen}
      maxWidth='sm'
      wrapperClasses={`message-confirm-dialog ${wrapperClasses}`}
      dialogTitle={<></>}
      dialogContent={
        <div className='dialog-body propx-view d-flex-column gap-32 p-3P5'>
          <DialogContent>
            <div className='dialog-content gap-16'>
              <div className={`dialog-icon ${theme}`}></div>
              <div>
                <div className='d-flex-v-center-h-between'>
                  <div className='fw-simi-bold fz-18 mb-2'>
                    {t(`${content.heading}`)}
                  </div>
                  <span
                    className='icons b-icon i-24 i-x-close-dialog pointer'
                    onClick={cancelButton.handler}
                  ></span>
                </div>
                {t(`${content.body}`)}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className='d-flex-center fj-end'>
              <ButtonBase
                onClick={cancelButton.handler}
                className='btns theme-propx outlined m-0 mr-2'
              >
                {t(`${cancelButton.label}`)}
              </ButtonBase>

              <ButtonBase
                className={`btns theme-propx solid m-0 ml-1 ${theme}`}
                onClick={confirmButton.handler}
              >
                {t(`${confirmButton.label}`)}
              </ButtonBase>
            </div>
          </DialogActions>
        </div>
      }
    />
  );
};

XeleConfirmDialog.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  theme: PropTypes.oneOf(['alert', 'warning']),
  content: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  }),
  confirmButton: PropTypes.shape({
    label: PropTypes.string,
    handler: PropTypes.func.isRequired,
  }),
  cancelButton: PropTypes.shape({
    label: PropTypes.string,
    handler: PropTypes.func.isRequired,
  }),
  wrapperClasses: PropTypes.string,
  parentTranslationPath: PropTypes.string,
  translationPath: PropTypes.string,
};
