import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {
  IconButton,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Tooltip,
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { XeleDialog, XeleInput } from '../../../../../Components';
import './AssignTeamsView.Style.scss';
import {
  getDownloadableLink,
  showError,
  showSuccess,
} from '../../../../../Helper';
import image from '../../../../../assets/images/defaults/notfound.gif';
import {
  DeleteTeamUserServices,
  PostTeamUserServices,
  SetUserAsTeamLead,
} from '../../../../../Services/Team';
import { OrganizationUserSearch } from '../../../../../Services/userServices';

export const AssignTeamsView = ({
  open,
  close,
  ClearData,
  reloadData,
  teamId,
  Teamname,
  UserTeam,
}) => {
  const translationPath = 'UserDialog';
  const { t } = useTranslation('TeamView', {
    keyPrefix: translationPath,
  });
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [teamUsersId, setteamUsersId] = React.useState();
  const [searchedItem, setSearchedItem] = useState('');
  const [usersResponse, setUsersResponse] = React.useState();
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 25,
    name: null,
  });
  const [timer, setTimer] = useState(null);
  const [loading, setLoading] = React.useState(false);
  const handleToggleleft = (value) => () => {
    setteamUsersId(value);
    setSelectedIndex(value);
  };

  const handleToggle = (value) => () => {
    setteamUsersId(value);
    setSelectedIndex(value);
  };

  const usersSearchResponse = async (Datauser) => {
    setLoading(true);
    const SearchResult = await OrganizationUserSearch({
      userStatusId: 2,
      pageIndex: filter.pageIndex,
      pageSize: filter.pageSize,
      name: filter.name || '',
    });

    if (
      SearchResult &&
      SearchResult.result &&
      SearchResult.result.length > 0 &&
      Datauser
    ) {
      const result = SearchResult.result.filter(
        (f) =>
          Datauser.result &&
          Datauser.result.find((e) => e.usersId === f.id) === undefined
      );
      setUsersResponse(result);
    } else setUsersResponse([]);
    setLoading(false);
  };

  useEffect(() => {
    if (Object.keys(UserTeam).length !== 0) usersSearchResponse(UserTeam);
  }, [UserTeam, teamId]);

  useEffect(() => {
    if (filter.name !== null) usersSearchResponse(UserTeam);
  }, [filter.name]);

  const PostTeamUserServicesAPI = async (value) => {
    const result = await PostTeamUserServices({
      teamsId: +teamId,
      usersId: value,
    });
    if (result) {
      setSelectedIndex(null);
      showSuccess(t('UserDialog.NotificationADDTeamUser'));
      // handleSearchLeft(searchedItem, UserTeam);
      reloadData();
    } else showError(t('UserDialog.NotificationAddTeamUserFiled'));
  };

  return (
    <XeleDialog
      titleText={t('AddTeamDialog.AssignTeamsView')}
      saveText={`${t('confirm')}`}
      saveType='button'
      SmothMove
      disableBackdropClick
      maxWidth='md'
      dialogContent={
        <div className='d-flex-column-center'>
          <div className='AssignTeamsView-wrapper'>
            <div>
              <div>
                <XeleInput
                  value={searchedItem}
                  idRef='Search-in-Users-team'
                  withLoader
                  label={t(`Search`)}
                  inputPlaceholder={t(
                    `Type-here-to-Search-in-Users`
                  )}
                  isLoading={loading}
                  beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                  onInputChanged={(e) => setSearchedItem(e.target.value)}
                  onKeyUp={() => {
                    setTimer(
                      setTimeout(() => {
                        setFilter((item) => ({ ...item, name: searchedItem }));
                      }, 700)
                    );
                  }}
                  onKeyDown={() => {
                    if (timer != null) clearTimeout(timer);
                  }}
                />
                <div className='list-users-left-wrapper'>
                  <List
                    className='list-users'
                    dense
                    component='div'
                    role='list'
                  >
                    {usersResponse &&
                      // eslint-disable-next-line react/destructuring-assignment
                      usersResponse
                        .filter((member) => member.userStatus === 'Active')
                        .map((value, i) => (
                          <div index={i}>
                            {value.fullName && (
                              <ListItem
                                role='listitem'
                                button
                                selected={selectedIndex === value.id}
                              >
                                <ListItemIcon>
                                  <Avatar
                                    alt={value.fullName}
                                    src={getDownloadableLink(value.profileImg)}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  onClick={handleToggleleft(value.id)}
                                  id={value.id}
                                  primary={value.fullName}
                                />
                                <Tooltip
                                  size='small'
                                  title={t(`add-to-team`)}
                                >
                                  <IconButton
                                    aria-label='comments'
                                    label='Add team leader'
                                    onClick={() =>
                                      PostTeamUserServicesAPI(value.id)
                                    }
                                  >
                                    <GroupAddIcon />
                                  </IconButton>
                                </Tooltip>
                              </ListItem>
                            )}
                          </div>
                        ))}
                    {(Array.isArray(usersResponse) && usersResponse.length) ===
                      0 && (
                      <div className='foundlogodiv'>
                        {' '}
                        <img
                          src={image}
                          className='notfoundlogo'
                          alt={t(`notfound-logo`)}
                        />
                        <div> {t(`NO-Data-Found`)} </div>
                      </div>
                    )}
                  </List>
                </div>
              </div>
              <div>
                <div className='Teamname' />
                <div className='my-team'>
                  <header className='title-wrp type2'>
                    <h1 className='title'>
                      <span className='tx'>
                        {`${Teamname}${' '}${t('UserDialog.Team')}` || ''}
                      </span>
                    </h1>
                  </header>
                </div>
                <div className='list-users-right-wrapper'>
                  <List
                    className='list-users-right'
                    dense
                    component='div'
                    role='list'
                  >
                    {UserTeam &&
                      UserTeam.result &&
                      UserTeam.result.map((value, i) => (
                        <ListItem
                          index={i}
                          role='listitem'
                          button
                          onClick={handleToggle(value)}
                        >
                          <ListItemIcon>
                            <Avatar
                              alt={value && value.users && value.users.fullName}
                              src={getDownloadableLink(
                                value && value.users && value.users.profileImg
                              )}
                              className='imgUserDialog'
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={value && value.usersId}
                            primary={
                              value && value.users && value.users.fullName
                            }
                          />
                          <Tooltip
                            size='small'
                            title={t(`add-team-leader`)}
                          >
                            <IconButton
                              aria-label='comments'
                              label='Add team leader'
                              onClick={async () => {
                                const result = await SetUserAsTeamLead({
                                  teamId: +value.teamsId,
                                  userId: value.usersId,
                                });
                                if (result) {
                                  showSuccess(t('UserDialog.addTeamLead'));
                                  reloadData();
                                }
                              }}
                            >
                              <StarIcon
                                className={value.isTeamLead ? 'star-color' : ''}
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            size='small'
                            title={t(`Delete-from-team`)}
                          >
                            <IconButton
                              aria-label='comments'
                              className='DeleteForeverIconUserDialog'
                              label={t('UserDialog.titleDelete')}
                              onClick={async () => {
                                const res = await DeleteTeamUserServices(
                                  value.teamUsersId
                                );
                                if (!(res && res.status && res.status !== 200))
                                  showSuccess(
                                    t('UserDialog.NotificationDeleteTeamUser')
                                  );
                                reloadData();
                              }}
                            >
                              <DeleteForeverIcon className='DeleteForeverIconUserDialog' />
                            </IconButton>
                          </Tooltip>
                        </ListItem>
                      ))}
                  </List>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
      isOpen={open}
      onSaveClicked={() => {
        setSearchedItem('');
        close();
        ClearData();
        reloadData();
        setSelectedIndex(null);
      }}
      onCloseClicked={close}
      onCancelClicked={close}
    />
  );
};
// AssignTeamsView.propTypes = {
//   open: PropTypes.bool.isRequired,
//   close: PropTypes.func.isRequired,
//   onSave: PropTypes.func.isRequired,

// };
//  ClearData, reloadData, teamId, Teamname, UserTeam
