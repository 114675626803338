import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClipboardHelper } from './ClipboardHelper';
import { showInfo } from '../../../../Helper';

export const useCopy = () => {
  const { t } = useTranslation('Shared');
  const [CopiedTheValue, setCopiedTheValue] = useState(false);
  const CopyFunctionBehavior = async (Message, MessageTitle, CopyDoneShowTime, hideToastify) => {
    if (await ClipboardHelper(Message)) {
      if (!hideToastify)
        showInfo(`${MessageTitle || t('Shared:Copy-id-successfully')}   ( ${Message} )`);
      setCopiedTheValue(true);
      setTimeout(() => {
        setCopiedTheValue(false);
      }, (CopyDoneShowTime));
    }
  };

  return { CopiedTheValue, CopyFunctionBehavior };
};
// Refactoring Done 2023
