import { useRef } from 'react';

export const useDebouncedAction = (
  action,
  disableFirstFetch = false,
  delay = 700
) => {
  const timerRef = useRef();
  const firstFetch = useRef(true);
  const debouncedAction = (payload) => {
    if (firstFetch.current && disableFirstFetch) {
      firstFetch.current = false;
      return;
    }
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => action(payload), delay);
  };
  return debouncedAction;
};
