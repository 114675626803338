import React from 'react';
import errorAnimation from '../../assets/images/icons/error-fix.gif';
export function ErrorBoundaryFallback() {
  return (
    <div className='error-fallback-ui-wrapper'>
      <div className='fallback-container'>
        <img src={errorAnimation} alt='' />
        <h1>Ops! Something Went Wrong .</h1>
        <p>
          There's an issue and the page could not be loaded . Please try
          navigating to home page and feel free to report the problem .
        </p>

        <button
          className='mt-5'
          onClick={() => {
            window.location.pathname = '/home';
          }}
        >
          <span className='mdi mdi-reload mr-2'></span>
          <span>Go Back Home</span>
        </button>
      </div>
    </div>
  );
}
