import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ButtonBase } from '@material-ui/core';
import {
  XeleDatePicker,
  XeleDialog,
  Spinner,
} from '../../../../../../Components';
import moment from 'moment';
import {
  GetParams,
  showError,
  showSuccess,
  getErrorByName,
} from '../../../../../../Helper';
import Joi from 'joi';
import {
  CreateUserLeave,
  UpdateUserLeave,
} from '../../../../../../Services/UsersServices';

export const AddLeavesDialog = ({
  setIsOpenAddLeaves,
  isOpenAddLeaves,
  parentTranslationPath,
  translationPath,
  activeItem,
  getAllUserLeaves,
  setActiveItem,
}) => {
  const { t } = useTranslation(parentTranslationPath, {
    keyPrefix: translationPath,
  });
  const [leaves, setLeaves] = useState({
    leaveFrom: activeItem?.leaveFrom || null,
    leaveTo: activeItem?.leaveTo || null,
  });
  const [isLoading, setIsLoading] = useState(false);

  const schema = Joi.object({
    leaveFrom: Joi.date()
      .required()
      .messages({
        'date.base': t(`leave-from-is-required`),
        'date.empty': t(`leave-from-is-required`),
      }),
    leaveTo: Joi.date()
      .iso()
      .min(Joi.ref('leaveFrom'))
      .required()
      .messages({
        'date.base': t(`leave-to-is-required`),
        'date.empty': t(`leave-to-is-required`),
        'date.min': t(`can't-select-date-in-the-past`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(leaves);

  const saveLeaves = useCallback(async () => {
    if (schema.error) {
      showError(t(`please-fix-all-errors`));
      return;
    }
    setIsLoading(true);
    let addBody = {
      userId: GetParams('id'),
      leaveFrom: leaves.leaveFrom,
      leaveTo: leaves.leaveTo,
    };
    let editBody = {
      userLeavesId: activeItem && activeItem.userLeavesId,
      leaveFrom: leaves.leaveFrom,
      leaveTo: leaves.leaveTo,
    };
    const res =
      (activeItem && (await UpdateUserLeave(editBody))) ||
      (await CreateUserLeave(addBody));
    if (!(res && res.status && res.status !== 200)) {
      if (activeItem) {
        showSuccess(t(`leave-edit-successfully`));
      } else {
        showSuccess(t(`leave-saved-successfully`));
      }
    } else {
      if (activeItem) {
        showError(t(`leave-edit-failed`));
      } else {
        showError(t(`leave-save-failed`));
      }
    }
    setActiveItem(null);
    getAllUserLeaves();
    setIsLoading(false);
    setIsOpenAddLeaves(false);
  }, [leaves]);

  return (
    <>
      <Spinner isActive={isLoading} />
      <XeleDialog
        isOpen={isOpenAddLeaves}
        titleText={activeItem ? 'edit-leave' : 'add-new-leave'}
        maxWidth='sm'
        disableBackdropClick
        dialogContent={
          <>
            <XeleDatePicker
              idRef='leaveFromRef'
              labelValue={t(`leave-from`)}
              placeholder='DD/MM/YYYY'
              format='YYYY/MM/DD'
              parentTranslationPath={parentTranslationPath}
              value={leaves.leaveFrom}
              maxDate={leaves.leaveTo || ''}
              onDateChanged={(newValue) => {
                setLeaves((values) => ({
                  ...values,
                  leaveFrom: newValue
                    ? moment(newValue).format('YYYY-MM-DDTHH:mm:ss')
                    : null,
                }));
              }}
              helperText={getErrorByName(schema, 'leaveFrom').message}
            />
            <XeleDatePicker
              idRef='leavetoRef'
              labelValue={t(`leave-to`)}
              placeholder='DD/MM/YYYY'
              format='YYYY/MM/DD'
              labelClasses='mt-3'
              parentTranslationPath={parentTranslationPath}
              value={leaves.leaveTo}
              minDate={leaves.leaveFrom || ''}
              onDateChanged={(newValue) => {
                setLeaves((values) => ({
                  ...values,
                  leaveTo: newValue
                    ? moment(newValue).format('YYYY-MM-DDTHH:mm:ss')
                    : null,
                }));
              }}
              helperText={getErrorByName(schema, 'leaveTo').message}
            />
            <div className='actions-btns'>
              <ButtonBase
                id='save-ref-action'
                onClick={saveLeaves}
                className='btns theme-solid'
              >
                {t(`save`)}
              </ButtonBase>
              <ButtonBase
                id='cancel-ref-action'
                onClick={() => {
                  setIsOpenAddLeaves(false);
                }}
                className='btns cancel-btn-wrapper'
              >
                {t(`cancel`)}
              </ButtonBase>
            </div>
          </>
        }
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
    </>
  );
};
AddLeavesDialog.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
