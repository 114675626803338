import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ButtonBase } from '@material-ui/core';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from '@material-ui/core';
import { CancelOrganizationUser } from '../../../../../../../Services/UsersServices/userServices';
import { showSuccess } from '../../../../../../../Helper';
import { Spinner } from '../../../../../../../Components';

const translationPath = 'UsersCanDeactiveDialog';
export const CanDeactivateUserDialog = ({
  activeUserItem, isOpen, isOpenChanged, reloadData
}) => {
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation('UsersView', {
    keyPrefix: translationPath,
  });
  const cancelHandler = async () => {
    setLoading(true);
    const res = await CancelOrganizationUser(activeUserItem.id);
    if (res) {
      reloadData();
      showSuccess(t(`user-Deactivated-successfully`));
    }
    setLoading(false);
    isOpenChanged();
  };
  return (
    <div>
    <Dialog
    className='activities-management-dialog-wrapper'
    open={isOpen}
    maxWidth='sm'
    disableBackdropClick
    SmothMove
    onClose={() => {
      isOpenChanged();
    }}
  >
      <DialogTitle id='alert-dialog-slide-title'>
        <span>
            {t(`cant_deactive_this_user`)}
        </span>
      </DialogTitle>
      <DialogContent>
        <div className='dialog-content-wrapper'>

          <div className='d-flex-column-center'>
          <Spinner isActive={loading} />
          <span className='mdi mdi-close-octagon c-danger mdi-48px' />
          <span>
            {' '}
            {t(`cant_deactive_this_user_because_have_reletedToAgent`)}
          </span>
        </div>
        </div>
      </DialogContent>
      <DialogActions>
      <ButtonBase
                  onClick={()=> isOpenChanged()}
                  className='btns theme-solid bg-primary'
                >
                  <span className='px-1'>
                    {t(`Cancel`)}
                  </span>
                </ButtonBase>
      </DialogActions>
  </Dialog>
    </div>
  );
};
CanDeactivateUserDialog.propTypes = {
  activeUserItem: PropTypes.instanceOf(Object).isRequired,
  isOpen: PropTypes.bool.isRequired,
  isOpenChanged: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
};
