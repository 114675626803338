import { lazy } from 'react';
import { UserLoginPermissions } from '../../Permissions';

const EditUserView = lazy(() =>
  import(
    '../../Views/Home/Administration/UsersView/UserManagementView/EditUserView'
  )
);

export const UsersRoutes = [
  {
    id: 30,
    path: '/add',
    name: 'UsersView:UsersManagementView.add-user',
    component: EditUserView,
    layout: '/home/Users',
    default: false,
    isRoute: true,
    authorize: true,
    roles: Object.values(UserLoginPermissions),
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'SideMenuView.Administration.Users',
        isDisabled: false,
        route: '/home/Users',
        groupName: 'system-admin',
      },
      {
        name: 'UsersView:UsersManagementView.add-user',
        isDisabled: false,
        route: '/home/Users/add',
      },
    ],
  },
  {
    id: 30,
    path: '/edit',
    name: 'UsersView:UsersManagementView.edit-user',
    component: EditUserView,
    layout: '/home/Users',
    default: false,
    isRoute: true,
    authorize: true,
    roles: Object.values(UserLoginPermissions),
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'SideMenuView.Administration.Users',
        isDisabled: false,
        route: '/home/Users',
        groupName: 'system-admin',
      },
      {
        name: 'UsersView:UsersManagementView.edit-user',
        isDisabled: false,
        route: '/home/Users/edit',
      },
    ],
  },
];
