import moment from 'moment';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { XeleTable } from '../../../../Components';
import { TableActions } from '../../../../Enums';
import { GlobalNavigate, returnPropsByPermissions } from '../../../../Helper';
import { ReportBuilderPermissions } from '../../../../Permissions';
import { ActiveItemActions } from '../../../../store/ActiveItem/ActiveItemActions';

export const TablesReportsBuilder = ({
  isOpenChanged,
  translationPath,
  parentTranslationPath,
  reportDto,
  setActiveItem,
  reportDtoFull,
  filter,
}) => {
  // const { t } = useTranslation(parentTranslationPath); import { useTranslation } from 'react-i18next';
  const [list, setList] = useState([
    {
      enum: TableActions.RunText.key,
      isDisabled: false,
      externalComponent: null,
    },
    {
      enum: TableActions.editText.key,
      isDisabled: false,
      externalComponent: null,
    },
    {
      enum: TableActions.delete.key,
      isDisabled: false,
      externalComponent: null,
    },
  ]);
  const dispatch = useDispatch();
  const getTableActionsWithPermissions = () => {
    // eslint-disable-next-line prefer-const
    let listWithPermissions = [];
    list.map((option, mapIndex) => {
      if (
        option.enum === 'RunText' &&
        returnPropsByPermissions(
          ReportBuilderPermissions.RunSystemReport.permissionsId
        )
      ) {
        listWithPermissions.push({
          enum: TableActions.RunText.key,
          isDisabled: false,
          externalComponent: null,
        });
      }
      if (
        option.enum === 'editText' &&
        returnPropsByPermissions(
          ReportBuilderPermissions.EditSystemReports.permissionsId
        )
      ) {
        listWithPermissions.push({
          enum: TableActions.editText.key,
          isDisabled: false,
          externalComponent: null,
        });
      }
      if (option.enum === 'delete') {
        listWithPermissions.push({
          enum: TableActions.delete.key,
          isDisabled: false,
          externalComponent: null,
        });
      }
    });
    return listWithPermissions;
  };

  const focusedRowChanged = useCallback((rowIndex, item) => {
    if (rowIndex !== -1) {
      if (item && item.isEditable && item && item.isDeletable) {
        setList([
          {
            enum: TableActions.RunText.key,
            isDisabled: false,
            externalComponent: null,
          },
          {
            enum: TableActions.editText.key,
            isDisabled: false,
            externalComponent: null,
          },
          {
            enum: TableActions.delete.key,
            isDisabled: false,
            externalComponent: null,
          },
        ]);
      } else if (item && item.isDeletable) {
        setList([
          {
            enum: TableActions.RunText.key,
            isDisabled: false,
            externalComponent: null,
          },
          {
            enum: TableActions.delete.key,
            isDisabled: false,
            externalComponent: null,
          },
        ]);
      } else if (item && item.isEditable) {
        setList([
          {
            enum: TableActions.RunText.key,
            isDisabled: false,
            externalComponent: null,
          },
          {
            enum: TableActions.editText.key,
            isDisabled: false,
            externalComponent: null,
          },
        ]);
      } else {
        setList([
          {
            enum: TableActions.RunText.key,
            isDisabled: false,
            externalComponent: null,
          },
        ]);
      }
    }
  }, []);

  const tableActionClicked = useCallback(
    (actionEnum, item, focusedRow, event) => {
      event.stopPropagation();
      event.preventDefault();
      if (actionEnum === TableActions.delete.key) {
        setActiveItem(item);
        isOpenChanged();
      } else if (actionEnum === TableActions.editText.key) {
        dispatch(ActiveItemActions.activeItemRequest(item));
        GlobalNavigate(
          `/home/Reports-Builder/edit?id=${item.systemReportCategoryTypeId}&Report=${item.systemReportCategoryType}&spName=${item.spName}&systemReportCategoryTypeId=${item.systemReportCategoryTypeId}`
        );
      } else if (actionEnum === TableActions.RunText.key) {
        localStorage.setItem(
          'conditions',
          JSON.stringify(
            item &&
              item.systemReportConditions.map((itemData) => ({
                operator: itemData.logicalOperator,
                searchKey: itemData.conditionField,
                value: itemData.filterValue,
                category: itemData.fieldDisplayPath,
                operand: itemData.operatorId,
              }))
          )
        );
        const filterr =
          item &&
          item.systemReportFields.map((data) =>
            JSON.parse(data.systemReportFieldJson)
          );
        localStorage.setItem(
          'ColumusResultTemp',
          JSON.stringify(
            filterr.map((data) => ({
              name: data.name,
              displayPath: data.searchKey,
              category: data.category,
            }))
          )
        );

        window.open(
          `/share/ReportRunView?spName=${item.spName}&Report=${item.systemReportCategoryType}&ReportId=${item.systemReportId}&withLogo=${item.withLogo}`,
          '',
          'width=1400,height=1000'
        );
      }
    },
    []
  );

  return (
    <div className=' '>
      <XeleTable
        data={(reportDto && reportDto) || []}
        headerData={[
          {
            id: 1,
            label: 'Report-Title',
            input: 'systemReportName',
          },
          {
            id: 2,
            label: 'Description',
            input: 'description',
          },
          {
            id: 3,
            label: 'LastModified',
            component: (item) => (
              <span>
                {item &&
                  item.updateOn &&
                  moment(item.updateOn).format('DD/MM/YYYY')}
              </span>
            ),
          },
          {
            id: 4,
            label: 'LastModifiedBy',
            input: 'updatedBy',
          },
          {
            id: 5,

            label: 'createdOn',
            component: (item) => (
              <span>
                {item &&
                  item.createdOn &&
                  moment(item.createdOn).format('DD/MM/YYYY')}
              </span>
            ),
          },
          {
            id: 6,
            label: 'ReportOwner',
            input: 'owner',
          },
          {
            id: 7,
            label: 'ReportCreatedBy',
            input: 'createdBy',
          },
          {
            id: 8,
            label: ' CategoryType',
            input: 'systemReportCategoryType',
          },
        ]}
        activePage={filter.pageIndex}
        actionsOptions={{
          onActionClicked: tableActionClicked,
        }}
        defaultActions={getTableActionsWithPermissions()}
        translationPath={translationPath}
        focusedRowChanged={focusedRowChanged}
        totalItems={
          reportDtoFull && reportDtoFull.totalCount
            ? reportDtoFull.totalCount
            : 0
        }
        itemsPerPage={filter.pageSize}
        parentTranslationPath={parentTranslationPath}
      />
    </div>
  );
};
