import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { XeleInput } from '../Inputs/XeleInput';
import { useTranslation } from 'react-i18next';

export const OptionsFilterInput = ({
  initialOptions,
  onFilterChange,
  textInput = '',
}) => {
  const { t } = useTranslation(['Shared']);

  const [filteredOptions, setFilteredOptions] = useState(initialOptions);

  const onTypedInputChange = (inputValue) => {
    const localInitialOptions = [...(initialOptions || [])];

    const localFilteredOptions = localInitialOptions.filter((option) => {
      const optionText = textInput ? option[textInput].toLowerCase() : '';
      if (optionText.includes(inputValue)) return option;
    });

    setFilteredOptions(localFilteredOptions);
  };

  useEffect(() => {
    onFilterChange(filteredOptions);
  }, [filteredOptions]);

  useEffect(() => {
    setFilteredOptions(initialOptions);
  }, []);

  return (
    <XeleInput
      idRef='OptionsFilterInputRef'
      wrapperClasses='mt-2'
      inputPlaceholder={`${t('Search-for-filter-columns')}`}
      onInputBlur={(event) => {
        event.stopPropagation();
        event.target.focus();
      }}
      onInputChanged={(event) => {
        const inputValue = event.target.value;
        onTypedInputChange(inputValue);
      }}
    />
  );
};

OptionsFilterInput.propTypes = {
  initialOptions: PropTypes.array,
  onFilterChange: PropTypes.func,
  textInput: PropTypes.string,
};
