import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  ButtonBase,
} from '@material-ui/core';

const translationPath = 'dialogs.NotHavePolicyToDisplayDataDialog';
export const NotHavePolicyToDisplayDataDialog = ({ isOpen, isOpenChanged }) => {
  const { t } = useTranslation('FormBuilder', {
    keyPrefix: translationPath,
  });

  return (
    <Dialog
      className='dialog detailsDialog'
      disableBackdropClick
      open={isOpen}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle>
        <>{`${`${t(`Access-Denied`)}` || ''}`}</>
      </DialogTitle>
      <DialogContent>
        {/* <Spinner isActive={isLoading} abs /> */}
        <div className='d-flex-column-center'>
          <span className='mdi mdi-close-octagon c-danger mdi-50px' />
          <span className='fz-20px fw-bold'>
            {`${
              `${t(`You-Dont-Have-Permission-To-Access`)}` ||
              ''
            }`}
          </span>
        </div>
      </DialogContent>

      <DialogActions>
        <ButtonBase
          className='btns theme-solid bg-cancel'
          onClick={() => {
            isOpenChanged();
          }}
        >
          {`${`${t(`cancel`)}` || ''}`}
        </ButtonBase>
      </DialogActions>
    </Dialog>
  );
};

NotHavePolicyToDisplayDataDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isOpenChanged: PropTypes.func.isRequired,
};
