/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  TextField,
  CircularProgress,
  ButtonBase,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from '../../../Hooks';
import {
  XeleNumberFormatCustom,
  XeleNumberFormatPercentage,
} from '../NumberFormatCustomComponent/XeleNumberFormatCustom';

export const XeleInput = ({
  value,
  isRequired,
  isDisabled,
  idRef,
  onInputChanged,
  fieldClasses = '',
  wrapperClasses = '',
  labelClasses = '',
  translationPath = '',
  parentTranslationPath = '',
  labelValue,
  labelValueWithoutTranslation,
  error,
  helperText = '',
  withLoader,
  autoCompleteParams = {},
  withNumberFormat,
  isLoading,
  variant = 'standard',
  label,
  inputPlaceholder,
  rows = 1,
  multiline,
  type = 'text',
  onInputBlur,
  onKeyUp,
  onKeyDown,
  buttonOptions,
  max,
  maxLength,
  min,
  minLength,
  step,
  endAdornment,
  startAdornment,
  beforeIconClasses,
  afterIconClasses,
  multiple,
  refs,
  isWithError,
  isSubmitted,
  overInputText,
  paddingReverse,
  overInputIcon,
  themeClass = 'theme-default',
  defaultValue,
  charactersCounterClasses = '',
  isWithCharactersCounter,
  onInputFocus,
  onInputClick,
  autoComplete = 'new-password',
  decimals,
  presicion,
  pattern,
  DisabledPaste,
  DisabledCopy,
  isAttachedInput,
  withNumberPersantageFormat,
}) => {
  const [isBlurOrChanged, setIsBlurOrChanged] = useState(false);
  const { t } = useTranslation(parentTranslationPath, {
    keyPrefix: translationPath,
  });
  const [language] = useLocalStorage('localization', {
    currentLanguage: 'en',
    isRtl: false,
  });
  useEffect(() => {
    setIsBlurOrChanged(false);
  }, [isSubmitted]);

  return (
    <FormControl
      className={`input-wrapper ${wrapperClasses}${
        (startAdornment && ' with-start-andorment') || ''
      } ${themeClass}`}
    >
      {labelValue && (
        <label
          htmlFor={idRef}
          className={`label-wrapper ${labelClasses}${
            isDisabled ? ' disabled' : ''
          }`}
        >
          {t(`${labelValue}`)}
        </label>
      )}
      {labelValueWithoutTranslation && !labelValue && (
        <label
          htmlFor={idRef}
          className={`label-wrapper ${labelClasses}${
            isDisabled ? ' disabled' : ''
          }`}
        >
          {labelValueWithoutTranslation}
        </label>
      )}
      <div
        className={`w-100 p-relative ${
          isWithCharactersCounter ? 'd-flex flex-wrap' : 'd-flex-center'
        }`}
      >
        {beforeIconClasses && (
          <span
            className={`before-icon-classes-wrapper ${beforeIconClasses}`}
          />
        )}
        {(overInputText || overInputIcon) && (
          <span className='over-input-wrapper'>
            {overInputIcon && <span className={overInputIcon} />}
            {overInputText && t(`${overInputText}`)}
          </span>
        )}
        <div className='text-field-wrapper'>
          <TextField
            {...autoCompleteParams}
            autoComplete={autoComplete}
            ref={refs}
            required={isRequired}
            disabled={isDisabled}
            className={`inputs ${fieldClasses}`}
            style={
              language.isRtl
                ? {
                    paddingRight: paddingReverse,
                  }
                : {
                    paddingLeft: paddingReverse,
                  }
            }
            onPaste={(e) => {
              if (DisabledPaste) {
                e.preventDefault();
                return false;
              }
            }}
            onCopy={(e) => {
              if (DisabledCopy) {
                e.preventDefault();
                return false;
              }
            }}
            id={idRef || label || inputPlaceholder || labelValue || ''}
            onFocus={onInputFocus}
            label={label && t(`${label}`)}
            placeholder={inputPlaceholder && t(`${inputPlaceholder}`)}
            variant={variant}
            helperText={
              // !withoutText &&
              helperText &&
              ((isWithError &&
                (isBlurOrChanged || isSubmitted) &&
                error &&
                helperText) ||
                (!isWithError && t(`${helperText}`)))
            }
            value={value}
            pattern={pattern}
            decimals={decimals}
            defaultValue={defaultValue}
            presicion={presicion}
            error={
              (isWithError && (isBlurOrChanged || isSubmitted) && error) ||
              (!isWithError && !isBlurOrChanged && error)
            }
            minRows={rows}
            onClick={onInputClick}
            onKeyUp={onKeyUp}
            onKeyDown={onKeyDown}
            type={
              (type === 'text' && type) ||
              (type === 'number' && !withNumberFormat && 'number') ||
              (type === 'number' && withNumberFormat && 'isnumber') ||
              (type === 'number' && !withNumberPersantageFormat && 'number') ||
              (type === 'number' && withNumberPersantageFormat && 'isnumber') ||
              type
            }
            multiline={multiline}
            onChange={
              ((onInputChanged || isWithError) &&
                ((event) => {
                  if (!isBlurOrChanged && !isAttachedInput)
                    setIsBlurOrChanged(true);

                  if (onInputChanged) onInputChanged(event);
                })) ||
              undefined
            }
            onBlur={(event) => {
              setIsBlurOrChanged(true);
              if (onInputBlur) onInputBlur(event);
            }}
            inputProps={{
              max,
              maxLength,
              min,
              minLength,
              step,
              decimals,
              multiple,
              presicion,
              ...autoCompleteParams.inputProps,
              // autoComplete
            }}
            InputProps={{
              inputComponent:
                (withNumberFormat && XeleNumberFormatCustom) ||
                (withNumberPersantageFormat && XeleNumberFormatPercentage) ||
                undefined,
              ...autoCompleteParams.InputProps,
              endAdornment:
                (withLoader && isLoading && !endAdornment && (
                  <CircularProgress color='inherit' size={20} />
                )) ||
                endAdornment ||
                autoCompleteParams.InputProps?.endAdornment ||
                undefined,
              startAdornment:
                startAdornment ||
                autoCompleteParams.InputProps?.startAdornment ||
                undefined,
            }}
          />
          {afterIconClasses && (
            <span
              className={`after-icon-classes-wrapper ${afterIconClasses}`}
            />
          )}
          {buttonOptions && (
            <ButtonBase
              className={`ml-2-reversed mt-1 ${buttonOptions.className}`}
              onClick={buttonOptions.onActionClicked}
              disabled={buttonOptions.isDisabled}
            >
              <span className={buttonOptions.iconClasses} />
            </ButtonBase>
          )}
        </div>
        {isWithCharactersCounter && (
          <div
            className={`characters-counter-wrapper ${charactersCounterClasses}`}
          >
            <span>
              {(value && value.length) ||
                (defaultValue && defaultValue.length) ||
                0}
            </span>
            <span className='px-1'>{t('Shared:charaters')}</span>
          </div>
        )}
      </div>
    </FormControl>
  );
};

XeleInput.propTypes = {
  presicion: PropTypes.number,
  decimals: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.any]),
  defaultValue: PropTypes.oneOfType([PropTypes.any]),
  startAdornment: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.func,
    PropTypes.node,
  ]),
  endAdornment: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.func,
    PropTypes.node,
  ]),
  onInputChanged: PropTypes.func,
  onInputBlur: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func,
  idRef: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  max: PropTypes.number,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  minLength: PropTypes.number,
  step: PropTypes.number,
  error: PropTypes.bool,
  isLoading: PropTypes.bool,
  withLoader: PropTypes.bool,
  withNumberFormat: PropTypes.bool,
  withNumberPersantageFormat: PropTypes.bool,
  multiline: PropTypes.bool,
  fieldClasses: PropTypes.string,
  autoCompleteParams: PropTypes.instanceOf(Object),
  wrapperClasses: PropTypes.string,
  labelClasses: PropTypes.string,
  translationPath: PropTypes.string,
  parentTranslationPath: PropTypes.string,
  labelValue: PropTypes.string,
  labelValueWithoutTranslation: PropTypes.string,
  helperText: PropTypes.string,
  variant: PropTypes.string,
  label: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  type: PropTypes.string,
  rows: PropTypes.number,
  beforeIconClasses: PropTypes.string,
  afterIconClasses: PropTypes.string,
  multiple: PropTypes.bool,
  refs: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  isWithError: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  withSearchIcon: PropTypes.bool,
  overInputText: PropTypes.string,
  paddingReverse: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  overInputIcon: PropTypes.string,
  themeClass: PropTypes.oneOf([
    'theme-default',
    'theme-solid',
    'theme-dark',
    'theme-underline',
    'theme-default-dark',
    'theme-transparent',
  ]),
  charactersCounterClasses: PropTypes.string,
  autoComplete: PropTypes.string,
  isWithCharactersCounter: PropTypes.bool,
  onInputFocus: PropTypes.func,
  onInputClick: PropTypes.func,
  buttonOptions: PropTypes.shape({
    className: PropTypes.string,
    iconClasses: PropTypes.string,
    onActionClicked: PropTypes.func,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
  }),
};
