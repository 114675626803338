import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useReducer,
} from 'react';
import SunEditor from 'suneditor-react';
import { align, list, image, link, video } from 'suneditor/src/plugins';
import { XeleInput, Spinner } from '../../../../Components';
import {
  getPublicDownloadableLink,
  showError,
  showSuccess,
} from '../../../../Helper';
import { XeleSwitch, XeleDialog } from '../../../../Components';
import {
  AddReleaseNote,
  DeleteReleaseNote,
  ReorderReleasesNote,
  UpdateReleaseNote,
} from '../../../../Services/ReleaseNotesServices';
import { uploadFile } from '../../../../Services/File';

export const ReleaseNoteForm = ({
  localReleaseNotes,
  setLocalReleaseNotes,
  localReleaseNoteLookups,
  setLocalReleaseNoteLookups,
  activeNoteTypeLookup,
  isDisabled,
  activeItem,
  getAllReleaseNotes,
}) => {
  const editor = useRef();
  const draggedItemOrder = useRef(null);
  const draggedOverItemOrder = useRef(null);

  const initialState = {
    releaseNoteTypeId: activeNoteTypeLookup?.lookupItemId,
    noteTitle: '',
    noteDescription: '',
  };
  const [content, setContent] = useState('<p></p>');
  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };
  const [activeNoteItem, setActiveNoteItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUplode, setIsLoadingUplode] = useState(false);
  const [draggedNoteOrder, setDraggedNoteOrder] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [state, setState] = useReducer(reducer, initialState);

  const handleImageUploadBefore = (files, info, uploadHandler) => {
    Object.values(files).map(async (image) => {
      const response = await uploadFile({
        file: image,
      });
      const responseLocal = {
        result: [
          {
            url: getPublicDownloadableLink(response.uuid),
            name: response.fileName,
            size: files[0].size,
          },
        ],
      };
      uploadHandler(responseLocal);
    });
  };

  const handleUploadBefore = async (e, info, uploadHandler) => {
    function insertVideoBeforeLastParagraph(inputString, videoSource) {
      const lastParagraphIndex = inputString.lastIndexOf('</p>');
      if (lastParagraphIndex !== -1) {
        const newString =
          inputString.slice(0, lastParagraphIndex) +
          `<video width="400" >
            <source src="${videoSource}" type="video/mp4">
            <source src="${videoSource}" type="video/mp4">
            Your browser does not support HTML video.
          </video>` +
          inputString.slice(lastParagraphIndex);

        return newString;
      } else {
        return inputString;
      }
    }

    if (!e.target.files) return;
    setIsLoadingUplode(true);
    const response = await uploadFile({
      file: e.target.files[0],
    });

    if (!(response && response.status && response.status !== 200)) {
      const resultString = insertVideoBeforeLastParagraph(
        content,
        getPublicDownloadableLink(response.uuid)
      );

      setContent(resultString);
    }
    setIsLoadingUplode(false);
  };

  const reorderReleasesNote = useCallback(
    async (draggedItemID, draggedOverItemID) => {
      const body = {
        oldReleaseNoteId: draggedOverItemID,
        newReleaseNoteId: draggedItemID,
        productReleaseId: activeItem?.productReleaseId,
      };
      const res = await ReorderReleasesNote(body);
      if (!(res && res.status && res.status !== 200)) {
      } else {
      }
    },
    [state, activeItem]
  );
  const inputRef = useRef(null);
  const dragStartHandler = (e, order) => {
    draggedItemOrder.current = order;
    setDraggedNoteOrder(order);
    setActiveNoteItem(null);
    resetReleaseNoteForm();
  };

  const dragEnterHandler = (e, order) => {
    draggedOverItemOrder.current = order;
  };

  const dragEndHandler = () => {
    const newReleaseNotes = [...localReleaseNotes];

    const draggedItem = newReleaseNotes.find(
      (item) => item.order === draggedItemOrder.current
    );
    const draggedItemIndex = newReleaseNotes.findIndex(
      (item) => item.order === draggedItemOrder.current
    );

    const draggedOverItemItem = newReleaseNotes.find(
      (item) => item.order === draggedOverItemOrder.current
    );
    const draggedOverItemIndex = newReleaseNotes.findIndex(
      (item) => item.order === draggedOverItemOrder.current
    );

    if (
      draggedItemIndex !== -1 &&
      draggedOverItemIndex !== -1 &&
      draggedItemIndex !== draggedOverItemIndex
    ) {
      reorderReleasesNote(
        draggedItem.releasesNoteId,
        draggedOverItemItem.releasesNoteId
      );

      newReleaseNotes.splice(draggedItemIndex, 1);
      newReleaseNotes.splice(draggedOverItemIndex, 0, draggedItem);

      draggedItemOrder.current = null;
      draggedOverItemOrder.current = null;

      setLocalReleaseNotes(newReleaseNotes);
    }
  };

  const switchComponentClickHandler = (event, isChecked) => {
    let newNoteTypesData = [...localReleaseNoteLookups];
    const clickedItemIndex = localReleaseNoteLookups.findIndex(
      (note) => note.lookupItemId === activeNoteTypeLookup.lookupItemId
    );
    const newNoteTypeItem = {
      ...activeNoteTypeLookup,
      isVisable: isChecked,
    };

    if (clickedItemIndex !== -1)
      newNoteTypesData[clickedItemIndex] = newNoteTypeItem;

    setLocalReleaseNoteLookups(newNoteTypesData);
  };

  const resetReleaseNoteForm = () => {
    setState({
      id: 'edit',
      value: initialState,
    });
    onContentChanged('');
    setContent('');
  };
  const cancelNoteClickHandler = () => {
    resetReleaseNoteForm();
    setContent('');
    setActiveNoteItem(null);
  };

  const noteItemDeleteHandler = useCallback(() => {
    if (activeNoteItem) deleteReleaseNote();
  }, [activeNoteItem]);

  const addReleaseNote = useCallback(async () => {
    const body = {
      ...state,
      productReleaseId: activeItem.productReleaseId,
    };
    setIsLoading(true);
    const res = await AddReleaseNote(body);
    if (!(res && res.status && res.status !== 200)) {
      getAllReleaseNotes();
      resetReleaseNoteForm();
      showSuccess('Release Note Added Successfully');
    } else {
      showError('Adding Release Note Has Failed');
    }
    setIsLoading(false);
  }, [state, activeItem]);

  const updateReleaseNote = useCallback(async () => {
    const releaseNoteId = activeNoteItem?.releasesNoteId;

    const body = {
      ...state,
      productReleaseId: activeItem.productReleaseId,
    };
    setIsLoading(true);
    const res = await UpdateReleaseNote(body, releaseNoteId);
    if (!(res && res.status && res.status !== 200)) {
      getAllReleaseNotes();
      resetReleaseNoteForm();
      setActiveNoteItem(null);
      showSuccess('Release Note Updated Successfully');
    } else {
      showError('Updating Release Note Has Failed');
    }
    setIsLoading(false);
  }, [state, activeItem]);

  const deleteReleaseNote = useCallback(async () => {
    const releasesNoteId = activeNoteItem?.releasesNoteId;
    const res = await DeleteReleaseNote(releasesNoteId);

    if (!(res && res.status && res.status !== 200)) {
      getAllReleaseNotes();
      resetReleaseNoteForm();
      setContent('');
      setActiveNoteItem(null);
      setIsDeleteDialogOpen(false);
      showSuccess('Release Note Deleted Successfully');
    } else {
      showError('Deleting Release Note Has Failed');
    }
  }, [activeNoteItem]);

  const onContentChanged = (newValue) => {
    setState({
      id: 'noteDescription',
      value: newValue,
    });
  };

  useEffect(() => {
    if (activeNoteItem && activeNoteTypeLookup) {
      setState({
        id: 'edit',
        value: {
          releaseNoteTypeId: activeNoteTypeLookup?.lookupItemId,
          noteTitle: activeNoteItem?.noteTitle,
          noteDescription: activeNoteItem?.noteDescription,
        },
      });

      setContent(activeNoteItem?.noteDescription);
    }
  }, [activeNoteItem, activeNoteTypeLookup]);

  return (
    <div className='release-note-managament-form'>
      <div className='note-type-wrapper'>
        <div className='note-type-title-wrapper'>
          <p className={`${isDisabled && 'disabled-note-item'} title`}>
            {activeNoteTypeLookup.lookupItemName}
          </p>
          <XeleSwitch
            idRef='isOpenStatusRef'
            isChecked={activeNoteTypeLookup.isVisable}
            isDisabled={isDisabled}
            onChangeHandler={(event, isChecked) =>
              switchComponentClickHandler(event, isChecked)
            }
          />
        </div>
        {activeNoteTypeLookup.isVisable && (
          <>
            <div className='added-notes-container'>
              {localReleaseNotes &&
                localReleaseNotes.map(
                  (noteItem) =>
                    noteItem.releaseNoteTypeId ===
                      activeNoteTypeLookup.lookupItemId && (
                      <div
                        className={`note-item 
                        ${
                          activeNoteItem?.releasesNoteId ===
                          noteItem?.releasesNoteId
                            ? 'active-note'
                            : ''
                        }
                        ${
                          draggedNoteOrder === noteItem.order
                            ? 'dragged-release-note'
                            : ''
                        }`}
                        onClick={() => {
                          setActiveNoteItem(noteItem);
                          setDraggedNoteOrder(null);
                        }}
                        draggable
                        onDragStart={(e) => dragStartHandler(e, noteItem.order)}
                        onDragEnter={(e) => dragEnterHandler(e, noteItem.order)}
                        onDragEnd={(e) => dragEndHandler()}
                      >
                        <span>{noteItem?.noteTitle}</span>
                        {draggedNoteOrder !== noteItem.order && (
                          <span
                            className='mdi mdi-delete'
                            onClick={() => setIsDeleteDialogOpen(true)}
                            title='Delete note'
                          />
                        )}
                      </div>
                    )
                )}
            </div>
            <div className='product-form-container'>
              <XeleInput
                idRef='TitleRef'
                labelValue='Title'
                value={state.noteTitle}
                onInputChanged={(event) => {
                  const value = event?.target?.value;

                  setState({
                    id: 'noteTitle',
                    value: value || '',
                  });
                }}
              />
              <div
                className='release-note-sunEditor'
                onMouseLeave={() => onContentChanged(content)}
              >
                <Spinner isActive={isLoadingUplode} isAbsolute />
                <SunEditor
                  getSunEditorInstance={getSunEditorInstance}
                  idRef='refs'
                  onImageUploadBefore={handleImageUploadBefore}
                  setOptions={{
                    showPathLabel: false,
                    minHeight: '180px',
                    maxHeight: '180px',
                    placeholder: 'Enter Description Here..',
                    plugins: [align, list, image, link, video],
                    buttonList: [
                      ['bold', 'italic', 'image', 'link', 'align', 'list'],
                    ],
                  }}
                  setContents={content}
                  onChange={(event) => {
                    setContent(event);
                  }}
                />
                <div className='m-2 uplode-ved'>
                  <label htmlFor='fileInput' className='custom-file-upload'>
                    <span>Choose a video file</span>
                    <input
                      id='fileInput'
                      type='file'
                      accept='video/*'
                      ref={inputRef}
                      onChange={handleUploadBefore}
                      style={{ display: 'none' }}
                    />
                  </label>
                </div>
              </div>

              <div className='form-btns'>
                <button
                  className='outlined-btn'
                  onClick={cancelNoteClickHandler}
                >
                  Cancel
                </button>
                {activeNoteItem ? (
                  <button
                    className='primary-btn'
                    onClick={() => {
                      if (state.noteTitle && !isLoading) updateReleaseNote();
                    }}
                  >
                    Edit
                  </button>
                ) : (
                  <button
                    className='primary-btn'
                    onClick={() => {
                      if (state.noteTitle && !isLoading) addReleaseNote();
                    }}
                  >
                    Add
                  </button>
                )}
              </div>
            </div>
          </>
        )}
        <XeleDialog
          titleTextClasses={'release-confirm-text'}
          titleText=''
          saveText='confirm'
          saveType='button'
          maxWidth='sm'
          dialogContent={
            <div className='d-flex-column-center'>
              <span className='mdi mdi-close-octagon c-danger mdi-48px' />
              <span>
                {' '}
                {`Are You Sure You Want To Delete "${
                  activeNoteItem?.noteTitle || ''
                }" Release Note?`}
              </span>
            </div>
          }
          saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
          isOpen={isDeleteDialogOpen}
          onSaveClicked={noteItemDeleteHandler}
          onCloseClicked={() => {
            setIsDeleteDialogOpen(false);
          }}
        />
      </div>
    </div>
  );
};
