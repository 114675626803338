import { config } from '../config/config';
import { HttpServices } from '../Helper';

export const formGet = async (payload) =>
  // const { token } = payload;
  //   await base('get', 'v1/forms', token, null);
  ({});
export const formPost = async (payload) =>
  // const { token, body } = payload;
  //   await base('post', 'v1/forms', token, body);
  ({});
export const formByIdPut = async (payload) =>
  // const { body, token } = payload;
  //   await base('put', 'v1/forms', token, body);
  ({});
// const formByIdGet = async (payload) => {
//     const { formname, token } = payload;
//     return await base('get', `v1/forms/${formname}`, token, null);
// }
export const formByIdGet = async (payload) => {
  const { formname } = payload;
  const result = await HttpServices.get(
    `${config.server_address}/FormBuilder/Forms/${formname}`
  );
  if (result.formsId) {
    const mappedResult = [
      {
        form_id: result.formsId,
        form_name: result.formsName,
        form_content: result.formsContent,
      },
    ];
    return mappedResult;
  }
  return undefined;
};

export const GetAllSearchableFormFieldsByFormId = async (formId) => {
  const result = await HttpServices.get(
    `${config.server_address}/FormBuilder/FormField/GetAllSearchableFormFieldsByFormId?formId=${formId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetAllFormFieldsByFormId = async (formId) => {
  const result = await HttpServices.get(
    `${config.server_address}/FormBuilder/FormField/GetAllFormFieldsByFormId?formId=${formId}`
  )
    .then((data) => data)
    .catch(() => undefined);
  return result;
};
export const CreateForm = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/FormBuilder/Forms`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetSensitiveFieldsForUser = async (formId, sensitiveTypeObj) => {
  const queryList = [];
  if (
    sensitiveTypeObj &&
    sensitiveTypeObj.key &&
    sensitiveTypeObj.key === 'unit'
  )
    queryList.push(`unitId=${sensitiveTypeObj.id}`);
  else if (
    sensitiveTypeObj &&
    sensitiveTypeObj.key &&
    sensitiveTypeObj.key === 'contact'
  )
    queryList.push(`contactId=${sensitiveTypeObj.id}`);
  else if (
    sensitiveTypeObj &&
    sensitiveTypeObj.key &&
    sensitiveTypeObj.key === 'lead'
  )
    queryList.push(`leadId=${sensitiveTypeObj.id}`);
  else if (
    sensitiveTypeObj &&
    sensitiveTypeObj.key &&
    sensitiveTypeObj.key === 'property'
  )
    queryList.push(`propertyId=${sensitiveTypeObj.id}`);

  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/Forms/GetSensitiveFieldsForUser/${formId}?${queryList.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
