import React from 'react';
import { XeleDialog } from '../../../../Components';
import { useSuccessDialog } from '../../../../SharedComponents';
import { ReactComponent as SuccessTickIcon } from '../../../../assets/images/icons/success-tick.svg';
import { Typography, Card, CardContent, Box, Button } from '@material-ui/core';
import FormattedMessage from '../../../../SharedComponents/FormattedComponent/FormattedMessage';
import { useNavigate } from 'react-router-dom';
import { CopyToClipboard } from '../../../../ReusableComponents/UtilityComponents';

export const PARENT_TRANSLATION_PATH = 'GlobalSuccessDialog';

export function GlobalSuccessDialog() {
  const { isOpen, message, hideDialog, entity } = useSuccessDialog();
  const navigate = useNavigate();

  return (
    <XeleDialog
      isOpen={isOpen}
      onCloseClicked={hideDialog}
      maxWidth='xs'
      closeClasses=''
      headingIcon={<SuccessTickIcon />}
      dialogContent={
        <>
          <Typography
            variant='h1'
            style={{
              fontSize: 18,
              fontWeight: 600,
              marginTop: 8,
              marginBottom: 8,
            }}
          >
            {message}
          </Typography>
          <Card>
            <CardContent>
              <Box display='flex' justifyContent='space-between'>
                <Typography variant='body1'>{entity?.name}</Typography>
                <CopyToClipboard
                  data={entity?.id}
                  childrenData={
                    entity?.customId ? entity?.customId : `#${entity?.id}`
                  }
                />
              </Box>
            </CardContent>
          </Card>
          <Button
            variant='contained'
            color='secondary'
            fullWidth
            style={{
              marginTop: 32,
              marginBottom: 24,
              backgroundColor: '#7F6944',
              color: 'white',
              textTransform: 'none',
            }}
            onClick={hideDialog}
          >
            <FormattedMessage id='confirm' path={PARENT_TRANSLATION_PATH} />
          </Button>
          {entity.isSale && (
            <Button
              variant='contained'
              color='secondary'
              fullWidth
              style={{
                marginBottom: 24,
                backgroundColor: '#7F6944',
                color: 'white',
                textTransform: 'none',
              }}
              onClick={() => {
                navigate(
                  `/home/units-sales/unit-transaction-parameters?unitRefNo=${entity?.customId}`
                );
                hideDialog();
              }}
            >
              <FormattedMessage
                id='quick-transaction'
                path={PARENT_TRANSLATION_PATH}
              />
            </Button>
          )}
        </>
      }
    />
  );
}
