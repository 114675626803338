import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { ActionsButtonsEnum } from '../../../Enums';
import { XeleSelect } from '../Select/XeleSelect';
import { PermissionsComponent } from '../..';
import FormattedMessage from '../../../SharedComponents/FormattedComponent/FormattedMessage';

const translationPath = 'Shared:actions-buttons.';
export const ActionsButtonsComponent = ({
  onActionButtonChanged,
  onActionsButtonClicked,
  onFormTypeSelectChanged,
  withType,
  typeData = [],
  isDisabled,
  wrapperClasses = '',
  enableMerge,
  enableBulk,
  enableImport,
  permissionsList,
  addPermissionsId,
  selectPermissionsId,
  checkDisable,
  withText = '',
  enableCloseLeads,
  enableQualifyLead,
  closeAction,
  enablereassignOwnerLeads,
  enablereassignSeekerLeads,
  enableCloneLeads,
  leadActiveAction,
  enableToSendToRotation,
  enableUnAssign,
  enableAssign,
  assignmentAction = '',
  selectedAction,
  enableUnQualifyLead,
  enableMakeTransaction,
  enableSendProposal,
  separateMakeTransactionButton,
  enableMarkAsAvailable,
  enableMoveToDraft,
  enablePublishToWebsite,
  enableUnpublish,
  enableSendToLeadPool,
  enableDelete,
  enableSendToOwnerRotation,
  enableAssignToMe,
  enableAddToFavourite,
  enableShareUnit,
  enableArchived,
  enableUnArchived,
  sendProposalAction,
  pullToContactOpportunity,
  enableConsentManagment,
  enableDNCR,
}) => {
  const { t } = useTranslation('Shared');
  const [activeAction, setActiveAction] = useState(() => ActionsButtonsEnum[1]);
  const [actionData, setActionData] = useState([]);
  const navigate = useNavigate();

  const onSelectChanged = useCallback(
    (selectedValue) => {
      setActiveAction(selectedValue);
      if (onActionButtonChanged) onActionButtonChanged(selectedValue.id);
    },
    [onActionButtonChanged, setActiveAction]
  );
  const getActionData = useCallback(() => {
    const actions = [ActionsButtonsEnum[1]];
    if (enableMerge) actions.push(ActionsButtonsEnum[2]);
    if (enableImport) actions.push(ActionsButtonsEnum[3]);
    if (enableBulk) actions.push(ActionsButtonsEnum[4]);
    if (enableArchived) actions.push(ActionsButtonsEnum[27]);
    if (enableUnArchived) actions.push(ActionsButtonsEnum[28]);
    if (enableCloseLeads) actions.push(ActionsButtonsEnum[5]);
    if (enablereassignSeekerLeads) actions.push(ActionsButtonsEnum[6]);
    if (enablereassignOwnerLeads) actions.push(ActionsButtonsEnum[14]);
    if (enableCloneLeads) actions.push(ActionsButtonsEnum[7]);
    if (enableToSendToRotation) actions.push(ActionsButtonsEnum[8]);
    if (enableSendToOwnerRotation) actions.push(ActionsButtonsEnum[23]);
    if (enableQualifyLead) actions.push(ActionsButtonsEnum[12]);
    if (enableAssign) actions.push(ActionsButtonsEnum[9]);
    if (enableUnAssign) actions.push(ActionsButtonsEnum[10]);
    if (enableUnQualifyLead) actions.push(ActionsButtonsEnum[13]);
    if (enableMakeTransaction && !separateMakeTransactionButton)
      actions.push(ActionsButtonsEnum[15]);
    if (enableSendProposal) actions.push(ActionsButtonsEnum[16]);
    if (enableMarkAsAvailable) actions.push(ActionsButtonsEnum[17]);
    if (enableMoveToDraft) actions.push(ActionsButtonsEnum[18]);
    if (enablePublishToWebsite) actions.push(ActionsButtonsEnum[19]);
    if (enableUnpublish) actions.push(ActionsButtonsEnum[20]);
    if (enableSendToLeadPool) actions.push(ActionsButtonsEnum[22]);
    if (enableDelete) actions.push(ActionsButtonsEnum[21]);
    if (enableAssignToMe) actions.push(ActionsButtonsEnum[24]);
    if (enableAddToFavourite) actions.push(ActionsButtonsEnum[26]);
    if (enableShareUnit) actions.push(ActionsButtonsEnum[25]);
    if (pullToContactOpportunity) actions.push(ActionsButtonsEnum[29]);
    if (enableConsentManagment) actions.push(ActionsButtonsEnum[30]);
    if (enableDNCR) actions.push(ActionsButtonsEnum[31]);
    setActionData(actions);
  }, [
    enableBulk,
    enableImport,
    enableMerge,
    enableCloseLeads,
    enablereassignOwnerLeads,
    enablereassignSeekerLeads,
    enableCloneLeads,
    enableQualifyLead,
    enableAssign,
    enableUnAssign,
    enableMarkAsAvailable,
    enableMoveToDraft,
    enablePublishToWebsite,
    enableUnpublish,
    enableSendToLeadPool,
    enableDelete,
    enableShareUnit,
    enableArchived,
    enableUnArchived,
    enableDNCR,
    enableConsentManagment,
  ]);

  useEffect(() => {
    getActionData();
  }, [getActionData]);

  useEffect(() => {
    if (closeAction) {
      setActiveAction(ActionsButtonsEnum[1]);
      if (onActionButtonChanged)
        onActionButtonChanged(ActionsButtonsEnum[1].id);
    }
  }, [closeAction]);

  useEffect(() => {
    if (assignmentAction && !selectedAction)
      setActiveAction(ActionsButtonsEnum[11]);
  }, [assignmentAction, onActionButtonChanged]);

  useEffect(() => {
    if (sendProposalAction) setActiveAction(ActionsButtonsEnum[16]);
  }, [sendProposalAction, onActionButtonChanged]);

  return (
    <div className={`actions-buttons-wrapper ${wrapperClasses}`}>
      <div className='d-inline-flex'>
        {(!withType || activeAction.id !== ActionsButtonsEnum[1].id) && (
          <PermissionsComponent
            permissionsList={permissionsList}
            permissionsId={selectPermissionsId}
            allowEmptyRoles
          >
            <Button
              disabled={
                isDisabled || (checkDisable && checkDisable(activeAction.id))
              }
              type='button'
              id={`${t(translationPath + activeAction.buttonLabel)}_ref`}
              onClick={() => onActionsButtonClicked(activeAction.id)}
              className={`btns theme-solid ${activeAction.classes}`}
            >
              <span>{t(translationPath + activeAction.buttonLabel)}</span>
            </Button>
          </PermissionsComponent>
        )}
        {withType && activeAction.id === ActionsButtonsEnum[1].id && (
          <PermissionsComponent
            permissionsList={permissionsList}
            permissionsId={addPermissionsId}
            allowEmptyRoles
          >
            <XeleSelect
              data={typeData}
              defaultValue={-1}
              emptyItem={{
                value: -1,
                text: withText || 'add',
                isHiddenOnOpen: true,
              }}
              valueInput='id'
              translationPath={translationPath}
              onSelectChanged={onFormTypeSelectChanged}
              wrapperClasses='bg-secondary c-white mx-2'
              themeClass='theme-action-buttons'
              idRef='AddActionsRef'
              keyValue='actionsbuttons'
              keyLoopBy='id'
              translationPathForData={translationPath}
              textInput='name'
            />
          </PermissionsComponent>
        )}
      </div>
      {onActionButtonChanged && (
        <>
          <div className='d-inline-flex'>
            <PermissionsComponent
              permissionsList={permissionsList}
              permissionsId={selectPermissionsId}
              allowEmptyRoles
            >
              {!closeAction &&
                (enableImport ||
                  enableMerge ||
                  enableBulk ||
                  enableCloseLeads ||
                  enablereassignOwnerLeads ||
                  enablereassignSeekerLeads ||
                  enableCloneLeads ||
                  enableToSendToRotation ||
                  enableSendToOwnerRotation ||
                  enableQualifyLead ||
                  enableMakeTransaction ||
                  enableSendProposal ||
                  enableSendToLeadPool ||
                  enableDelete ||
                  enableAddToFavourite ||
                  enableArchived ||
                  enableUnArchived ||
                  enableAssignToMe ||
                  enableDNCR ||
                  enableConsentManagment ||
                  pullToContactOpportunity) && (
                  <XeleSelect
                    data={actionData}
                    value={leadActiveAction || activeAction.id}
                    valueInput='id'
                    defaultValue={ActionsButtonsEnum[1].id}
                    themeClass='theme-action-buttons'
                    idRef='ActionsRef'
                    keyValue='actionsbuttons'
                    keyLoopBy='id'
                    translationPathForData={translationPath}
                    textInput='label'
                  />
                )}
              {closeAction &&
                (enableImport ||
                  enableMerge ||
                  enableBulk ||
                  enableCloseLeads ||
                  enablereassignOwnerLeads ||
                  enablereassignSeekerLeads ||
                  enableCloneLeads ||
                  enableToSendToRotation ||
                  enableSendToOwnerRotation ||
                  enableAssign ||
                  enableUnAssign ||
                  enableSendToLeadPool ||
                  enableDelete ||
                  enableAddToFavourite ||
                  enableArchived ||
                  enableDNCR ||
                  enableConsentManagment ||
                  enableUnArchived ||
                  enableAssignToMe) && (
                  <XeleSelect
                    data={actionData}
                    value={ActionsButtonsEnum[1]}
                    defaultValue={ActionsButtonsEnum[1]}
                    onSelectChanged={onSelectChanged}
                    themeClass='theme-action-buttons'
                    idRef='AllActionsRef'
                    menuClasses='action-select'
                    keyValue='actionsbuttons'
                    keyLoopBy='id'
                    translationPathForData={translationPath}
                    textInput='label'
                  />
                )}
            </PermissionsComponent>
          </div>
          <PermissionsComponent
            permissionsList={permissionsList}
            permissionsId={selectPermissionsId}
            allowEmptyRoles
          >
            {separateMakeTransactionButton &&
              !closeAction &&
              (enableImport ||
                enableMerge ||
                enableBulk ||
                enableCloseLeads ||
                enablereassignOwnerLeads ||
                enablereassignSeekerLeads ||
                enableCloneLeads ||
                enableToSendToRotation ||
                enableSendToOwnerRotation ||
                enableQualifyLead ||
                enableMakeTransaction ||
                enableSendProposal ||
                enableDelete ||
                enableAddToFavourite ||
                enableDNCR ||
                enableConsentManagment ||
                enableArchived ||
                enableUnArchived ||
                enableAssignToMe) && (
                <>
                  <Button
                    type='button'
                    onClick={() =>
                      navigate.push(
                        `/home/units-sales/unit-transaction-parameters`
                      )
                    }
                    className={`btns theme-solid ${activeAction.classes}`}
                    style={{ padding: '0px 12px' }}
                  >
                    <FormattedMessage
                      id='quick-transaction'
                      path={'GlobalSuccessDialog'}
                    />
                  </Button>
                </>
              )}
          </PermissionsComponent>
        </>
      )}
    </div>
  );
};
ActionsButtonsComponent.propTypes = {
  onActionButtonChanged: PropTypes.func,
  onFormTypeSelectChanged: PropTypes.func,
  onActionsButtonClicked: PropTypes.func,
  wrapperClasses: PropTypes.string,
  withType: PropTypes.bool,
  typeData: PropTypes.instanceOf(Array),
  isDisabled: PropTypes.bool,
  enableMerge: PropTypes.bool,
  enableBulk: PropTypes.bool,
  assign: PropTypes.bool,
  unAssign: PropTypes.bool,
  enableImport: PropTypes.bool,
  permissionsList: PropTypes.instanceOf(Array),
  addPermissionsId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(String),
  ]),
  selectPermissionsId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(String),
  ]),
  checkDisable: PropTypes.func,
  withText: PropTypes.string,
  assignmentAction: PropTypes.string,
  enableCloseLeads: PropTypes.bool,
  enableQualifyLead: PropTypes.bool,
  isSelectAllFromTable: PropTypes.bool,
  enablereassignOwnerLeads: PropTypes.bool,
  enablereassignSeekerLeads: PropTypes.bool,
  value: PropTypes.string,
  enableToSendToRotation: PropTypes.bool,
  enableSendToOwnerRotation: PropTypes.bool,
  pullToContactOpportunity: PropTypes.bool,
};
