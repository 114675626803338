import { lazy } from 'react';

const LoginView = lazy(() =>
  import('../../Views/Account/LoginView/Login.View').then((module) => ({
    default: module.LoginView,
  }))
);
export const AccountRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    layout: '/account',
    default: true,
    isExact: true,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: '/IdentityVerificationView',
    name: 'Login',
    component: LoginView,
    layout: '/account',
    default: false,
    isExact: true,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: '/ResetPasswordOptionsView',
    name: 'Login',
    component: LoginView,
    layout: '/account',
    default: false,
    isExact: true,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: '/VerificationCodeView',
    name: 'Login',
    component: LoginView,
    layout: '/account',
    default: false,
    isExact: true,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: '/NewPasswordView',
    name: 'Login',
    component: LoginView,
    layout: '/account',
    default: false,
    isExact: true,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: '/LoginChangePasswordView',
    name: 'Login',
    component: LoginView,
    layout: '/account',
    default: false,
    isExact: true,
    authorize: false,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
];
