import React, { Children, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import moment from 'moment';
import {
  TableFilterOperatorsEnum,
  TableFilterTypesEnum,
} from '../../../../Enums';
import {
  TableFilterDataPickerComponent,
  TableFilterTextInputComponent,
} from './Controls';
import { TableFilterSelectOptionsComponent } from './Controls/TableFilterSelectOptionsComponent';

export const TableFilterComponent = ({
  tableFilterClassWrapper,
  filterValues,
  onFilterValuesChanged,
  filterData,
  textInputPlaceholder,
  parentTranslationPath,
  translationPath,
  FilterDisabledButton,
  isWithCheckboxColumn,
  optionFilterName,
  isClearFiltersClicked,
  setIsClearFiltersClicked,
  localStorageKey,
}) => {
  const getFilterByTypeHandler = useCallback(
    (row, index) => {
      if (row.filterType === TableFilterTypesEnum.datePicker.key) {
        return (
          <TableFilterDataPickerComponent
            idRef={`filterControlRef${index + 1}`}
            filterKey={row.searchableKey || row.displayPath}
            displayPath={row.displayPath}
            searchableKey={row.searchableKey}
            filterValues={filterValues}
            onFilterValuesChanged={onFilterValuesChanged}
            maxDate={row.maxDate}
            minDate={row.minDate}
            operators={
              row.operators || TableFilterTypesEnum.datePicker.defaultOperators
            }
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            isClearFiltersClicked={isClearFiltersClicked}
            setIsClearFiltersClicked={setIsClearFiltersClicked}
            localStorageKey={localStorageKey}
            FilterDisabledButton={FilterDisabledButton}
          />
        );
      }
      if (row.filterType === TableFilterTypesEnum.textInput.key) {
        return (
          <TableFilterTextInputComponent
            idRef={`filterControlRef${row.key}`}
            filterKey={row.searchableKey || row.displayPath}
            displayPath={row.displayPath}
            searchableKey={row.searchableKey}
            filterValues={filterValues}
            onFilterValuesChanged={onFilterValuesChanged}
            inputPlaceholder={textInputPlaceholder}
            textInputType={row.textInputType}
            textInputMax={row.textInputMax}
            FilterDisabledButton={FilterDisabledButton}
            textInputMin={row.textInputMin}
            operators={
              row.operators || TableFilterTypesEnum.textInput.defaultOperators
            }
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            isClearFiltersClicked={isClearFiltersClicked}
            setIsClearFiltersClicked={setIsClearFiltersClicked}
            localStorageKey={localStorageKey}
          />
        );
      }
      if (row.filterType === TableFilterTypesEnum.numberInput.key) {
        return (
          <TableFilterTextInputComponent
            idRef={`filterControlRef${index + 1}`}
            filterKey={row.searchableKey || row.displayPath}
            displayPath={row.displayPath}
            searchableKey={row.searchableKey}
            filterValues={filterValues}
            onFilterValuesChanged={onFilterValuesChanged}
            inputPlaceholder={textInputPlaceholder}
            textInputType={row.textInputType}
            textInputMax={row.textInputMax}
            FilterDisabledButton={FilterDisabledButton}
            textInputMin={row.textInputMin}
            operators={
              row.operators || TableFilterTypesEnum.numberInput.defaultOperators
            }
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            isClearFiltersClicked={isClearFiltersClicked}
            setIsClearFiltersClicked={setIsClearFiltersClicked}
            isNumerInput={
              row.filterType === TableFilterTypesEnum.numberInput.key
            }
            localStorageKey={localStorageKey}
          />
        );
      }
      if (row.filterType === TableFilterTypesEnum.selectOption.key) {
        return (
          <TableFilterSelectOptionsComponent
            idRef={`filterControlRef${index + 1}`}
            // filterKey={row.key}
            filterKey={row.searchableKey || row.displayPath}
            displayPath={row.displayPath}
            searchableKey={row.searchableKey}
            filterValues={filterValues}
            onFilterValuesChanged={onFilterValuesChanged}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            optionFilterName={(row && row.optionFilterList) || optionFilterName}
            localStorageKey={localStorageKey}
            valueSelected={row && row.valueSelected}
            isClearFiltersClicked={isClearFiltersClicked}
            setIsClearFiltersClicked={setIsClearFiltersClicked}
          />
        );
      }
      return null;
    },
    [
      filterValues,
      onFilterValuesChanged,
      parentTranslationPath,
      textInputPlaceholder,
      translationPath,
    ]
  );

  return (
    (filterData && (
      <TableRow
        tabIndex={-1}
        className={`table-filter-row-wrapper ${tableFilterClassWrapper}`}
      >
        {isWithCheckboxColumn ? <TableCell /> : null}
        {Children.toArray(
          filterData
            .filter((column) => !column.isHidden)
            .map((item, index) => (
              <TableCell colSpan={item.colSpan}>
                {!item.isHiddenFilter &&
                  ((item.component && item.component(item, index)) ||
                    getFilterByTypeHandler(item, index))}
              </TableCell>
            ))
        )}
      </TableRow>
    )) ||
    null
  );
};

TableFilterComponent.propTypes = {
  tableFilterClassWrapper: PropTypes.string,
  isWithCheckboxColumn: PropTypes.bool,
  filterValues: PropTypes.instanceOf(Object),
  onFilterValuesChanged: PropTypes.func,
  isClearFiltersClicked: PropTypes.bool,
  setIsClearFiltersClicked: PropTypes.func,
  filterData: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      colSpan: PropTypes.number,
      isHiddenFilter: PropTypes.bool,
      component: PropTypes.oneOfType([
        PropTypes.elementType,
        PropTypes.func,
        PropTypes.node,
      ]),
      filterType: PropTypes.oneOf(
        Object.values(TableFilterTypesEnum).map((item) => item.key)
      ),
      maxDate: PropTypes.oneOfType([
        PropTypes.instanceOf(moment),
        PropTypes.string,
      ]),
      minDate: PropTypes.oneOfType([
        PropTypes.instanceOf(moment),
        PropTypes.string,
      ]),
      textInputType: PropTypes.oneOf(['number', 'string']),
      textInputMax: PropTypes.number,
      textInputMin: PropTypes.number,
      displayPath: PropTypes.string,
      searchableKey: PropTypes.string,
      operators: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.oneOf(
            Object.values(TableFilterOperatorsEnum).map((item) => item.key)
          ),
          isDisabled: PropTypes.bool,
        })
      ),
    })
  ),
  textInputPlaceholder: PropTypes.string,
  parentTranslationPath: PropTypes.string,
  translationPath: PropTypes.string,
  FilterDisabledButton: PropTypes.bool,
};
TableFilterComponent.defaultProps = {
  tableFilterClassWrapper: '',
  filterData: undefined,
  FilterDisabledButton: undefined,
  filterValues: undefined,
  onFilterValuesChanged: undefined,
  textInputPlaceholder: 'search',
  parentTranslationPath: 'Shared',
  translationPath: '',
  isWithCheckboxColumn: false,
  isClearFiltersClicked: false,
};
